import { t } from 'i18next';
import { FC, useCallback, useState, useEffect } from 'react';

import styles from './SelectStepType.module.scss';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { JitText } from 'components/JitText/JitText';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { DefinedStep, StepType, IConditionStep, IActionStep } from 'types/interfaces/Workflows/IWorkflow';
import { capitalizeFirstLetter } from 'utils';

interface Props {
  selectedStep: DefinedStep;
}

export const SelectStepType: FC<Props> = ({ selectedStep }) => {
  const {
    setWorkflow,
    selectedStepId,
  } = useEditWorkflowContext();

  const [selectedStepType, setSelectedStepType] = useState<StepType>(selectedStep.stepType);

  useEffect(() => {
    setSelectedStepType(selectedStep.stepType);
  }, [selectedStep.stepType]);

  const onSelectOption = useCallback(
    (itemKey: StepType) => {
      setSelectedStepType(itemKey);
      const updatedStep = {
        ...selectedStep,
        stepType: itemKey,
        type: undefined,
        config: {},
        name: 'Description',
      } as IConditionStep | IActionStep;

      setWorkflow((prevWorkflow) => ({
        ...prevWorkflow,
        steps: prevWorkflow.steps.map((step) => (step.id === selectedStepId ? updatedStep : step)),
      }));
    },
    [selectedStep, selectedStepId, setWorkflow],
  );

  if (!selectedStep.stepType) return null;

  return (
    <div className={styles.wrapper}>
      <JitText bold muted size='s' text='pages.workflows.editWorkflowPage.stepDetails.selectType.ctaQuestion' />

      <div>
        <JitAutoCompleteForm
          autoFocus={false}
          clearOnBlur={false}
          fitContent
          fontSize={14}
          getOptionKey={(option) => option}
          getOptionLabel={(option) => capitalizeFirstLetter(option)}
          isSingleValue
          options={[StepType.Condition, StepType.Action]}
          placeHolder={t`pages.workflows.editWorkflowPage.stepDetails.action.addAction`}
          selectedValue={selectedStepType}
          setSelectedValue={onSelectOption}
          showArrow={false}
          variant='light'
        />
      </div>
    </div>
  );
};
