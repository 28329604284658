import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { IPolicyRule, IRuleTemplate, RuleSettings } from '../../types';
import { RuleBuilder } from '../PolicyBuilder/RuleBuilder';

import { usePolicyForm } from './hooks/usePolicyForm';
import { usePolicyMutations } from './hooks/usePolicyMutations';
import styles from './PolicyRule.module.scss';

import { EditPen, Trash } from 'assets';
import { IconButton } from 'components/IconButton/IconButton';
import { ConfirmDialog } from 'components/JitDialogs/ConfirmDialog';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitSwitchToggle } from 'components/JitSwitchToggle/JitSwitchToggle';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { ActionButtonVariant } from 'types/interfaces';

interface Props {
  ruleData: IPolicyRule;
  index: number;
  ruleTemplate: IRuleTemplate;
  onAddCancel: () => void;
  policyDisplayName: string;
  policySlug: string;
  isReadOnly: boolean;
}

export const PolicyRule: FC<Props> = ({
  ruleData,
  ruleTemplate,
  index,
  onAddCancel,
  policyDisplayName,
  policySlug,
  isReadOnly,
}) => {
  const [editRuleData, setEditRuleData] = useState<IPolicyRule | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { slug } = ruleTemplate;

  const {
    saveRule,
    deleteRule,
    updateRule,
    isLoadingDeleteRule,
    isLoadingSaveRule,
    isLoadingUpdateRule,
  } = usePolicyMutations({
    policySlug,
    ruleSlug: slug,
    onCreate: () => setEditRuleData(null),
    onUpdate: () => setEditRuleData(null),
  });

  const isEditMode = !!editRuleData;

  const {
    isDirty,
    isValid,
  } = usePolicyForm({
    ruleSettingsData: ruleData.settings,
    editRuleSettingsData: editRuleData?.settings,
    ruleTemplate,
    isEditMode,
  });

  useEffect(() => {
    if (!ruleData.id) {
      setEditRuleData(JSON.parse(JSON.stringify(ruleData)));
    }
  }, [ruleData]);

  const handleToggleEnableRule = useCallback(() => {
    if (!ruleData?.id) return;

    updateRule({
      id: ruleData.id,
      payload: {
        enabled: !ruleData.enabled,
      },
    });
  }, [ruleData.enabled, ruleData.id, updateRule]);

  const handleDelete = useCallback(() => {
    if (ruleData.id) deleteRule(ruleData.id);
  }, [ruleData.id, deleteRule]);

  const handleEdit = useCallback(() => {
    setEditRuleData(JSON.parse(JSON.stringify(ruleData)));
  }, [ruleData]);

  const handleSave = useCallback(() => {
    if (!editRuleData) return;

    if (editRuleData.id) {
      updateRule({
        id: editRuleData.id,
        payload: {
          settings: editRuleData.settings,
        },
      });
    } else {
      saveRule(editRuleData.settings);
    }
  }, [editRuleData, saveRule, updateRule]);

  const handleCancel = useCallback(() => {
    setEditRuleData(null);
    if (!ruleData.id) {
      onAddCancel();
    }
  }, [onAddCancel, ruleData.id]);

  const updatedByText = useMemo(() => {
    if (ruleData.updatedBy) {
      const textType = ruleData.updatedAt ? 'updatedBy' : 'createdBy';
      return `pages.policies.rule.modify.${textType}`;
    }

    return null;
  }, [ruleData.updatedAt, ruleData.updatedBy]);

  const isLoadingSave = isLoadingSaveRule || isLoadingUpdateRule;

  const onSettingsChange = useCallback((settings: RuleSettings) => {
    setEditRuleData((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        settings,
      };
    });
  }, [setEditRuleData]);

  const rootClasses = isEditMode ? `${styles.ruleCard} ${styles.ruleCardEditMode}` : styles.ruleCard;

  const deleteDialog = (
    <ConfirmDialog
      actionButtons={[
        {
          label: 'pages.policies.rule.deleteDialog.cancel',
          handleClick: () => setIsDialogOpen(false),
          variant: ActionButtonVariant.SECONDARY,
        },
        {
          label: 'pages.policies.rule.deleteDialog.confirm',
          handleClick: handleDelete,
          variant: ActionButtonVariant.DANGER,
          isLoading: isLoadingDeleteRule,
          disabled: isLoadingDeleteRule,
        },
      ]}
      isOpen={isDialogOpen}
      message={i18n.t('pages.policies.rule.deleteDialog.message', { policyDisplayName })}
      onClose={() => setIsDialogOpen(false)}
      title='pages.policies.rule.deleteDialog.title'
    />
  );

  const handleHover = useCallback(() => {
    if (!isEditMode && !isReadOnly) setIsHovered(true);
  }, [isEditMode, isReadOnly]);

  const handleLeave = useCallback(() => {
    if (!isEditMode) setIsHovered(false);
  }, [isEditMode]);

  return (
    <div className={rootClasses} onMouseEnter={handleHover} onMouseLeave={handleLeave}>

      <div className={styles.ruleBody}>
        <div className={styles.ruleContent}>
          <RuleBuilder
            isEditMode={isEditMode}
            onSettingsChange={onSettingsChange}
            ruleTemplate={ruleTemplate}
            settings={editRuleData?.settings || ruleData.settings}
            title={(index + 1).toString()}
          />
        </div>

        {updatedByText && (
          <div className={styles.editInfoBox}>
            <JitText muted text={updatedByText} />

            <JitText text={ruleData.updatedBy} />
          </div>
        )}

      </div>

      {editRuleData ? (
        <div className={styles.formButtons}>
          <JitGradientButton
            data-testid='saveRuleButton'
            disabled={!isDirty || !isValid || isLoadingSave}
            height={42}
            isLoading={isLoadingSave}
            onClick={handleSave}
            width={65}
          >
            <div className={styles.saveButton}>
              <JitText
                bold
                fontFamily='Inter, serif'
                size='s'
                text='pages.policies.rule.buttons.save.label'
              />
            </div>
          </JitGradientButton>

          <JitText
            color={colors.iris}
            data-testid='cancelRuleEditButton'
            onClick={handleCancel}
            size='s'
            text='pages.policies.rule.buttons.cancel.label'
          />
        </div>
      ) : (
        <div className={styles.buttonsWrapper}>
          {isHovered && (
            <div className={styles.controlButtons}>
              <IconButton
                color={colors.iris}
                icon={EditPen}
                isDisabled={isLoadingDeleteRule}
                onClick={handleEdit}
                size={14}
                testId='editRuleButton'
                tooltipText='pages.policies.rule.buttons.edit.tooltip'
              />

              <IconButton
                icon={Trash}
                isLoading={isLoadingDeleteRule}
                onClick={() => setIsDialogOpen(true)}
                size={14}
                testId='deleteRuleButton'
                tooltipText='pages.policies.rule.buttons.delete.tooltip'
                withBackSquare
              />
            </div>
          )}

          <JitSwitchToggle
            checked={ruleData?.enabled}
            disabled={isLoadingSave}
            isReadOnly={isReadOnly}
            onChange={handleToggleEnableRule}
          />
        </div>
      )}

      {deleteDialog}
    </div>
  );
};
