import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cell, Row } from 'react-table';

import { useGetColumns } from './useGetColumns';
import styles from './WorkflowsTable.module.scss';

import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { constants } from 'globalConstants';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

interface WorkflowsTableProps {
  workflows: IWorkflow[];
  isFetchingNextPage?: boolean;
  fetchNextPage: () => void;
  isEntitled: boolean;
  isReadOnly: boolean;
}

export const WorkflowsTable: FC<WorkflowsTableProps> = ({ workflows, fetchNextPage, isFetchingNextPage, isEntitled, isReadOnly }) => {
  const navigate = useNavigate();

  const { WORKFLOWS } = constants.routes;

  const onSelectRow = (row: Row<IWorkflow>) => {
    navigate(`/${WORKFLOWS}/${row.original.id}`);
  };

  const onCellClick = (cell: Cell<IWorkflow>) => {
    if (!isEntitled) return;

    if (cell.column.id === 'enabled' || cell.column.id === 'delete') {
      // Inline function - prevents the event from bubbling up to the row click event
      return;
    }

    onSelectRow(cell.row);
  };

  const { columns } = useGetColumns(isEntitled, isReadOnly);
  const tableInstance = useGetTableInstance(columns, workflows);
  return (
    <div className={styles.tableWrapper} data-testid='workflows-table'>
      <JitTable
        handleReachScrollEnd={{
          callback: fetchNextPage,
        }}
        isFetching={isFetchingNextPage}
        isLoading={false}
        onCellClick={onCellClick as (cell: Cell<object>) => void}
        // dummy function- we handle row selection in onCellClick but onSelectRow is required by JitTable for the styling to work
        onSelectRow={() => {}}
        showPagination={false}
        tableInstance={tableInstance}
      />
    </div>
  );
};
