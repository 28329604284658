import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { ActionButton, ActionButtonVariant } from '../../types/interfaces/ActionButton/ActionButton';
import { CustomDialog } from '../JitDialogs/CustomDialog';

import { IgnoreFindingsDialogMenu } from './components/IgnoreFindingsDialogMenu';
import styles from './IgnoreFindingsDialog.module.scss';

import { Info } from 'assets';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitText } from 'components/JitText/JitText';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { i18n } from 'locale/i18n';
import { allowIgnoreFindingsPolicySlug } from 'pages/PoliciesPage/constants';
import { usePoliciesService } from 'services/PoliciesService/usePoliciesService';
import colors from 'themes/colors.module.scss';
import { IgnoreReason } from 'types/enums/IgnoreReason';
import { Queries } from 'types/enums/Queries';

interface IgnoreFindingsDialogProps {
  open: boolean;
  onClose: () => void;
  ignoreFunction: (ignoreReason: IgnoreReason, comment: string, ignoreByType?: boolean) => void;
  isIgnoredLoading: boolean;
  displayIgnoreByType?: boolean;
  findingType?: string;
}

export const IgnoreFindingsDialog: FC<IgnoreFindingsDialogProps> = ({
  open,
  onClose,
  ignoreFunction,
  isIgnoredLoading,
  displayIgnoreByType,
  findingType,
}) => {
  const [ignoreDescription, setIgnoreDescription] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [ignoreByType, setIgnoreByType] = useState<boolean>(false);
  const IgnoreByTypeDescriptionText = i18n.t('pages.actions.actionCard.ignoreDialog.IgnoreByType.description', { findingType });
  const { getPolicyRules } = usePoliciesService();

  const { data: rules = [] } = useQuery(
    [Queries.policyRules, allowIgnoreFindingsPolicySlug, {
      enabled: true,
    }],
    () => getPolicyRules(allowIgnoreFindingsPolicySlug, true),
    {
      enabled: open,
      staleTime: 1000 * 60, // 1 minute - we pass this prop in order to avoid refetching on dialog open (enabled: true)
      onError: (error) => {
        console.error('Failed to fetch ignore policies:', error);
      },
    },
  );

  const hasIgnorePolicy = rules.length > 0;

  const handleIgnoreByType = () => {
    setIgnoreByType(!ignoreByType);
  };
  const handleIgnoreFindings = () => {
    ignoreFunction(reason as IgnoreReason, ignoreDescription, ignoreByType);
  };

  const handleDescriptionChange = (value: string) => {
    setIgnoreDescription(value);
  };

  const resetState = () => {
    setIgnoreDescription('');
    setReason('');
    setIgnoreByType(false);
  };

  useEffect(() => () => resetState(), [open]);

  const handleClose = () => {
    onClose();
    resetState();
  };

  const buttons: ActionButton[] = [{
    label: 'pages.pullRequests.prsLists.cancelButton',
    handleClick: handleClose,
    variant: ActionButtonVariant.SECONDARY,
  }, {
    label: 'pages.pullRequests.prsLists.ignoreButton',
    handleClick: handleIgnoreFindings,
    variant: ActionButtonVariant.PRIMARY,
    disabled: isEmpty(reason) || isEmpty(ignoreDescription) || isIgnoredLoading,
    isLoading: isIgnoredLoading,
  }];

  const content = (
    <div className={styles.wrapper} data-testid='dialogContent'>
      <JitText size='m' style={{ marginBottom: 8 }} text='pages.actions.actionCard.ignoreDialog.reasonDropDownTitle' />

      <IgnoreFindingsDialogMenu data-testid='ignoreFindingsDialogMenu' selectedReason={reason} setSelectedReason={setReason} />

      <div className={styles.TextWrapper} data-testid='descriptionTitle'>
        <JitText
          size='m'
          text='pages.actions.actionCard.ignoreDialog.descriptionTitle'
        />
      </div>

      <JitTextInput
        fullWidth
        height={45}
        inputDataTestId='descriptionInput'
        maxRows={2}
        multiline
        onChange={handleDescriptionChange}
        placeholder='pages.actions.actionCard.ignoreDialog.descriptionPlaceholder'
        value={ignoreDescription}
        variant='light'
      />

      {displayIgnoreByType
        && (
          <div className={styles.ignoreByTypeWrapper}>
            <JitCheckbox
              checked={ignoreByType}
              data-testid='ignore-by-type-checkbox'
              onChange={handleIgnoreByType}
              style={{ marginRight: '0.5rem' }}
            />

            <div className={styles.ignoreByTypeTextWrapper}>
              <JitText bold size='m' text='pages.actions.actionCard.ignoreDialog.IgnoreByType.title' />

              <JitText size='m' text={IgnoreByTypeDescriptionText} />
            </div>

          </div>
        )}

      {hasIgnorePolicy && (
      <JitInfoBanner
        bgColor={colors.cardContent}
        className={styles.infoBanner}
        icon={Info}
        iconColor={colors.blue}
      >
        <JitText
          size='m'
          text='pages.actions.actionCard.ignoreDialog.infoBanner.title'
        />
      </JitInfoBanner>
      )}
    </div>
  );

  return (
    <div>
      <CustomDialog
        actionButtons={buttons}
        content={content}
        isOpen={open}
        onClose={handleClose}
        title='pages.actions.actionCard.ignoreDialog.title'
        width='m'
        withDivider
      />
    </div>
  );
};

