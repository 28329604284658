import { OnCheckoutCompletedParams } from '@stigg/react-sdk';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './PricingPlansPage.module.scss';

import { BackArrow, JitLogoIcon } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { StiggCheckout } from 'components/Stigg/Checkout/StiggCheckout';
import { StiggPaywall } from 'components/Stigg/StiggPaywall';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { constants } from 'globalConstants';
import { logError } from 'services/logger/logger';
import colors from 'themes/colors.module.scss';

const backSquareStyle = {
  backgroundColor: 'transparent',
  height: '26px',
  width: '26px',
  borderColor: colors.premiumPink,
};

export const PricingPlansPage: FC = () => {
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleBackClick = () => {
    sendAnalyticsEvent({
      action: 'pricing-page-exit',
      params: {
        selected_plan: selectedPlanId || 'selectedPlanId is null',
        exit_type: 'back-button',
      },
    });
    navigate(-1);
  };

  const onCheckoutCompleted = async (params: OnCheckoutCompletedParams) => {
    if (params.success) {
      sendAnalyticsEvent({
        action: 'pricing-checkout-completed',
        params: {
          status: 'success',
          plan_id: selectedPlanId || 'selectedPlanId is null',
        },
      });
      setSelectedPlanId(null);
      navigate(`/${constants.routes.BILLING}`);
    } else {
      sendAnalyticsEvent({
        action: 'pricing-checkout-completed',
        params: {
          status: 'failed',
          plan_id: selectedPlanId || 'selectedPlanId is null',
          error: params.error || 'params.error is undefined',
        },
      });
      logError('Checkout failed from pricing plans page', params);
    }
  };

  const handlePlanSelected = (planId: string) => {
    sendAnalyticsEvent({
      action: 'pricing-plan-selected',
      params: {
        plan_id: planId,
      },
    });
    setSelectedPlanId(planId);
  };

  return (
    <PageLayout>
      <div className={styles.cornerIconsWrapper}>
        <JitButton className={styles.backArrow} onClick={handleBackClick}>
          <JitIcon
            centered
            className={styles.backArrowIcon}
            color={colors.premiumPink}
            data-testid='back-arrow'
            icon={BackArrow}
            size='12px'
            withBackSquare={backSquareStyle}
          />
        </JitButton>

        <JitIcon centered color={colors.jitPink} icon={JitLogoIcon} size={23} />
      </div>

      <div className={styles.container}>
        {!selectedPlanId && (
          <>
            <JitText align='center' gutterBottom size='xxl' text='common.pricingPlan.page.title' whiteSpace='pre-line' />

            <JitText
              align='center'
              muted
              paddingBottom='48px'
              size='m'
              text='common.pricingPlan.page.subtitle'
            />
          </>
        )}

        <div className={styles.content}>
          {selectedPlanId ? (
            <StiggCheckout
              onCheckoutCompleted={onCheckoutCompleted}
              planId={selectedPlanId}
            />
          ) : (
            <StiggPaywall onPlanSelected={handlePlanSelected} />
          )}
        </div>
      </div>
    </PageLayout>
  );
};
