import { t } from 'i18next';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { IGitlabIntegrationWizardStep, IGitlabIntegrationWizardStepStatus } from '../GitlabIntegrationWizard';

import { useSecretService } from 'services/SecretsService/useSecretService';
import { Queries } from 'types/enums/Queries';

interface Props {
  incrementStepIndex: () => void;
  secretNameKey: string;
  StepComponent: React.ComponentType<{
    incrementStepIndex: () => void;
    secretExists: boolean;
    secretName: string;
  }>;
  titleKey: string;
}

export const useSecretStep = ({ incrementStepIndex, secretNameKey, StepComponent, titleKey }: Props): IGitlabIntegrationWizardStep => {
  const { getTenantSecrets } = useSecretService();
  const secretName = t(secretNameKey);

  const { data: secrets } = useQuery(
    [Queries.TenantSecrets],
    getTenantSecrets,
  );

  const isCompleted = !!secrets?.data?.some((secret) => secret.name === secretName);

  const status = useMemo(() => (isCompleted ? IGitlabIntegrationWizardStepStatus.COMPLETE : IGitlabIntegrationWizardStepStatus.ACTIVE), [isCompleted]);

  return useMemo(() => ({
    title: titleKey,
    component: () => (
      <StepComponent
        incrementStepIndex={incrementStepIndex}
        secretExists={isCompleted}
        secretName={secretName}
      />
    ),
    status,
  }), [titleKey, status, StepComponent, incrementStepIndex, isCompleted, secretName]);
};
