import { PERMISSIONS } from '../constants';

import { UiLayoutMode } from './uiLayoutMode';

import { AppLoadingBar } from 'components/AppLoadingBar/AppLoadingBar';
import { constants } from 'globalConstants';
import { OverviewPage } from 'pages/OverviewPage/OverviewPage';
import { DEFAULT_PLAN_SLUG } from 'pages/PlanPage/constants';
import { PlanPageWrapper } from 'pages/PlanPage/PlanPageWrapper';
import { QuickStartGuide } from 'pages/QuickStartGuide';
import { GuidePage } from 'pages/TeamsPortal/GuidePage/GuidePage';
import { TeamPageWrapper } from 'pages/TeamsPortal/TeamPage/TeamPageWrapper';
import { WelcomePage } from 'pages/TeamsPortal/WelcomePage/WelcomePage';

interface GetDefaultRouteParams {
  uiLayoutMode: UiLayoutMode;
  viewedWelcomeTeamsPage: boolean;
  hasTriedFetchInstallations: boolean;
  isAnyScmVendorIntegrated: boolean;
  routesComponents: Record<string, JSX.Element>;
  initialOnboardingCompleted: boolean;
  uiShowSecurityChampionQuickGuide: boolean;
  hasPermission: (permission: string) => boolean;
}

const STATIC_ROUTES = {
  TEAMS_PORTAL_WELCOME: {
    key: constants.routes.teamsPortal.WELCOME,
    component: <WelcomePage />,
  },
  TEAM_PAGE: {
    key: `/${constants.routes.teamsPortal.BASE_ROUTE}/${constants.routes.teamsPortal.PORTAL_TEAMS}`,
    component: <TeamPageWrapper />,
  },
  TEAMS_PORTAL_GUIDE: {
    key: `/${constants.routes.teamsPortal.BASE_ROUTE}/${constants.routes.teamsPortal.GUIDE}`,
    component: <GuidePage />,
  },
  LOADING: {
    key: constants.routes.OVERVIEW,
    component: <AppLoadingBar />,
  },
  OVERVIEW: {
    key: constants.routes.OVERVIEW,
    component: <OverviewPage />,
  },
  QUICKSTART: {
    key: constants.routes.QUICKSTART,
    component: <QuickStartGuide />,
  },
};

const getTeamsPortalDefaultRoute = (
  viewedWelcomeTeamsPage: boolean,
  hasPermission: (permission: string) => boolean,
) => {
  if (hasPermission(PERMISSIONS.TEAM_PORTAL_QUICKSTART)) {
    // If the user has the permission to view the quickstart we want to check if they have already viewed the welcome page
    return viewedWelcomeTeamsPage
      ? STATIC_ROUTES.TEAMS_PORTAL_GUIDE
      : STATIC_ROUTES.TEAMS_PORTAL_WELCOME;
  }

  return STATIC_ROUTES.TEAM_PAGE; // This page will be shown to users without quickstart access AKA developers
};

const getManagementPlatformDefaultRoute = (
  hasTriedFetchInstallations: boolean,
  isAnyScmVendorIntegrated: boolean,
  routesComponents: Record<string, JSX.Element>,
  initialOnboardingCompleted: boolean,
  uiShowSecurityChampionQuickGuide: boolean,
  hasPermission: (permission: string) => boolean,
) => {
  if (!initialOnboardingCompleted && uiShowSecurityChampionQuickGuide && hasPermission(PERMISSIONS.QUICKSTART)) {
    return STATIC_ROUTES.QUICKSTART;
  }

  if (!hasTriedFetchInstallations) {
    return STATIC_ROUTES.LOADING;
  }

  const planSlug = sessionStorage.getItem(constants.GOAL_PLAN_KEY) || undefined;
  if (planSlug) {
    return {
      key: constants.routes.PLAN,
      component: <PlanPageWrapper planSlug={planSlug} />,
    };
  }

  const recentLocationRoute = sessionStorage.getItem(constants.RECENT_LOCATION_ROUTE_KEY) || undefined;
  if (recentLocationRoute && recentLocationRoute in routesComponents) {
    return {
      key: recentLocationRoute.slice(1),
      component: routesComponents[recentLocationRoute],
    };
  }

  if (!isAnyScmVendorIntegrated) {
    return {
      key: constants.routes.PLAN,
      component: <PlanPageWrapper isDefaultRoute planSlug={DEFAULT_PLAN_SLUG} />,
    };
  }

  return STATIC_ROUTES.OVERVIEW;
};

export const getDefaultRoute = ({
  uiLayoutMode,
  viewedWelcomeTeamsPage,
  isAnyScmVendorIntegrated,
  hasTriedFetchInstallations,
  routesComponents,
  initialOnboardingCompleted,
  uiShowSecurityChampionQuickGuide,
  hasPermission,
}: GetDefaultRouteParams) => {
  switch (uiLayoutMode) {
    case UiLayoutMode.TeamPortal:
      return getTeamsPortalDefaultRoute(viewedWelcomeTeamsPage, hasPermission);
    case UiLayoutMode.ManagementPlatform:
      return getManagementPlatformDefaultRoute(
        hasTriedFetchInstallations,
        isAnyScmVendorIntegrated,
        routesComponents,
        initialOnboardingCompleted,
        uiShowSecurityChampionQuickGuide,
        hasPermission,
      );
    default:
      return STATIC_ROUTES.OVERVIEW;
  }
};
