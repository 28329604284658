import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';

import { CallbackKey, InputOption } from '../../../types';

import { calcShouldFetchMore } from 'components/JitTable/utils';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { fetchFilterOptions } from 'services/FindingsService';
import { useGraphService } from 'services/GraphService/useGraphService';
import { useTeamsService } from 'services/TeamsService/useTeamsService';
import { GraphEntityType } from 'types/enums/ContextGraphEntityType';
import { Queries } from 'types/enums/Queries';
import { AssetSortBy } from 'types/interfaces';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';

const PAGE_LIMIT = 20;

export type CallbackProps = {
  options: InputOption[];
  isLoading: boolean;
  onScroll?: (e: React.UIEvent<HTMLUListElement>) => void;
  onInputChange?: (value: string) => void;
};
export const useOptionsQueries = (activeCallbacks: Record<CallbackKey, boolean>) => {
  const [searchTerms, setSearchTerms] = useState<Record<string, string>>({});

  const { fetchTeams } = useTeamsService();
  const { fetchPriorityFactorsGroups } = useGraphService();
  const { getAssetsWithPagination, fetchAssetsCount } = useAssetService();

  const { data: findingTypesData, isLoading: isLoadingFindingTypes } = useQuery(
    [Queries.FetchFindingTypesOptions],
    () => fetchFilterOptions({}),
    {
      enabled: activeCallbacks.fetchFindingTypesOptions,
    },
  );

  const findingTypesOptions = useMemo(() => findingTypesData?.vulnerability_type?.map((value) => ({
    value,
    label: value,
  })) || [], [findingTypesData]);

  const {
    data: findingTeamsData,
    isLoading: isLoadingFindingsTeam,
    fetchNextPage: fetchNextPageFindingsTeam,
    hasNextPage: hasNextPageFindingsTeam,
    isFetchingNextPage: isFetchingNextPageFindingsTeam,
    remove: removeFindingsTeam } = useInfiniteQuery(
    [Queries.FetchFindingTeamsOptions, {
      sortBy: TeamSortBy.NAME,
      sortOrder: TeamSortOrder.ASC,
      limit: PAGE_LIMIT,
      searchValue: searchTerms.findingTeams,
    }],
    fetchTeams,
    {
      getNextPageParam: (response) => ({ after: response?.metadata.after }),
      enabled: activeCallbacks.fetchFindingTeamsOptions,
    },
  );

  const onScrollFindingsTeam = useCallback((e: React.UIEvent<HTMLUListElement>) => {
    if (hasNextPageFindingsTeam && !isFetchingNextPageFindingsTeam && calcShouldFetchMore(e, 20)) {
      fetchNextPageFindingsTeam();
    }
  }, [hasNextPageFindingsTeam, isFetchingNextPageFindingsTeam, fetchNextPageFindingsTeam]);

  const debouncedInputChangeFindingsTeam = debounce((value: string) => {
    removeFindingsTeam();

    setSearchTerms((prev) => ({
      ...prev,
      findingTeams: value,
    }));
  }, 300);

  const findingTeamsOptions = useMemo(
    () => findingTeamsData?.pages?.flatMap((team) => team?.data || []).map(({ name }) => ({
      value: name,
      label: name,
    })) || [],
    [findingTeamsData],
  );

  const {
    data: priorityFactorsData,
    isLoading: isLoadingPriorityFactors,
  } = useQuery(
    [Queries.FetchPriorityFactorsOptions],
    fetchPriorityFactorsGroups,
    {
      enabled: activeCallbacks.fetchFindingPriorityFactorsOptions || activeCallbacks.fetchAssetPriorityFactorsOptions,
    },
  );

  const findingPriorityFactorsOptions = useMemo(() => priorityFactorsData?.data.groups.filter(({
    scopes,
  }) => scopes.includes(GraphEntityType.FINDING)).flatMap(({ factors }) => factors.map(({ key, displayName }) => ({
    label: displayName,
    value: key,
  }))).sort((a, b) => a.label.localeCompare(b.label)) || [], [priorityFactorsData]);

  const assetPriorityFactorsOptions = useMemo(() => priorityFactorsData?.data.groups.filter(({
    scopes,
  }) => scopes.includes(GraphEntityType.ASSET)).flatMap(({ factors }) => factors.map(({ key, displayName }) => ({
    label: displayName,
    value: key,
  }))).sort((a, b) => a.label.localeCompare(b.label)) || [], [priorityFactorsData]);

  const {
    data: assetNamesData,
    isLoading: isLoadingAssetNames,
    fetchNextPage: fetchNextPageAssetNames,
    hasNextPage: hasNextPageAssetNames,
    isFetchingNextPage: isFetchingNextPageAssetNames,
    remove: removeAssetNames,
  } = useInfiniteQuery(
    [Queries.FetchAssetNamesOptions, {}, {
      sort_by: AssetSortBy.PriorityScore,
      sort_order: 'desc',
    }],
    getAssetsWithPagination,
    {
      getNextPageParam: (response) => response?.metadata.after,
      enabled: activeCallbacks.fetchAssetNamesOptions,
    },
  );

  const onScrollAssetNames = useCallback((e: React.UIEvent<HTMLUListElement>) => {
    if (hasNextPageAssetNames && !isFetchingNextPageAssetNames && calcShouldFetchMore(e, 20)) {
      fetchNextPageAssetNames();
    }
  }, [hasNextPageAssetNames, isFetchingNextPageAssetNames, fetchNextPageAssetNames]);

  const debouncedInputChangeAssetNames = debounce((value: string) => {
    removeAssetNames();

    setSearchTerms((prev) => ({
      ...prev,
      assetNames: value,
    }));
  }, 300);

  const assetNamesOptions = useMemo(() => assetNamesData?.pages?.flatMap((page) => page?.data || []).map(({ asset_name }) => ({
    label: asset_name,
    value: asset_name,
  })) || [], [assetNamesData?.pages]);

  const { data: assetsTypesData = {}, isLoading: isLoadingAssetsTypes } = useQuery(
    [Queries.FetchAssetTypesOptions],
    () => fetchAssetsCount({ groupBy: 'asset_type' }),
    {
      enabled: activeCallbacks.fetchAssetTypesOptions,
    },
  );

  const assetTypesOptions = useMemo(() => (Array.isArray(assetsTypesData) && assetsTypesData?.map(({ key }) => ({
    value: key,
    label: key,
  })).sort((a, b) => a.label.localeCompare(b.label))) || [], [assetsTypesData]);

  const optionsQueries: Record<CallbackKey, CallbackProps> = useMemo(() => ({
    fetchFindingTypesOptions: {
      options: findingTypesOptions,
      isLoading: isLoadingFindingTypes,
    },
    fetchFindingTeamsOptions: {
      options: findingTeamsOptions || [],
      isLoading: isLoadingFindingsTeam || isFetchingNextPageFindingsTeam,
      onScroll: onScrollFindingsTeam,
      onInputChange: debouncedInputChangeFindingsTeam,
    },
    fetchFindingPriorityFactorsOptions: {
      options: findingPriorityFactorsOptions,
      isLoading: isLoadingPriorityFactors,
    },
    fetchAssetPriorityFactorsOptions: {
      options: assetPriorityFactorsOptions,
      isLoading: isLoadingPriorityFactors,
    },
    fetchAssetNamesOptions: {
      options: assetNamesOptions,
      isLoading: isLoadingAssetNames || isFetchingNextPageAssetNames,
      onScroll: onScrollAssetNames,
      onInputChange: debouncedInputChangeAssetNames,
    },
    fetchAssetTypesOptions: {
      options: assetTypesOptions,
      isLoading: isLoadingAssetsTypes,
    },
  }), [findingTypesOptions, isLoadingFindingTypes, findingTeamsOptions, isLoadingFindingsTeam,
    isFetchingNextPageFindingsTeam, onScrollFindingsTeam, debouncedInputChangeFindingsTeam, findingPriorityFactorsOptions,
    isLoadingPriorityFactors, assetPriorityFactorsOptions, assetNamesOptions, isLoadingAssetNames, isFetchingNextPageAssetNames, onScrollAssetNames, debouncedInputChangeAssetNames,
    assetTypesOptions, isLoadingAssetsTypes]);

  return { optionsQueries };
};
