import TableCell from '@mui/material/TableCell';
import { CSSProperties, FC, useMemo, useState } from 'react';
import { Cell, Row } from 'react-table';

import { StyledTableRow } from './JitTableRow.styles';

import { CellVerticalAlign, DefaultCellVerticalAlign } from 'components/JitTable/constants';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { ITableColumn } from 'types/interfaces/Table/ITableColumn';

export interface ElementToShowOnHoverProps {
  row: Row;
}

interface Props {
  prepareRow: (row: Row) => void;
  row: Row<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  onSelectRow?: (row: Row<any>) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  onCellClick?: (cell: Cell<object>) => void;
  isSelected: boolean;
  cellVerticalAlign?: CellVerticalAlign;
  ElementToShowOnHover?: FC<ElementToShowOnHoverProps>;
  cellPadding?: string;
  getDynamicRowHoverStyle?: (row: Row<any>) => CSSProperties | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const JitTableRow: FC<Props> = ({
  prepareRow, row, onSelectRow, onCellClick, isSelected = false, cellVerticalAlign = DefaultCellVerticalAlign,
  ElementToShowOnHover, cellPadding, getDynamicRowHoverStyle,
}) => {
  const [toggleClick, setToggleClick] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const rowHoverStyle = useMemo(() => (onSelectRow ? { cursor: 'pointer' } : {}), [onSelectRow]);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleCellClick = (cell: Cell<object>) => {
    if (onCellClick) {
      onCellClick(cell);
    }
  };

  useMemo(() => prepareRow(row), [prepareRow, row]);

  const rowProps = useMemo(() => row.getRowProps && row.getRowProps(), [row]);
  // Determine the hover style dynamically based on the row data
  const hoverStyle = useMemo(() => {
    if (getDynamicRowHoverStyle) {
      return getDynamicRowHoverStyle(row?.original);
    }
    return rowHoverStyle;
  }, [getDynamicRowHoverStyle, row.original, rowHoverStyle]);
  return (
    <StyledTableRow
      {...rowProps}
      key={row.id}
      data-testid='jitTableRow'
      hoverStyle={hoverStyle}
      isClickable={!!onSelectRow}
      isselected={isSelected ? 'true' : undefined}
      onClick={() => {
        sendAnalyticsEvent({
          action: 'clicked-row',
          params: {
            findingId: row?.original?.id,
            assetId: row?.original?.assetId,
            assetName: row?.original?.assetName,
            assetType: row?.original?.assetType,
            priorityScore: row?.original?.priorityScore,
          },
        });
        if (onSelectRow) {
          onSelectRow(row?.original);
        }
        setToggleClick(!toggleClick);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      verticalalign={cellVerticalAlign}
    >
      {row?.cells?.map((cell: Cell, index) => {
        const cellProps = cell.getCellProps((origCellProps) => ({
          ...origCellProps,
          onClick: () => handleCellClick(cell),
        }));
        const { column } = cell;
        const { style = {}, width, showOnHover }: ITableColumn = column;
        const isFirstCell = index === 0;
        const shouldRenderElementToShowOnHover = !!(isFirstCell && isHovering && ElementToShowOnHover);

        if (typeof cell.value === 'object' && cell.value !== null) {
          // eslint-disable-next-line no-param-reassign
          cell.value.toggleClick = toggleClick;
        }

        return (
          <TableCell
            {...cellProps}
            key={`${column?.id}-${row?.id}`}
            style={{
              width,
              ...style,
            }}
            sx={{ padding: cellPadding }}
          >
            {(!showOnHover || isHovering) && cell.render('Cell')}

            {shouldRenderElementToShowOnHover && <ElementToShowOnHover row={row} />}
          </TableCell>
        );
      })}
    </StyledTableRow>
  );
};
