export const NEW_WORKFLOW_PATH_PARAM = 'new';

export const DEFAULT_CONDITION = {
  id: 'default',
  type: 'sentence',
  data: {},
  children: [],
};

export const HISTORY_VIEW = 'history';
export const DIAGRAM_VIEW = 'diagram';
