import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';

import { usePolicyForm } from 'pages/PoliciesPage/components/PolicyRule/hooks/usePolicyForm';
import { RuleSettings } from 'pages/PoliciesPage/types';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { IConditionStep } from 'types/interfaces/Workflows/IWorkflow';
import { IWorkflowConditionStepOption } from 'types/interfaces/Workflows/IWorkflowStepOptions';

interface UseConditionEditorProps {
  conditionOption: IWorkflowConditionStepOption;
  selectedStep: IConditionStep;
}

export const useConditionEditor = ({
  conditionOption,
  selectedStep,
}: UseConditionEditorProps) => {
  const { setWorkflow } = useEditWorkflowContext();

  // Start in edit mode if config indicates we should be in edit mode
  const [isInEditMode, setIsInEditMode] = useState(
    selectedStep.config?.isInEditMode ?? isEmpty(selectedStep.config),
  );
  // Holds the updated rule settings locally before saving
  const [updatedSettings, setUpdatedSettings] = useState<RuleSettings>(
    selectedStep.config as RuleSettings,
  );

  /**
   * Callback for saving changes to settings
   */
  const saveChanges = useCallback(
    (settingsToSave: RuleSettings) => {
      setIsInEditMode(false);
      setWorkflow((prevWorkflow) => {
        const updatedSteps = prevWorkflow.steps.map((step) => {
          if (step.id === selectedStep.id) {
            return {
              ...step,
              config: settingsToSave,
            };
          }
          return step;
        });

        return {
          ...prevWorkflow,
          steps: updatedSteps,
        };
      });
    },
    [selectedStep.id, setWorkflow],
  );

  /**
   * Update local settings state and set edit mode to true
   */
  const onSettingsChange = useCallback((changedSettings: RuleSettings) => {
    setUpdatedSettings(changedSettings);
    setIsInEditMode(true);
  }, []);

  /**
   * Discard local changes and revert to the original config
   */
  const discardChanges = useCallback(() => {
    setUpdatedSettings(selectedStep.config as RuleSettings);
    setIsInEditMode(false);
  }, [selectedStep.config]);

  /**
   * Delete settings (make them empty) on this condition
   */
  const deleteSettings = useCallback(() => {
    const emptySettings = {} as RuleSettings;
    setUpdatedSettings(emptySettings);
    saveChanges(emptySettings);
  }, [saveChanges]);

  /**
   * Hook from usePolicyForm to track isDirty/isValid based on rule settings
   */
  const { isDirty, isValid } = usePolicyForm({
    ruleSettingsData: selectedStep.config as RuleSettings,
    editRuleSettingsData: updatedSettings,
    ruleTemplate: conditionOption?.configSchema,
    isEditMode: isInEditMode,
  });

  return {
    isInEditMode,
    setIsInEditMode,
    updatedSettings,
    saveChanges,
    onSettingsChange,
    discardChanges,
    deleteSettings,
    isDirty,
    isValid,
  };
};
