import { Paywall, Plan } from '@stigg/react-sdk';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './StiggPaywall.module.scss';

import { config } from 'config';
import { useStiggExtendedContext } from 'context/StiggContext';

export enum StiggPlanId {
  PLAN_JIT_FREE = 'plan-jit-free',
  PLAN_JIT_GROWTH = 'plan-jit-growth',
  PLAN_JIT_ENTERPRISE = 'plan-jit-enterprise',
}

interface StiggPaywallProps {
  onPlanSelected: (planId: string) => void;
}

export const StiggPaywall: FC<StiggPaywallProps> = ({ onPlanSelected }) => {
  const { setShowStiggPayWall } = useStiggExtendedContext();
  const { t } = useTranslation();

  const handlePageSelection = ({ plan }: { plan: Plan }) => {
    switch (plan.id) {
      case StiggPlanId.PLAN_JIT_FREE:
        setShowStiggPayWall(false);
        break;
      case StiggPlanId.PLAN_JIT_GROWTH:
        onPlanSelected(plan.id);
        break;
      case StiggPlanId.PLAN_JIT_ENTERPRISE:
        window.open(config.contactUsUrl, '_blank');
        break;
      default:
        console.error('Unknown plan', plan);
        break;
    }
  };

  const ctaButtonText = t('pricingPlan.paywall.cta', { returnObjects: true }) as {
    upgrade: string;
    contact: string;
    downgrade: string;
    current: string;
  };

  return (
    <div className={styles.container}>
      <Paywall
        onPlanSelected={handlePageSelection}
        textOverrides={{
          planCTAButton: {
            upgrade: ctaButtonText.upgrade,
            custom: ctaButtonText.contact,
            downgrade: ctaButtonText.downgrade,
            currentPlan: ctaButtonText.current,
          },
        }}
      />
    </div>
  );
};
