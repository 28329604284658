import { useCallback, useMemo, useState } from 'react';

import { RunnerType, RunnerTypeSelection } from '../StepComponents/RunnerTypeSelection/RunnerTypeSelection';

import { IGitlabIntegrationWizardStep, IGitlabIntegrationWizardStepStatus } from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useAssetsContext } from 'context/AssetsContext';
import { useGitlabService } from 'services/GitlabService/useGitlabService';

interface Props {
  handleClose: () => void;
}

export const useRunnerTypeSelectionStep = ({
  handleClose,
}: Props): IGitlabIntegrationWizardStep => {
  const [selectedOption, setSelectedOption] = useState<RunnerType>();
  const [hasValidSaasRunners, setHasValidSaasRunners] = useState<boolean>();
  const [isLoadingRunners, setIsLoadingRunners] = useState(false);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { listProjectRunners } = useGitlabService();
  const { centralizedRepo } = useAssetsContext();

  const onDoneButtonClick = useCallback(async () => {
    if (selectedOption === RunnerType.SaaS) {
      setIsLoadingRunners(true);
      const response = await listProjectRunners(
        centralizedRepo!.external_id!,
        {
          runner_type: RunnerType.SaaS,
          online_only: true,
        },
      );
      setIsLoadingRunners(false);
      let hasRunners = false;
      if (response!.status === 200) {
        const runners = response!.data.project_runners;
        hasRunners = runners?.length > 0;
      }
      setHasValidSaasRunners(hasRunners);
      if (!hasRunners) {
        return;
      }
    }
    sendAnalyticsEvent({
      action: 'gitlab_self_hosted_runner_completed',
    });
    handleClose();
  }, [selectedOption, sendAnalyticsEvent, handleClose, listProjectRunners, centralizedRepo, setIsLoadingRunners]);

  const onContinueNoSaasRunnersButtonClick = useCallback(() => {
    sendAnalyticsEvent({
      action: 'gitlab_self_hosted_runner_completed_no_saas_runners',
    });
    handleClose();
  }, [handleClose, sendAnalyticsEvent]);

  return useMemo(() => ({
    title: 'GitlabIntegrationWizard.runnerTypeSelection.tabText',
    component: () => (
      <RunnerTypeSelection
        hasValidSaasRunners={hasValidSaasRunners}
        isLoadingRunners={isLoadingRunners}
        onContinueNoSaasRunnersButtonClick={onContinueNoSaasRunnersButtonClick}
        onDoneButtonClick={onDoneButtonClick}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
    ),
    status: IGitlabIntegrationWizardStepStatus.ACTIVE,
  }), [hasValidSaasRunners, isLoadingRunners, onContinueNoSaasRunnersButtonClick, onDoneButtonClick, selectedOption]);
};
