import { FC } from 'react';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { TeamsProvider } from 'context/TeamsContext/TeamsProvider';
import { TeamsPage } from 'pages/TeamsPage/SubPages/TeamsPage/TeamsPage';

export const TeamsPageWrapper: FC = () => (
  <PageLayout>
    <TeamsProvider>
      <TeamsPage />
    </TeamsProvider>
  </PageLayout>
);
