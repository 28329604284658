import { isEmpty } from 'lodash';
import { FC } from 'react';

import styles from '../ConditionSection.module.scss';

import { ConditionActions } from './ConditionActions';
import { useConditionEditor } from './useConditionEditor';

import { RuleBuilder } from 'pages/PoliciesPage/components/PolicyBuilder/RuleBuilder';
import { RuleSettings, BaseRuleTemplate } from 'pages/PoliciesPage/types';
import { IConditionStep } from 'types/interfaces/Workflows/IWorkflow';
import { IWorkflowConditionStepOption } from 'types/interfaces/Workflows/IWorkflowStepOptions';

interface ConditionEditorProps {
  conditionOption: IWorkflowConditionStepOption;
  selectedStep: IConditionStep;
}

export const ConditionEditor: FC<ConditionEditorProps> = ({
  conditionOption,
  selectedStep,
}) => {
  const {
    isInEditMode,
    setIsInEditMode,
    updatedSettings,
    saveChanges,
    onSettingsChange,
    discardChanges,
    deleteSettings,
    isDirty,
    isValid,
  } = useConditionEditor({
    conditionOption,
    selectedStep,
  });

  return (
    <div className={styles.builderWrapper}>
      <RuleBuilder
        isEditMode={isInEditMode || isEmpty(updatedSettings)}
        onSettingsChange={onSettingsChange}
        ruleTemplate={conditionOption.configSchema as unknown as BaseRuleTemplate}
        settings={(updatedSettings || {}) as RuleSettings}
      />

      {!isEmpty(updatedSettings) && (
        <ConditionActions
          isDirty={isDirty}
          isInEditMode={isInEditMode}
          isValid={isValid}
          onDeleteSettings={deleteSettings}
          onDiscardChanges={discardChanges}
          onSaveChanges={saveChanges}
          onSetEditMode={setIsInEditMode}
          updatedSettings={updatedSettings}
        />
      )}
    </div>
  );
};
