import { useCallback, useEffect, useMemo, useState } from 'react';

import { IGitlabIntegrationWizardStep, IGitlabIntegrationWizardStepStatus } from '../GitlabIntegrationWizard';
import { ChooseIntegrationMethod, IntegrationRole } from '../StepComponents/ChooseIntegrationMethod/ChooseIntegrationMethod';

import { useSendAnalyticsEvent } from 'context/AnalyticsContext/hooks/useSendAnalyticsEvent';
import { useTenantContext } from 'context/TenantContext';
import { GitlabAccessLevel, IGitlabInstallationVendorAttributes } from 'types/interfaces';

interface Props {
  incrementStepIndex: () => void;
  selectedRole: IntegrationRole;
  setSelectedRole: (role: IntegrationRole) => void;
}

export const useChooseIntegrationMethodStep = ({
  incrementStepIndex,
  selectedRole,
  setSelectedRole,
}: Props): IGitlabIntegrationWizardStep => {
  const [isStepCompleted, setIsStepCompleted] = useState<boolean>(false);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { gitlabInstallation } = useTenantContext();

  useEffect(() => {
    if (gitlabInstallation) {
      const accessLevel = gitlabInstallation.vendor_attributes as IGitlabInstallationVendorAttributes;
      const role = accessLevel.access_level === GitlabAccessLevel.OWNER ? 'owner' : 'maintainer';
      setSelectedRole(role);
      setIsStepCompleted(true);
    }
  }, [gitlabInstallation, setSelectedRole]);

  const onContinueButtonClick = useCallback(() => {
    setIsStepCompleted(true);
    sendAnalyticsEvent({
      action: 'gitlab_integration_method_selected',
      params: {
        method: selectedRole,
      },
    });
    incrementStepIndex();
  }, [incrementStepIndex, selectedRole, sendAnalyticsEvent]);

  const status = useMemo(() => (isStepCompleted ? IGitlabIntegrationWizardStepStatus.COMPLETE : IGitlabIntegrationWizardStepStatus.ACTIVE), [isStepCompleted]);

  return useMemo(() => ({
    title: 'GitlabIntegrationWizard.chooseIntegrationMethod.tabText',
    component: () => (
      <ChooseIntegrationMethod
        onContinueButtonClick={onContinueButtonClick}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
      />
    ),
    status,
  }), [onContinueButtonClick, selectedRole, setSelectedRole, status]);
};
