import { FC, useEffect } from 'react';

import { NewPipelinesPage } from './NewPipelinesPage';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { usePipelineContextNew } from 'context/PipelinesContext/PipelinesContextNew';

export const NewPipelinesPageWrapper: FC = () => {
  const { fetchPipelines, filters } = usePipelineContextNew();
  useEffect(() => {
    fetchPipelines(filters);
  }, [fetchPipelines]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <PageLayout>
      <NewPipelinesPage />
    </PageLayout>
  );
};
