import { FC, useMemo } from 'react';

import { useEditWorkflowContext } from '../../EditWorkflowContext/EditWorkflowContext';

import { ActionSection } from './components/ActionSection/ActionSection';
import { ConditionSection } from './components/ConditionSection/ConditionSection';
import { RemoveStep } from './components/RemoveStep/RemoveStep';
import { SelectStepType } from './components/SelectStepType/SelectStepType';
import { StepName } from './components/StepName/StepName';
import { TriggerSection } from './components/TriggerSection/TriggerSection';
import styles from './StepDetails.module.scss';

import { JitDivider } from 'components/JitDivider/JitDivider';
import { JitSidePanel } from 'components/JitSidePanel/JitSidePanel';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { StepType } from 'types/interfaces/Workflows/IWorkflow';

export const StepDetails: FC = () => {
  const {
    workflow,
    selectedStepId,
    closeStepDetailsPanel,
  } = useEditWorkflowContext();

  const selectedStep = useMemo(() => {
    if (!workflow) return null; // Shouldn't happen
    if (workflow.trigger?.id === selectedStepId) return workflow.trigger;
    return workflow.steps.find((step) => step.id === selectedStepId);
  }, [selectedStepId, workflow]);
  if (!selectedStep) return null;

  const nonDeletableMessage = selectedStep.next.length > 0 ? 'pages.workflows.editWorkflowPage.stepDetails.removeStepTooltip.nonLeafStep' : undefined;

  return (
    <JitSidePanel closePanel={closeStepDetailsPanel} showCloseButton={false} title='pages.workflows.editWorkflowPage.stepDetails.title'>
      <div className={styles.card}>
        <div className={styles.title}>
          <JitText bold muted size='s' text='pages.workflows.editWorkflowPage.stepDetails.subtitle' />

          <div className={styles.stepNameActions}>
            <StepName selectedStep={selectedStep} />

            {selectedStep.id !== workflow?.trigger?.id && <RemoveStep nonDeletableMessage={nonDeletableMessage} />}
          </div>
        </div>

        <JitDivider color={colors.black} />

        {selectedStep?.stepType === StepType.Trigger ? <TriggerSection /> : <SelectStepType selectedStep={selectedStep} />}
      </div>

      {selectedStep?.stepType === StepType.Action && (<ActionSection selectedStep={selectedStep} />)}

      {selectedStep?.stepType === StepType.Condition && (<ConditionSection selectedStep={selectedStep} />)}
    </JitSidePanel>
  );
};
