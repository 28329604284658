import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { useHandleAssetsWebSocketNotification } from './assetHooks/useHandleAssetsWebSocketNotification';

import { useReposErrorToast } from 'context/AssetsContext/assetHooks/ReposErrorToast/useReposErrorToast';
import { AssetsContext } from 'context/AssetsContext/AssetsContext';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { getIntegrationThirdPartyVendor } from 'pages/IntegrationsPage/utils/getIntegrationThirdPartyVendor';
import { useAssetService } from 'services/AssetsService/useAssetService';
import { AssetType, Vendor, WebSocketNotificationTopics } from 'types/enums';
import { IAsset } from 'types/interfaces';
import { vendorsThatDontRequireIntegration, vendorsWithIntegration } from 'utils/constants/vendorIntegrations';
import { useAssetTypeToVendor } from 'utils/hooks/useAssetTypeToVendor';

export const AssetsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);
  const { websocketSubscribe } = useWebsocketSubscribe();
  const {
    currentScmVendor,
    getInstallationForSCMVendor,
  } = useTenantContext();
  const { getAllAssets } = useAssetService();
  const { handleAssetsWebSocketNotification } = useHandleAssetsWebSocketNotification({ setAssets });
  const { getVendorByAssetType } = useAssetTypeToVendor();

  useReposErrorToast({ assets });

  const initAssets = useCallback(async () => {
    setIsLoadingAssets(true);
    const res = await getAllAssets();
    setAssets(res || []);
    setIsLoadingAssets(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initAssets();
  }, [initAssets]);

  const getCentralizedRepoForSCMVendor = useCallback((vendor: string): IAsset | undefined => {
    const scmInstallation = getInstallationForSCMVendor(vendor);

    return assets.find((asset) => asset.asset_id === scmInstallation?.centralized_repo_asset_id);
  }, [getInstallationForSCMVendor, assets]);

  const centralizedRepo = useMemo(() => {
    if (!currentScmVendor) return undefined;

    const currentCentralizedRepo = getCentralizedRepoForSCMVendor(currentScmVendor);

    return currentCentralizedRepo;
  }, [currentScmVendor, getCentralizedRepoForSCMVendor]);

  const isCentralizedRepoAvailable = !!currentScmVendor && !!getCentralizedRepoForSCMVendor(currentScmVendor);
  const repoAssets = useMemo(() => assets.filter((asset) => asset.asset_type
    === AssetType.REPO
    && asset.asset_id
    !== centralizedRepo?.asset_id), [assets, centralizedRepo?.asset_id]);

  const awsAccountAssets = useMemo(() => assets.filter((asset) => asset.asset_type === AssetType.AWS_ACCOUNT), [assets]);

  const getAssetById = useCallback((assetId: string) => assets.find((asset) => asset.asset_id === assetId), [assets]);

  const getAssetsByVendor = useCallback((vendor: Vendor) => assets.filter((asset) => asset.vendor === vendor.toString() || asset.vendor === getIntegrationThirdPartyVendor(vendor)), [assets]);

  const isVendorProperlyIntegrated = useCallback((vendor?: Vendor) => {
    if (!!vendor && vendorsWithIntegration.includes(vendor) && !vendorsThatDontRequireIntegration.includes(vendor)) {
      const assetsByVendor = getAssetsByVendor(vendor);
      return !!assetsByVendor?.length;
    }

    return true;
  }, [getAssetsByVendor]);

  const isAssetTypeProperlyIntegrated = useCallback((assetType: AssetType) => {
    const vendor = getVendorByAssetType(assetType);
    return isVendorProperlyIntegrated(vendor);
  }, [isVendorProperlyIntegrated, getVendorByAssetType]);

  const coveredAssets = useMemo(() => assets.filter((asset) => asset.is_active && asset.is_covered && !asset.is_archived), [assets]);

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Asset, handleAssetsWebSocketNotification);
  }, [handleAssetsWebSocketNotification, websocketSubscribe]);

  const updateAssetLocally = useCallback((updatedAsset: IAsset) => {
    setAssets((prevAssets) => prevAssets.map((asset) => (asset.asset_id === updatedAsset.asset_id ? updatedAsset : asset)));
  }, []);

  const value = useMemo(() => ({
    assets,
    centralizedRepo,
    repoAssets,
    awsAccountAssets,
    initAssets,
    getAssetById,
    isLoadingAssets,
    getAssetsByVendor,
    isAssetTypeProperlyIntegrated,
    coveredAssets,
    getCentralizedRepoForSCMVendor,
    isCentralizedRepoAvailable,
    isVendorProperlyIntegrated,
    updateAssetLocally,
  }), [
    assets,
    centralizedRepo,
    repoAssets,
    awsAccountAssets,
    initAssets,
    getAssetById,
    isLoadingAssets,
    getAssetsByVendor,
    isAssetTypeProperlyIntegrated,
    coveredAssets,
    getCentralizedRepoForSCMVendor,
    isCentralizedRepoAvailable,
    isVendorProperlyIntegrated,
    updateAssetLocally,
  ]);

  return (
    <AssetsContext.Provider value={value}>
      {children}
    </AssetsContext.Provider>
  );
};
