import { FC, useCallback, useState } from 'react';

import styles from './BookTimeWidget.module.scss';

import { JittyOk, Cross } from 'assets';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import colors from 'themes/colors.module.scss';

const LOCAL_STORAGE_KEY = 'bookTimeWidgetClosed';

export const BookTimeWidget: FC = () => {
  const [isVisible, setIsVisible] = useState(!localStorage.getItem(LOCAL_STORAGE_KEY));
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleClose = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
    setIsVisible(false);
    sendAnalyticsEvent({
      action: 'book-time-widget-closed',
    });
  }, [sendAnalyticsEvent]);

  return (
    isVisible ? (
      <div className={styles.root} data-testid='book-time-widget'>
        <img alt='Jitty' className={styles.image} data-testid='book-time-widget-image' height={70} src={JittyOk} />

        <div className={styles.text}>
          <JitText data-testid='book-time-widget-title' size='l' text='widgets.bookTime.title' />

          <JitExternalLink
            analytics={{
              action: 'book-time-widget-link-clicked',
              params: { url: config.bookADemoUrl },
            }}
            href={config.bookADemoUrl}
            size='l'
            testId='book-time-widget-link'
            text='widgets.bookTime.linkText'
          />
        </div>

        <div className={styles.closeIconWrapper} onClick={handleClose} role='button' tabIndex={0}>
          <JitIcon className={styles.closeIcon} color={colors.paleMint} data-testid='book-time-widget-close' icon={Cross} size={20} />
        </div>
      </div>
    ) : null
  );
};
