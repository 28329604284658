import { FC } from 'react';

import { SecurityPlansBody } from './components';
import styles from './SecurityPlansPage.module.scss';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { PageTitles } from 'components/PageTitles/PageTitles';

export const SecurityPlansPage: FC = () => (
  <PageLayout>
    <div className={styles.wrapper} data-testid='securityPlansPageWrapper'>
      <PageTitles subtitle='pages.securityPlans.subtitle' title='pages.securityPlans.title' />

      <div className={styles.page}>
        <SecurityPlansBody />
      </div>

    </div>
  </PageLayout>
);
