import { FC } from 'react';

import styles from './NeedHelpSection.module.scss';

import { GitlabIcon, JitFavicon } from 'assets';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import colors from 'themes/colors.module.scss';
import { hexColorToRGBA } from 'utils/functions/hexColorToRGBA';

export const NeedHelpSection: FC = () => (
  <div className={styles.needHelpSection} data-testid='need-help-section'>
    <div className={styles.needHelpIcons} data-testid='need-help-icons'>
      <JitIcon
        className={styles.jitIcon}
        data-testid='jit-icon'
        icon={JitFavicon}
        size={16}
      />

      <JitIcon
        className={styles.gitlabIcon}
        data-testid='gitlab-icon'
        icon={GitlabIcon}
        size={16}
      />
    </div>

    <JitText
      className={styles.needHelpText}
      color={hexColorToRGBA(colors.premiumPink, 0.5)}
      data-testid='need-help-text'
      text='GitlabIntegrationWizard.integrateGitlab.needHelpQuestion'
    />

    <JitExternalLink
      className={styles.bookTimeWithUsLink}
      href={config.bookADemoUrl}
      showIcon={false}
      size='s'
      testId='book-time-with-us'
      text='GitlabIntegrationWizard.integrateGitlab.bookTimeWithUs'
      textColor={colors.blue02}
    />
  </div>
);
