import { FC } from 'react';

import styles from './IconsContainer.module.scss';

import { JitFavicon, ArrowRight } from 'assets';
import { getIconByVendor } from 'components/AssetType/utils/getIconByVendor';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { ISvg } from 'types/interfaces';

interface IconsContainerProps {
  vendors: string[];
}

export const IconsContainer: FC<IconsContainerProps> = ({ vendors }) => {
  const uniqueVendors = Array.from(new Set(vendors));
  const vendorToIcon: Map<string, FC<ISvg>> = Object.fromEntries(
    uniqueVendors.map((vendor) => [vendor, getIconByVendor(vendor, undefined, true)]).filter(([, icon]) => icon),
  );

  if (!Object.keys(vendorToIcon).length) {
    return null;
  }

  return (
    <div className={styles.iconsContainer}>
      <JitIcon icon={JitFavicon} />

      <JitIcon icon={ArrowRight} />

      <div className={styles.vendorIcons}>
        {
          // limit to the first 3 icons
          Object.entries(vendorToIcon).slice(0, 3).map(([vendor, icon]) => (
            <JitIcon key={vendor} icon={icon} />
          ))
      }
      </div>
    </div>
  );
};
