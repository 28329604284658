import { FC, useState } from 'react';

import { JitSwitchToggle } from 'components/JitSwitchToggle/JitSwitchToggle';
import { ITableCell } from 'types/interfaces';

interface ISwitchCell {
  isChecked: boolean;
  entity: unknown;
  isDisabled: boolean;
  isReadOnly: boolean;
  onClick: (checked: boolean, entity: unknown) => void;
}

export const SwitchCell: FC<ITableCell> = ({ cell: { value } }) => {
  const { isChecked, entity, isDisabled, onClick, isReadOnly } = value as ISwitchCell;

  const [checked, setChecked] = useState(isChecked);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) return;
    setChecked(event.target.checked);
    onClick(event.target.checked, entity);
  };

  return (
    <JitSwitchToggle
      checked={checked}
      disabled={isDisabled}
      isReadOnly={isReadOnly}
      onChange={handleChange}
    />
  );
};
