import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from './TooltipOnlyOnOverflow.module.scss';

import { JitText, JitTextProps } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';

interface Props extends JitTextProps {
  tooltipText?: string | string[]
  placement?: 'top' | 'right' | 'bottom' | 'left' | 'bottom-end' | 'bottom-start' | 'left-end' | 'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start'
}

export const TooltipOnlyOnOverflow: FC<Props> = ({ text = '', tooltipText, children, placement, ...jitTextProps }) => {
  const textElementRef = useRef<HTMLSpanElement | null>(null);
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);

  const getElementDimensions = useCallback(() => {
    const element = textElementRef.current;
    if (!element) return null;

    const targetElement = element.children[0] || element;

    return {
      clientWidth: targetElement.clientWidth,
      clientHeight: targetElement.clientHeight,
      scrollWidth: targetElement.scrollWidth,
      scrollHeight: targetElement.scrollHeight,
    };
  }, []);

  const checkOverflow = useCallback(() => {
    const dimensions = getElementDimensions();
    if (!dimensions) return;

    const { clientWidth, clientHeight, scrollWidth, scrollHeight } = dimensions;
    const hasOverflow = scrollWidth > clientWidth || scrollHeight > clientHeight;
    setIsOverflowed(hasOverflow);
  }, [getElementDimensions]);

  useLayoutEffect(() => {
    checkOverflow();
  }, [checkOverflow, text, children]);

  useEffect(() => {
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [checkOverflow]);

  return (
    <JitTooltip
      childrenWrapperClassName={styles.tooltipChildren}
      disableHoverListener={!isOverflowed}
      followCursor={false}
      placement={placement || 'top'}
      title={tooltipText || text}
    >
      <span
        ref={textElementRef}
        className={styles.tooltipChildren}
      >
        {children || (
          <JitText
            className={styles.tooltipChildren}
            data-testid={jitTextProps['data-testid'] || 'TooltipOnlyOnOverflow-text'}
            noWrap
            overflowHiddenEllipsis
            text={text}
            {...jitTextProps}
          />
        )}
      </span>
    </JitTooltip>
  );
};
