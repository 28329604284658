import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import { FC } from 'react';

import styles from './PremiumFeatureBanner.module.scss';

import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { useGetCustomerTrialDetails } from 'context/StiggContext/useGetCustomerTrialDetails';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

interface PremiumFeatureBannerProps {
  isEntitled: boolean;
  message?: string;
}

export const PremiumFeatureBanner: FC<PremiumFeatureBannerProps> = ({ isEntitled, message }) => {
  const { isOnTrial, daysLeft, isLoading } = useGetCustomerTrialDetails(true);

  if (isLoading || (isEntitled && !isOnTrial)) {
    return null;
  }

  let bannerMessage = i18n.t('common.pricingPlan.premiumFeature');

  if (isEntitled && isOnTrial) {
    const key = daysLeft === 1
      ? 'common.pricingPlan.trialDaysLeftSingular'
      : 'common.pricingPlan.trialDaysLeftPlural';

    bannerMessage += ` ${i18n.t(key, { daysLeft })}`;
  }

  if (!isEntitled && !isOnTrial) {
    if (message) {
      bannerMessage += ` ${message}`;
    }
  }

  return (

    <div className={styles.wrapper}>
      <DiamondOutlinedIcon
        fontSize='small'
        sx={{
          color: colors.upgradeColor,
          marginRight: '6px',
        }}
      />

      <JitText color={colors.upgradeColor} text={bannerMessage} />

      {/* Todo:
      implement onClick when the upgrade plan withing the app is ready
      currently it opens a new tab to the contact us page */}
      <JitLink
        className={styles.docsLink}
        data-testid='contactUsLink'
        href={config.contactUsUrl}
        rel='noreferrer'
        target='_blank'
        underline='none'
      >
        <JitText bold color={colors.upgradeColor} text='common.pricingPlan.upgradeNow' />
      </JitLink>
    </div>

  );
};
