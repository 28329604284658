import { FC, ReactElement } from 'react';

import { JitTooltip, JitTooltipProps } from '../JitTooltip';

interface Props extends Omit<JitTooltipProps, 'title'> {
  children: ReactElement;
}

export const JitReadOnlyTooltip: FC<Props> = ({ children, ...props }) => (
  <JitTooltip data-testid='readOnlyTooltip' followCursor={false} placement='top' title='readOnly.tooltip' {...props}>
    {children}
  </JitTooltip>
);
