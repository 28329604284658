import { t } from 'i18next';
import { FC, useMemo, useCallback, useState } from 'react';

import styles from './ActionSection.module.scss';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { JitText } from 'components/JitText/JitText';
import { ActionConfigInput } from 'pages/WorkflowsPage/EditWorkflowPage/components/StepDetails/components/ActionSection/ActionConfigInput';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { IActionStep } from 'types/interfaces/Workflows/IWorkflow';
import { IWorkflowActionStepOption } from 'types/interfaces/Workflows/IWorkflowStepOptions';

interface Props {
  selectedStep: IActionStep;
}

export const ActionSection: FC<Props> = ({ selectedStep }) => {
  const {
    setWorkflow,
    stepsOptions,
    setSelectedStepId,
    selectedStepId,
  } = useEditWorkflowContext();

  const actionOptions = useMemo(() => (stepsOptions?.actionOptions || []), [stepsOptions]);

  const preSelectedActionType = useMemo(
    () => actionOptions.find((option) => option.type === selectedStep.type),
    [actionOptions, selectedStep.type],
  );

  const [selectedActionType, setSelectedActionType] = useState<IWorkflowActionStepOption | undefined>(preSelectedActionType);

  const onSelectActionOptions = useCallback((item: IWorkflowActionStepOption) => {
    setSelectedActionType(item);
    const actionOption = actionOptions.find((option) => option.type === item.type);
    if (!actionOption) return;

    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      steps: prevWorkflow.steps.map((step) => (step.id === selectedStep.id ? {
        ...step,
        type: actionOption.type,
        name: actionOption.label,
      } : step)),
    }));
    setSelectedStepId(selectedStepId);
  }, [actionOptions, selectedStep.id, selectedStepId, setSelectedStepId, setWorkflow]);

  const actionOption = useMemo(
    () => actionOptions.find((option) => option.type === selectedStep.type),
    [actionOptions, selectedStep.type],
  );

  const configSectionsToShow = useMemo(() => {
    if (!actionOption) return [];
    return actionOption.configSchema.filter((section) => {
      if (!section.dependsOn) return true;
      return Object.entries(section.dependsOn).every(([key, value]) => selectedStep.config[key] === value);
    });
  }, [actionOption, selectedStep.config]);

  const updateActionConfig = useCallback((key: string, value: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      steps: prevWorkflow.steps.map((step) => (step.id === selectedStep.id ? {
        ...step,
        config: {
          ...step.config,
          [key]: value,
        },
      } : step)),
    }));
  }, [selectedStep.id, setWorkflow]);

  return (
    <>
      <div className={styles.actionSection} data-testid='selectActionType'>
        <JitText bold muted size='s' text='pages.workflows.editWorkflowPage.stepDetails.action.ctaQuestion' />

        <div>
          <JitAutoCompleteForm
            autoFocus={!selectedActionType}
            clearOnBlur={false}
            fitContent
            fontSize={14}
            getOptionKey={(option) => option.type}
            getOptionLabel={(option) => option.label}
            isSingleValue
            options={actionOptions}
            placeHolder={t`pages.workflows.editWorkflowPage.stepDetails.action.addAction`}
            selectedValue={selectedActionType}
            setSelectedValue={onSelectActionOptions}
            showArrow={false}
            variant='light'
          />
        </div>
      </div>

      {configSectionsToShow.map((configSchema) => (
        <div key={configSchema.key} className={styles.actionSection}>
          <JitText bold muted size='s' text={configSchema.description} />

          <ActionConfigInput
            actionConfig={selectedStep.config}
            configSchema={configSchema}
            updateActionConfig={updateActionConfig}
          />
        </div>
      ))}
    </>
  );
};
