import { FC } from 'react';

import { WelcomePageContent } from './components/WelcomePageContent/WelcomePageContent';
import styles from './WelcomePage.module.scss';

import { Background } from 'components/Pages/Background/Background';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';

export const WelcomePage: FC = () => (
  <PageLayout>
    <div className={styles.root} data-testid='teams-portal-welcome-page'>
      <Background />

      <div className={styles.content}>
        <WelcomePageContent />
      </div>

    </div>
  </PageLayout>
);
