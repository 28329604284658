import { t } from 'i18next';
import { FC, useMemo } from 'react';

import styles from '../common/TokenInput.module.scss';

import { getCollapsibleSteps } from './collapsibleSteps';
import { connectWebhooksCurlCommand } from './copyCurlCommand';

import { GitlabIcon, Info } from 'assets';
import { useSecretManagement } from 'components/GitlabIntegrationWizard/hooks/useSecretManagement';
import { JitCopyToClipboardBox } from 'components/JitCopyToClipboardBox/JitCopyToClipboardBox';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitPasswordInput } from 'components/JitPasswordInput/JitPasswordInput';
import { JitText } from 'components/JitText/JitText';
import { useTenantContext } from 'context/TenantContext';
import { CollapseSection } from 'pages/OverviewPage/components/CollapseSection/CollapseSection';
import colors from 'themes/colors.module.scss';

interface Props {
  incrementStepIndex: () => void;
  secretExists: boolean;
  secretName: string;
}

export const ConnectWebhooks: FC<Props> = ({ incrementStepIndex, secretExists, secretName }) => {
  const { installations } = useTenantContext();
  const tenantId = installations[0]?.tenant_id;
  const installationId = installations[0]?.installation_id;

  const {
    secretValue,
    isLoading,
    onSecretValueChange,
    onContinueButtonClick,
  } = useSecretManagement(secretExists, secretName, incrementStepIndex);

  const getTooltipText = useMemo(() => {
    if (isLoading) return t('GitlabIntegrationWizard.connectWebhooks.creatingSecret');
    if (!secretExists && !secretValue) return t('GitlabIntegrationWizard.connectWebhooks.missingTokenTooltip');
    return undefined;
  }, [isLoading, secretExists, secretValue]);

  const actionButtons = useMemo(() => [{
    label: 'GitlabIntegrationWizard.connectWebhooks.nextButton',
    handleClick: onContinueButtonClick,
    disabled: (!secretExists && !secretValue) || isLoading,
    isLoading,
    tooltip: getTooltipText,
  }], [onContinueButtonClick, secretValue, secretExists, isLoading, getTooltipText]);

  const renderedSteps = useMemo(() => getCollapsibleSteps(tenantId, installationId).map((step, index) => (
    <div key={step.stepId} className={styles.stepWrapper}>
      <CollapseSection
        header={(
          <div className={styles.stepHeader}>
            <JitText bold color={colors.gray} size='xs' text={`${index + 1}`} />

            {step.titleComponent}
          </div>
        )}
      >
        {step.bodyComponent}
      </CollapseSection>
    </div>
  )), [installationId, tenantId]);

  const passwordInputProps = useMemo(() => ({
    className: styles.inputFieldBox,
    autoFocus: true,
    disableUnderline: !!secretExists,
    fullWidth: true,
    value: secretExists ? t('GitlabIntegrationWizard.connectWebhooks.tokenInput.mockSecret') : secretValue,
    onChange: onSecretValueChange,
    dataTestid: 'tokenInput',
    placeholder: t('GitlabIntegrationWizard.connectWebhooks.tokenInput.placeholder'),
  }), [onSecretValueChange, secretValue, secretExists]);

  return (
    <DialogContentWrapper
      actionButtons={actionButtons}
      icon={GitlabIcon}
    >
      <div className={styles.content}>
        <JitText bold size='xl' text='GitlabIntegrationWizard.connectWebhooks.title' />

        <JitText
          components={{
            boldDescription: <JitText bold display='inline' text='GitlabIntegrationWizard.connectWebhooks.boldDescription' />,
          }}
          muted
          text='GitlabIntegrationWizard.connectWebhooks.description'
        />

        <div className={styles.guidelineWrapper}>
          <JitText muted size='s' text='GitlabIntegrationWizard.connectWebhooks.guidelines' />

          <div className={styles.copyToClipboardWrapper}>
            <JitText muted size='s' text='GitlabIntegrationWizard.connectWebhooks.curl.prefix' />

            <JitCopyToClipboardBox
              displayText='GitlabIntegrationWizard.connectWebhooks.curl.copyButtonText'
              iconSize={14}
              size='s'
              text={connectWebhooksCurlCommand({
                tenantId,
                installationId,
              })}
              textColor={colors.iris}
              variant='minimal'
            />
          </div>
        </div>

        <div className={styles.stepsContainer}>
          {renderedSteps}
        </div>

        <div className={styles.secretInputWrapper}>
          <JitText bold text='GitlabIntegrationWizard.connectWebhooks.tokenInput.title' />

          <div className={styles.inputBox}>
            <JitPasswordInput
              disabled={secretExists}
              inputProps={passwordInputProps}
              preventShowPassword={secretExists}
            />
          </div>

          <JitInfoBanner bgColor={colors.cardContent} icon={Info} iconColor={colors.blue}>
            <JitText
              color={colors.white}
              params={{ secretName }}
              text={secretExists
                ? 'GitlabIntegrationWizard.connectWebhooks.tokenInput.alreadyExistsBanner'
                : 'dialogs.secretsManagement.createSecret.infoMessage'}
            />
          </JitInfoBanner>
        </div>
      </div>
    </DialogContentWrapper>
  );
};
