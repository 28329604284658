import { useCallback } from 'react';
import { Id, toast, ToastOptions, TypeOptions } from 'react-toastify';

import { JitSnackBar } from 'components/JitSnackBar/JitSnackBar';
import { constants } from 'globalConstants';
import { IMouseEvent } from 'types/interfaces';

type PickOptionsObject = Pick<ToastOptions, 'autoClose'>;
export type SnackBarType = TypeOptions | 'loading';
export type SnackBarAction = {
  label: string;
  handleClick: (e: IMouseEvent) => void;
};

export type ShowSnackbarProps = {
  title: string;
  description?: string;
  type: SnackBarType;
  options?: PickOptionsObject;
  action?: SnackBarAction;
};

type UpdateSnackbarProps = ShowSnackbarProps & {
  notificationId: Id;
};

const {
  time: { SECOND },
} = constants;

export const useSnackBar = () => {
  const showSnackBar = useCallback(({ title, description, type, action, options }: ShowSnackbarProps) => {
    const content = (
      <JitSnackBar
        action={action}
        description={description}
        title={title}
        type={type}
      />
    );

    return toast(content, {
      autoClose: type === 'loading' ? false : SECOND * 5,
      ...options,
    });
  }, []);

  const updateSnackBar = useCallback(({ notificationId, title, description, type, action, options }: UpdateSnackbarProps) => {
    const content = (
      <JitSnackBar
        action={action}
        description={description}
        title={title}
        type={type}
      />
    );

    toast.update(notificationId, {
      render: content,
      autoClose: SECOND * 5,
      ...options,
    });
  }, []);

  const hideSnackBar = useCallback((notificationId: Id) => toast.dismiss(notificationId), []);

  return {
    showSnackBar,
    updateSnackBar,
    hideSnackBar,
  };
};
