import { ErrorBoundary } from '@sentry/react';
import { FC, PropsWithChildren } from 'react';

import { JittyErrorBoundary } from 'assets';
import { GeneralErrorPage } from 'components/GeneralErrorPage/GeneralErrorPage';

export const Fallback = () => (
  <GeneralErrorPage
    description='pages.pageLevelError.description'
    icon={JittyErrorBoundary}
    iconSize={300}
    title='pages.pageLevelError.title'
  />
);

// eslint-disable-next-line react/no-multi-comp
export const PageLevelErrorBoundary: FC<PropsWithChildren> = ({ children }) => (
  <ErrorBoundary fallback={Fallback}>
    {children}
  </ErrorBoundary>

);
