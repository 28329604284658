import { isEmpty } from 'lodash';

import { useEditWorkflowContext } from '../../EditWorkflowContext/EditWorkflowContext';

import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

export const useValidateWorkflow = (workflow?: IWorkflow) => {
  const { stepsOptions } = useEditWorkflowContext();
  if (!workflow) {
    return null;
  }
  const conditions = workflow?.steps.filter((step) => step.stepType === 'condition') || [];
  const allConditionsConfigured = conditions.every((step) => !isEmpty(step.config.conditions));
  const allConditionsHaveNextStep = conditions.every((step) => step.next.length > 0);
  const actions = workflow?.steps.filter((step) => step.stepType === 'action') || [];
  const hasActions = actions.length > 0;
  const actionOptions = stepsOptions?.actionOptions || [];
  const isAllActionsConfigured = actions.every((step) => {
    const stepSchema = actionOptions.find((option) => option.type === step.type);
    if (!stepSchema?.configSchema) return true;
    return stepSchema.configSchema.every((configSection) => step.config[configSection.key] !== undefined);
  });

  const validationErrors = {
    allConditionsConfigured,
    allConditionsHaveNextStep,
    hasActions,
    isAllActionsConfigured,
  };

  const validationErrorMessages = Object.entries(validationErrors).filter(([,isValid]) => !isValid)
    .map(([message]) => `pages.workflows.editWorkflowPage.header.cantSaveReasons.workflowInvalid.${message}`);
  return validationErrorMessages[0] || null;
};
