import { useMutation, useQueryClient } from 'react-query';

import { IPolicyRule, IPolicyTemplate, RuleSettings } from 'pages/PoliciesPage/types';
import { UpdatePolicyPayload, usePoliciesService } from 'services/PoliciesService/usePoliciesService';
import { Queries } from 'types/enums/Queries';
import { useSnackBar } from 'utils/hooks/useSnackBar';

interface UsePolicyMutationsProps {
  onCreate?: (rule: IPolicyRule) => void;
  onUpdate?: (rule: IPolicyRule) => void;
  policySlug: string;
  ruleSlug: string;
}

const DEFAULT_ENABLED = true;

export const usePolicyMutations = ({
  onCreate,
  onUpdate,
  policySlug,
  ruleSlug,
}: UsePolicyMutationsProps) => {
  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBar();
  const { createPolicyRule, updatePolicyRule, deletePolicyRule } = usePoliciesService();

  const updateEnabledRulesCount = (
    templates: IPolicyTemplate[],
    increment: boolean,
  ): IPolicyTemplate[] => templates.map((template) => {
    if (template.policySlug === policySlug) {
      const countChange = increment ? 1 : -1;
      return {
        ...template,
        dynamicData: {
          ...template.dynamicData,
          enabledRulesCount: template.dynamicData.enabledRulesCount + countChange,
        },
      };
    }
    return template;
  });

  const { mutate: saveRule, isLoading: isLoadingSaveRule } = useMutation(
    async (settings: RuleSettings) => createPolicyRule({
      policySlug,
      ruleSlug,
      settings,
      enabled: DEFAULT_ENABLED,
    }),
    {
      onSuccess: (createdRule: IPolicyRule) => {
        if (onCreate) onCreate(createdRule);
        queryClient.setQueryData(
          [Queries.policyRules, policySlug],
          (oldData: IPolicyRule[] = []) => [...oldData, createdRule],
        );

        if (DEFAULT_ENABLED) {
          queryClient.setQueryData(
            [Queries.policiesTemplate],
            (oldData: IPolicyTemplate[] = []) => updateEnabledRulesCount(oldData, true),
          );
        }
      },
      onError: () => {
        showSnackBar({
          title: 'pages.policies.rule.buttons.add.error',
          type: 'error',
        });
      },
    },
  );

  const { mutate: updateRule, isLoading: isLoadingUpdateRule } = useMutation(
    async ({ id, payload }: { id: string; payload: UpdatePolicyPayload }) => updatePolicyRule(id, payload),
    {
      onSuccess: (updatedRule: IPolicyRule, { payload }) => {
        if (onUpdate) onUpdate(updatedRule);

        if (payload.enabled !== undefined) {
          queryClient.setQueryData(
            [Queries.policiesTemplate],
            (oldData: IPolicyTemplate[] = []) => updateEnabledRulesCount(oldData, updatedRule.enabled),
          );
        }

        queryClient.setQueryData(
          [Queries.policyRules, policySlug],
          (oldData: IPolicyRule[] = []) => oldData.map((rule) => (rule.id === updatedRule.id ? updatedRule : rule)),
        );
      },
      onError: () => {
        showSnackBar({
          title: 'pages.policies.rule.buttons.edit.error',
          type: 'error',
        });
      },
    },
  );

  const { mutate: deleteRule, isLoading: isLoadingDeleteRule } = useMutation(
    async (id: string) => deletePolicyRule(id),
    {
      onSuccess: (_, id) => {
        queryClient.invalidateQueries([Queries.policiesTemplate]);
        queryClient.setQueryData(
          [Queries.policyRules, policySlug],
          (oldData: IPolicyRule[] = []) => oldData.filter((rule) => rule.id !== id),
        );
      },
    },
  );

  return {
    saveRule,
    isLoadingSaveRule,
    updateRule,
    isLoadingUpdateRule,
    deleteRule,
    isLoadingDeleteRule,
  };
};
