import { useState } from 'react';

import { useToastsContext } from 'context/ToastsContext/ToastsContext';
import { i18n } from 'locale/i18n';
import { useNavigatePipelinesDemo } from 'pages/PipelinesPage/hooks/useNavigatePipelinesDemo';
import { useExecutionsService } from 'services/ExecutionsService/useExecutionsService';
import { useTriggerService } from 'services/TriggerService/useTriggerService';
import { ToastType } from 'types/enums';
import { ActionButton, ActionButtonVariant } from 'types/interfaces';

interface Props {
  plan_items: string[];
  assetId: string;
}

export const useGetScanButton = ({ plan_items, assetId }: Props): ActionButton => {
  const { showToast } = useToastsContext();
  const { manualExecute } = useTriggerService();
  const { fetchExecutions } = useExecutionsService();
  const { pipelinePageLink } = useNavigatePipelinesDemo();
  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    setIsLoading(true);
    try {
      const ongoingScan = await fetchExecutions({
        status: 'running',
        asset_id: assetId,
      });
      const ongoingScans = ongoingScan?.data || [];

      let alreadyRunningCount = 0;
      let successCount = 0;
      let failureCount = 0;

      await Promise.all(
        plan_items.map(async (planItem) => {
          const ongoingScanForItem = ongoingScans.find((scan) => scan.plan_item_slug === planItem);

          if (ongoingScanForItem) {
            alreadyRunningCount += 1;
          } else {
            try {
              const result = await manualExecute(planItem, assetId);

              if (result?.status === 201) {
                successCount += 1;
              } else {
                failureCount += 1;
              }
            } catch {
              failureCount += 1;
            }
          }
        }),
      );

      const totalItems = plan_items.length;
      const title = i18n.t('dialogs.assetsManagement.actions.scan.summary', {
        total: totalItems,
        success: successCount,
        skipped: alreadyRunningCount,
        failed: failureCount,
      });

      showToast({
        type: ToastType.Info,
        overrideProps: {
          title,
          content: pipelinePageLink,
          secondsToClose: 6,
        },
      });
    } catch (error) {
      showToast({
        type: ToastType.Error,
        overrideProps: {
          title: 'dialogs.assetsManagement.actions.scan.error',
          secondsToClose: 3,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    label: 'dialogs.assetsManagement.actions.scan.label',
    handleClick: onClick,
    disabled: isLoading,
    isLoading,
    variant: ActionButtonVariant.OUTLINED,
    analytics: {
      action: 'manual-execute-item-for-asset',
      params: {
        'plan-items': plan_items.join(', '),
        'asset-id': assetId,
      },
    },
  };
};
