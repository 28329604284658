import { ReactElement, useEffect, useState } from 'react';

import { DataType, IPolicyRule, BaseRuleTemplate, InputOption } from 'pages/PoliciesPage/types';
import { getConditionSentence } from 'pages/PoliciesPage/utils';

type Props = {
  template: BaseRuleTemplate;
  settings: IPolicyRule['settings'];
  isEditMode: boolean;
  onFieldChange: (field: string, value: DataType, index?: number) => void;
};

export const useRuleBuilder = ({ template, settings, isEditMode, onFieldChange }: Props) => {
  const [baseConditionElements, setBaseConditionElements] = useState<ReactElement[]>([]);
  const [filterConditionElements, setFilterConditionElements] = useState<ReactElement[][]>([]);

  const { baseCondition, filterConditions, inputs: inputTemplates } = template;

  useEffect(() => {
    const loadSentences = async () => {
      if (baseCondition) {
        const baseSentence = await getConditionSentence({
          conditionSentence: baseCondition,
          inputTemplates,
          data: settings,
          isEditMode,
          onFieldChange,
        });
        setBaseConditionElements(baseSentence);

        Object.entries(inputTemplates).forEach(([key, input]) => {
          if (input?.defaultValue && !settings[key]) {
            const defaultVal = Array.isArray(input.defaultValue)
              ? input.defaultValue.map((opt) => opt.value)
              : (input.defaultValue as InputOption).value;
            onFieldChange(key, defaultVal);
          }
        });
      }

      if (!settings?.conditions) {
        setFilterConditionElements([]);
        return;
      }

      const filterSentences = await Promise.all(
        settings.conditions.map((condition, index) => getConditionSentence({
          conditionSentence: filterConditions,
          inputTemplates,
          data: condition,
          isEditMode,
          onFieldChange,
          index,
        })),
      );

      setFilterConditionElements(filterSentences);
    };

    loadSentences();
  }, [baseCondition, filterConditions, inputTemplates, settings, isEditMode, onFieldChange]);

  return {
    baseConditionSentence: baseConditionElements,
    filterConditionSentence: filterConditionElements,
  };
};
