import { useMemo, useState } from 'react';

import { useChooseGitlabGroupStep } from './useChooseGitlabGroupStep';
import { useChooseGitlabProjectsTemporaryStep } from './useChooseGitlabProjectsTemporaryStep';
import { useIntegrateGitlabStep } from './useIntegrateGitlabStep';

import { IGroup } from 'services/GitlabService/useGitlabService';

interface Props {
  incrementStepIndex: () => void;
  handleClose: () => void;
}

export const useTemporaryWizardSteps = ({ incrementStepIndex, handleClose }: Props) => {
  const [selectedGroup, setSelectedGroup] = useState<IGroup>();

  const integrateGitlabStep = useIntegrateGitlabStep({
    incrementStepIndex,
  });

  const chooseGitlabGroupStep = useChooseGitlabGroupStep({
    incrementStepIndex,
    selectedGroup,
    setSelectedGroup,
  });

  const chooseGitlabProjectsStep = useChooseGitlabProjectsTemporaryStep({
    selectedGroup,
    handleClose,
  });

  const steps = useMemo(
    () => [integrateGitlabStep, chooseGitlabGroupStep, chooseGitlabProjectsStep],
    [integrateGitlabStep, chooseGitlabGroupStep, chooseGitlabProjectsStep],
  );

  return steps;
};
