import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { CSSProperties, FC, UIEvent, useMemo } from 'react';
import { Cell, Row } from 'react-table';

import { ElementToShowOnHoverProps, JitTableHeader, JitTablePagination, JitTableRow } from './components';
import { GroupedRow, GroupedTableRows } from './components/GroupedTableRows/GroupedTableRows';
import { CellVerticalAlign, DefaultCellVerticalAlign } from './constants';
import styles from './JitTable.module.scss';

import { JittyEmpty } from 'assets';
import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import { ITableInstance as ITableInstanceOld } from 'components/JitTable/hooks/useGetTableInstance';
import { ITableInstance as ITableInstanceNew } from 'components/JitTable/hooks/useManageTable';
import { calcShouldFetchMore } from 'components/JitTable/utils';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { ISvg, ITableRow } from 'types/interfaces';

interface Props {
  onSelectRow?: (row: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  onCellClick?: (cell: Cell<object>) => void;
  selectedRow?: ITableRow | null;
  entityName?: string;
  isLoading: boolean;
  isFetching?: boolean; // sc-5861 - Different JitTable component for infinite scroll
  handleScroll?: (event: UIEvent<HTMLDivElement>) => void;
  className?: string;
  tableInstance: ITableInstanceOld | ITableInstanceNew;
  EmptyTableView?: FC;
  cellsNumber?: number;
  cellVerticalAlign?: CellVerticalAlign;
  emptyTableText?: string;
  emptyTableSubtext?: string;
  jittyIcon?: FC<ISvg> | null;
  jittyHeight?: string;
  jittyWidth?: string;
  ElementToShowOnRowHover?: FC<ElementToShowOnHoverProps>;
  groupRowsBy?: (page: Row<ITableRow>[]) => GroupedRow[] | undefined;
  cellPadding?: string;
  headerCellPadding?: string;
  showPagination?: boolean;
  pageMaxLength?: number;
  totalRecordsAmount?: number;
  enableScrolling?: boolean;
  getDynamicRowHoverStyle?: (row: any) => CSSProperties; // eslint-disable-line @typescript-eslint/no-explicit-any
  loadingText?: string;
  handleReachScrollEnd?: {
    threshold?: number;
    callback: () => void;
  };
}

export const JitTable: FC<Props> = ({
  onSelectRow,
  onCellClick,
  isLoading,
  isFetching,
  handleScroll,
  className,
  entityName,
  selectedRow,
  tableInstance,
  cellsNumber = 1,
  cellVerticalAlign = DefaultCellVerticalAlign,
  EmptyTableView,
  emptyTableText,
  emptyTableSubtext,
  jittyIcon = JittyEmpty,
  jittyHeight,
  jittyWidth,
  ElementToShowOnRowHover,
  groupRowsBy,
  cellPadding,
  headerCellPadding,
  showPagination = true,
  totalRecordsAmount,
  pageMaxLength,
  enableScrolling = true,
  getDynamicRowHoverStyle,
  loadingText,
  handleReachScrollEnd,
}) => {
  const { t } = i18n;

  if (showPagination && !pageMaxLength) throw new Error('pageMaxLength is required when showPagination is true');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    getNextPage,
    getPreviousPage,
    canPreviousPage,
    canNextPage,
    pageIndex,
  } = tableInstance;

  const tableProps = useMemo(() => getTableProps(), [getTableProps]);
  const tableBodyProps = useMemo(() => getTableBodyProps(), [getTableBodyProps]);
  const shouldShowTable = useMemo(() => !isLoading && page.length > 0, [isLoading, page]);

  const groupedRows = useMemo(() => (groupRowsBy && groupRowsBy(page)), [groupRowsBy, page]);

  const loadingBarSxProps = isFetching ? { sx: { w: 1 } } : {};

  const backgroundColor = colors.transparent;
  const wrapperClassName = styles.wrapperLight;

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    if (handleScroll) {
      handleScroll(event);
    }

    if (handleReachScrollEnd && calcShouldFetchMore(event, handleReachScrollEnd.threshold || 200)) {
      handleReachScrollEnd.callback();
    }
  };

  const content = (
    <>
      <TableContainer
        onScroll={onScroll}
        sx={{
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor,
            marginTop: '10px',
            marginBottom: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.darkGray,
          },
          height: '100%',
          marginLeft: '-3px',
          overflowY: enableScrolling ? 'auto' : 'hidden',
        }}
      >
        <Table
          {...tableProps}
          className={styles.table}
          stickyHeader
          sx={{
            backgroundColor,
            borderSpacing: !isLoading && page.length === 0 ? '' : '0 8px !important',
            // prevent header "jumping" when scrolling
            marginTop: '-8px',
          }}
        >
          <JitTableHeader cellPadding={headerCellPadding} headerGroups={headerGroups} />

          {shouldShowTable && (
            <TableBody className={styles.body} {...tableBodyProps}>
              {(groupRowsBy && groupedRows)
                ? (
                  <GroupedTableRows
                    cellPadding={cellPadding}
                    cellsNumber={cellsNumber}
                    cellVerticalAlign={cellVerticalAlign}
                    ElementToShowOnRowHover={ElementToShowOnRowHover}
                    groupedRows={groupedRows}
                    onSelectRow={onSelectRow}
                    prepareRow={prepareRow}
                    selectedRow={selectedRow}
                  />
                )
                : page?.map((row) => (
                  <JitTableRow
                    key={row.id}
                    cellPadding={cellPadding}
                    cellVerticalAlign={cellVerticalAlign}
                    ElementToShowOnHover={ElementToShowOnRowHover}
                    getDynamicRowHoverStyle={getDynamicRowHoverStyle}
                    isSelected={!!(selectedRow?.id && row?.original?.id === selectedRow?.id)}
                    onCellClick={onCellClick}
                    onSelectRow={onSelectRow}
                    prepareRow={prepareRow}
                    row={row}
                  />
                ))}
            </TableBody>
          )}
        </Table>

        {(isLoading || isFetching) && (
          <div className={styles.loaderWrapper}>
            <LoadingBar {...loadingBarSxProps} text={loadingText} />
          </div>
        )}

        <div className={styles.emptyTableWrapper} {...page.length && { style: { display: 'none' } }}>
          {!isLoading && (totalRecordsAmount === undefined || totalRecordsAmount === 0) && page.length === 0 && (
            (EmptyTableView && <EmptyTableView />)
            || (
              <JitEmpty
                description={emptyTableSubtext}
                descriptionColor={colors.lightGray}
                height={jittyHeight}
                icon={jittyIcon ?? undefined}
                showJitty
                title={t(emptyTableText || 'table.noData', { entityName })}
                titleColor={colors.white}
                width={jittyWidth}
              />
            ))}
        </div>
      </TableContainer>

      {showPagination && !!page.length && !!totalRecordsAmount && (
        <JitTablePagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          currentPageLength={page.length}
          getNextPage={getNextPage}
          getPreviousPage={getPreviousPage}
          pageIndex={pageIndex}
          pageMaxLength={pageMaxLength!} // We validate it exist when showPagination is true above
          totalRecordsAmount={totalRecordsAmount}
        />
      )}
    </>
  );

  return (
    <div className={`${wrapperClassName} ${className}`}>
      {content}
    </div>
  );
};
