import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './VendorPicker.module.scss';

import { getIconByVendor } from 'components/AssetType/utils/getIconByVendor';
import { JitButton } from 'components/JitButton/JitButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import colors from 'themes/colors.module.scss';

interface VendorPickerCardProps {
  vendor: string;
  isSelected: boolean
  isDisabled: boolean;
  onCardClick: (vendor: string) => void;
}

export const VendorPickerCard: FC<VendorPickerCardProps> = ({
  vendor,
  isSelected,
  isDisabled,
  onCardClick,
}) => {
  const { INTEGRATIONS } = constants.routes;

  const navigate = useNavigate();
  let className = styles.card;
  if (isSelected) {
    className = styles.cardSelected;
  } else if (isDisabled) {
    className = styles.cardDisabled;
  }
  const onClick = () => {
    onCardClick(vendor);
  };
  const onClickConnect = () => {
    navigate(`/${INTEGRATIONS}`);
  };
  const icon = getIconByVendor(vendor, undefined, true);

  return (
    <div
      key={vendor}
      className={className}
      data-testid={`VendorPickerCard-${vendor}`}
      onClick={isDisabled ? undefined : onClick}
      role={isDisabled ? undefined : 'button'}
      tabIndex={isDisabled ? undefined : 0}
    >

      <div className={styles.iconAndVendor}>
        <JitIcon icon={icon} size={33} />

        <div className={styles.vendorNameWrapper}>
          <JitText bold text={`vendor.${vendor}`} />
        </div>
      </div>

      {isDisabled && (
      <JitButton disableRipple noHover onClick={onClickConnect}>
        <JitText color={colors.blue02} text='cards.buttons.connect' />
      </JitButton>
      )}

    </div>
  );
};
