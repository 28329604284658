import { useAuthUser, useTenantsState } from '@frontegg/react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';

import { Row } from './components/Row/Row';
import { TenantRow } from './components/TenantRow/TenantRow';
import { UserDetails } from './components/UserDetails/UserDetails';
import styles from './SelectTenantPopper.module.scss';

import { Check, Logout } from 'assets';
import { JitDivider } from 'components/JitDivider/JitDivider';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useTenantContext } from 'context/TenantContext';
import { useSwitchTenant } from 'context/TenantContext/tenantHooks/useSwitchTenant';
import { PERMISSIONS } from 'wrappers/RBAC/constants';

interface Props {
  shouldShowArrow?: boolean;
  handleLogout: () => void;
  shouldShowLogout: boolean;
}

// ts-prune-ignore-next
export const SelectTenantPopper: FC<Props> = ({ shouldShowArrow, handleLogout, shouldShowLogout }) => {
  const { setIsAddingNewOrganization } = useTenantContext();
  const frontEggUser = useAuthUser();
  const { tenants } = useTenantsState();
  const { useCreateOrganization } = useFlags();
  const { hasPermission } = useAuthContext();

  const sortedTenants = [...tenants];
  sortedTenants.sort((a, b) => a.name.localeCompare(b.name));

  const currentTenant = sortedTenants.find((tenant) => tenant.tenantId === frontEggUser.tenantId);

  const { handleSwitchTenant } = useSwitchTenant();

  const handleAddNewTenant = () => {
    setIsAddingNewOrganization(true);
  };

  return (
    <div className={styles.contentWrapper} data-testid='SelectTenantPopper'>
      <UserDetails tenant={currentTenant} />

      <JitDivider />

      <div className={styles.tenantsList} data-testid='tenantsList'>
        {sortedTenants.map(({ tenantId, name }, index) => (
          <TenantRow
            key={tenantId}
            endAdornment={frontEggUser.tenantId === tenantId ? (<JitIcon icon={Check} size={10} />) : undefined}
            isLast={!shouldShowLogout && index === sortedTenants.length - 1}
            onClick={() => handleSwitchTenant(tenantId)}
            shouldShowArrow={shouldShowArrow}
            tenantName={name}
          />
        ))}

        {useCreateOrganization && hasPermission(PERMISSIONS.WRITE_ORGANIZATIONS) && (
          <Row
            icon={(
              <AddCircleOutlineIcon />
            )}
            isLast={false}
            onSelect={handleAddNewTenant}
            shouldShowArrow={shouldShowArrow}
            text='navbar.menu.createOrganization.button'
          />
        )}
      </div>

      {shouldShowLogout && (
        <Row
          icon={<JitIcon icon={Logout} />}
          isLast
          onSelect={handleLogout}
          shouldShowArrow={shouldShowArrow}
          text='Logout'
        />
      )}

    </div>
  );
};
