import { ReactElement } from 'react';

import styles from '../common/TokenInput.module.scss';

import { JitText } from 'components/JitText/JitText';

interface CollapsibleStep {
  stepId: string;
  titleComponent: ReactElement;
  bodyComponent: ReactElement;
}

interface FormField {
  fieldText: string;
  valueText: string;
}

const getFormFields = (): FormField[] => [
  {
    fieldText: 'GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.tokenName',
    valueText: 'GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.tokenNameValue',
  },
  {
    fieldText: 'GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.expirationDate',
    valueText: 'GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.expirationDateValue',
  },
  {
    fieldText: 'GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.role',
    valueText: 'GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.roleValue',
  },
  {
    fieldText: 'GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.scope',
    valueText: 'GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.scopeValue',
  },
];

export const collapsibleSteps: CollapsibleStep[] = [
  {
    stepId: 'step-1',
    titleComponent: <JitText
      components={{
        addNewToken: <JitText bold display='inline' text='GitlabIntegrationWizard.maintainerToken.steps.pressAddNewToken.titleBold' />,
      }}
      muted
      text='GitlabIntegrationWizard.maintainerToken.steps.pressAddNewToken.title'
    />,
    bodyComponent: (
      <div className={styles.expandedContent}>
        <JitText muted text='GitlabIntegrationWizard.maintainerToken.steps.pressAddNewToken.body1' />

        <JitText muted text='GitlabIntegrationWizard.maintainerToken.steps.pressAddNewToken.body2' />
      </div>
    ),
  },
  {
    stepId: 'step-2',
    titleComponent: <JitText
      components={{
        formName: <JitText bold display='inline' text='GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.titleBold' />,
      }}
      muted
      text='GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.title'
    />,
    bodyComponent: (
      <div className={styles.expandedContent}>
        <div className={styles.wrapper} data-testid='formTable'>
          <JitText muted size='s' text='GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.guidelines' />

          <div className={styles.row}>
            <JitText horizontalAlign='start' muted size='m' text='GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.fieldColumnHeader' />

            <JitText horizontalAlign='start' muted size='m' text='GitlabIntegrationWizard.maintainerToken.steps.fillTheForm.valueColumnHeader' />
          </div>

          {getFormFields().map((field) => (
            <div key={field.fieldText} className={styles.row}>
              <JitText text={field.fieldText} />

              <JitText text={field.valueText} />
            </div>
          ))}
        </div>
      </div>
    ),
  },
  {
    stepId: 'step-3',
    titleComponent: <JitText
      components={{
        createGroupAccessToken: <JitText bold display='inline' text='GitlabIntegrationWizard.maintainerToken.steps.pressCreateToken.titleBold' />,
      }}
      muted
      text='GitlabIntegrationWizard.maintainerToken.steps.pressCreateToken.title'
    />,
    bodyComponent: <JitText className={styles.expandedContent} muted text='GitlabIntegrationWizard.maintainerToken.steps.pressCreateToken.body' />,
  },
];
