import { FC } from 'react';

import { DeleteWorkflow } from 'pages/WorkflowsPage/components/DeleteWorkflow/DeleteWorkflow';
import { ITableCell } from 'types/interfaces';

export const DeleteWorkflowCell: FC<ITableCell> = ({ cell: { value } }) => (
  <div style={{ maxHeight: 38 }}>
    <DeleteWorkflow
      isReadOnly={value.isReadOnly}
      onDeleteSuccess={() => {}}
      workflow={value.workflow}
    />
  </div>
);
