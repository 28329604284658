export const loginTitle = 'Empower developers to secure everything they code';

export const reviews = [
  {
    content:
      '"Jit provides continuous security by enabling my team to find and fix vulnerabilities in-PRs without slowing them down or expecting them to be security experts."',
    reviewer: {
      fullName: 'Jeff Haynie',
      title: 'CTO at ShopMonkey',
    },
    rating: 5,
  },
  {
    content:
      '"With Jit, we no longer need to understand and manage a lot of disparate tools. Getting it all in one console is a game changer."',
    reviewer: {
      fullName: 'Dudu Yosef',
      title: 'Director of Security at LinearB',
    },
    rating: 5,
  },
  {
    content:
      '“The Context Engine is amazing. What I enjoy most is that I can modify the factors that go into the priority and that I can easily see how a priority was calculated. '
      + 'We’ve used tools in the past that were black boxes. This feature is fantastic.”',
    reviewer: {
      fullName: 'Chris Zeeb',
      title: 'DevOps Manager at Formstack',
    },
    rating: 5,
  },
];
