import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { IGitlabInstallationVendorAttributes } from '../../../../types/interfaces';

import { GitlabStartScanningSection } from './GitlabStartScanningSection/GitlabStartScanningSection';
import styles from './SCMIntegrationTask.module.scss';

import { GithubIcon, GitlabIcon } from 'assets';
import { DisabledElement } from 'components/DisabledElement/DisabledElement';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { useTenantContext } from 'context/TenantContext';
import { constants } from 'globalConstants';
import { SCMVendors, Vendor } from 'types/enums';
import { useScmIntegration } from 'utils/hooks/useScmIntegration';

export const SCMIntegrationTask: FC = () => {
  const { currentScmVendor, isLoadingInstallation, githubInstallation, gitlabInstallation, initialOnboardingCompleted } = useTenantContext();
  const {
    ActiveSCMWizardComponent,
    setActiveSCMWizard,
    handleClose,
  } = useScmIntegration();
  const { uiShowGitlabIntegrationWidget } = useFlags();
  const navigate = useNavigate();
  const showGitlabWidget = useMemo(() => uiShowGitlabIntegrationWidget, [uiShowGitlabIntegrationWidget]);

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleSendAnalyticsEvent = useCallback((vendor: SCMVendors) => {
    sendAnalyticsEvent({
      action: 'click-scm-integration',
      params: {
        vendor,
      },
    });
  }, [sendAnalyticsEvent]);

  const handleClick = useCallback((vendor: SCMVendors) => {
    if (currentScmVendor && initialOnboardingCompleted) return;
    navigate(`/${constants.routes.QUICKSTART}`);
    // The reason for this navigate is that the default page will change as soon as SCM configuration is done, and we want to keep the user in this page.
    // The current routing solution doesn't allow a good solution to this, as the defaultRoute is determined by the first render and we currently don't
    // actually redirect the user to it, only render the component.
    setActiveSCMWizard(vendor);
    handleSendAnalyticsEvent(vendor);
  }, [currentScmVendor, handleSendAnalyticsEvent, initialOnboardingCompleted, navigate, setActiveSCMWizard]);

  const scmIntegrations = useMemo(() => ([{
    key: Vendor.Github,
    icon: GithubIcon,
    onClick: () => handleClick(Vendor.Github),
    isSelected: currentScmVendor === Vendor.Github,
    isDisabled: !!((currentScmVendor && currentScmVendor !== Vendor.Github && initialOnboardingCompleted) || gitlabInstallation),
  }, {
    key: Vendor.GITLAB,
    icon: GitlabIcon,
    onClick: () => handleClick(Vendor.GITLAB),
    shouldHide: !showGitlabWidget,
    isSelected: currentScmVendor === Vendor.GITLAB,
    isDisabled: !!((currentScmVendor && currentScmVendor !== Vendor.GITLAB && initialOnboardingCompleted) || githubInstallation),
  }]), [currentScmVendor, githubInstallation, gitlabInstallation, handleClick, initialOnboardingCompleted, showGitlabWidget]);

  const scmIntegrationsToShow = useMemo(() => scmIntegrations.filter(({ shouldHide }) => !shouldHide), [scmIntegrations]);

  const shouldShowStartScanning = useMemo(() => {
    if (!gitlabInstallation || !gitlabInstallation.vendor_attributes) return false;

    const vendorAttributes = gitlabInstallation.vendor_attributes as IGitlabInstallationVendorAttributes;
    return vendorAttributes.repo_selection !== undefined;
  }, [gitlabInstallation]);

  return (
    <div>
      <div className={styles.root} data-testid='scm-integration-task'>
        {scmIntegrationsToShow.map(({ key, icon, onClick, isDisabled, isSelected }) => (
          !isLoadingInstallation ? (
            <DisabledElement key={key} isDisabled={isDisabled}>
              <div className={isSelected ? styles.selectedIntegrationButton : styles.integrationButton} data-testid={`scm-integration-${key}`} onClick={onClick} role='button' tabIndex={0}>
                <JitIcon className={styles.icon} icon={icon} size={50} />
              </div>
            </DisabledElement>
          ) : (
            <LoadingSkeleton key={key} className={styles.skeleton} />
          )
        ))}

        {ActiveSCMWizardComponent && <ActiveSCMWizardComponent handleClose={handleClose} />}
      </div>

      { shouldShowStartScanning && (
        <GitlabStartScanningSection
          dataTestId='gitlab-start-scanning-section'
          initialOnboardingCompleted={initialOnboardingCompleted}
        />
      )}
    </div>
  );
};
