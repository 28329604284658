import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import colors from 'themes/colors.module.scss';

export const DefaultTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== 'focusColor',
})(() => ({
  '& label, & .MuiInputBase-input': {
    color: colors.gray,
  },
  '& label.Mui-focused': {
    color: colors.iris,
    borderWidth: '1px',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.iris,
    borderWidth: '1px',
  },
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: colors.iris,
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.iris,
      borderWidth: '1px',
    },
  },
}));
