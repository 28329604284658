import { FC, useEffect, useState, useCallback } from 'react';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { usePlanInstanceContext } from 'context/PlanInstanceContext/PlanInstanceContext';
import { useActivatePlanItem } from 'context/PlansContext/hooks/useActivatePlanItem';
import { usePlansContext } from 'context/PlansContext/PlansContext';
import { constants } from 'globalConstants';
import { EmptyStateTemplate } from 'pages/SbomPage/components/SbomTable/EmptyStateTemplate/EmptyStateTemplate';
import { logError } from 'services/logger/logger';
import { useSnackBar } from 'utils/hooks/useSnackBar';

export const ActivateSbom: FC = () => {
  const { activatePlanItem } = useActivatePlanItem();
  const { showSnackBar } = useSnackBar();
  const { plans, fetchPlanDetails, fetchPlans, hasFetchedPlans } = usePlansContext();
  const { hasPlanFetched, setInitialPlan } = usePlanInstanceContext();
  const [isActivating, setIsActivating] = useState<boolean>(false);

  const raiseErrorToast = useCallback(() => {
    showSnackBar({
      title: 'toasts.failedApiRequest.title',
      description: 'toasts.failedApiRequest.subtitle',
      type: 'error',
    });
  }, [showSnackBar]);

  useEffect(() => {
    if (!plans[constants.PLAN_SLUG.MVS]?.hasFetchedItems) {
      fetchPlanDetails(constants.PLAN_SLUG.MVS).then((hasCompleted) => {
        if (!hasCompleted) {
          logError('Could not fetch plan details for default plan');
          raiseErrorToast();
        }
      }).catch(() => {
        logError('Could not fetch plan details for default plan');
        raiseErrorToast();
      });
    }
  }, [fetchPlanDetails, fetchPlans, hasFetchedPlans, plans, raiseErrorToast]);

  useEffect(() => {
    if (!hasPlanFetched) {
      setInitialPlan();
    }
  }, [hasPlanFetched, setInitialPlan]);

  const planItemDetails = plans?.[constants.PLAN_SLUG.MVS]?.items?.[constants.PLAN_ITEMS_SLUG.SBOM];
  const onClick = () => {
    if (!planItemDetails) {
      raiseErrorToast();
      logError('Could not find plan item details for item-software-bill-of-materials');
      return;
    }
    activatePlanItem(constants.PLAN_ITEMS_SLUG.SBOM, planItemDetails);
    setIsActivating(true);
  };

  return (
    <EmptyStateTemplate
      buttonComponent={(
        <JitButton
          data-testid='activate-sbom-button'
          isLoading={isActivating}
          onClick={onClick}
          variant='contained'
        >
          <JitText text='pages.sbom.table.activateSbom.button' />
        </JitButton>
      )}
      description='pages.sbom.table.activateSbom.description'
      title='pages.sbom.table.activateSbom.title'
    />
  );
};
