import { useCallback } from 'react';

import { useConfigurationsContext } from '../ConfigurationsContext';

import { useHasConfigurationsChanged } from 'context/ConfigurationsContext/hooks/useHasConfigurationsChanged/useHasConfigurationsChanged';
import { usePlanService } from 'services/PlanService/usePlanService';
import { ShowSnackbarProps, useSnackBar } from 'utils/hooks/useSnackBar';

export const useCommitConfigurations = (snackBarOnSuccess?: ShowSnackbarProps) => {
  const { configurations, configurationsCommit, setConfigurationsCommit, setFetchedConfigurations, setIsCommittingConfigurations } = useConfigurationsContext();
  const { hasConfigurationsChanged } = useHasConfigurationsChanged();
  const { showSnackBar } = useSnackBar();
  const { updateConfigurations } = usePlanService();

  return useCallback(
    async () => {
      if (hasConfigurationsChanged()) {
        setIsCommittingConfigurations(true);
        await updateConfigurations(configurations, configurationsCommit).then(
          (response) => {
            if (response?.data?.content?.sha) {
              setConfigurationsCommit(response.data.content.sha);
            }
            setFetchedConfigurations(configurations);
            setIsCommittingConfigurations(false);

            const isSuccessfulResponse = response?.status && response.status <= 299;
            if (isSuccessfulResponse && snackBarOnSuccess) {
              showSnackBar(snackBarOnSuccess);
            }
          },
        ).catch(
          (error) => {
            if (error?.response?.status === 409) {
              showSnackBar({
                title: 'toasts.configurationConflict.title',
                description: 'toasts.configurationConflict.subtitle',
                type: 'error',
              });
              setIsCommittingConfigurations(false);
            }
          },
        );
      }
    },
    [configurations,
      configurationsCommit,
      hasConfigurationsChanged,
      setConfigurationsCommit,
      setFetchedConfigurations,
      setIsCommittingConfigurations,
      showSnackBar,
      snackBarOnSuccess,
      updateConfigurations,
    ],
  );
};
