/* eslint-disable @typescript-eslint/no-explicit-any */
import { AutocompleteRenderGetTagProps } from '@mui/material';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { Delete } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';

interface JitAutoCompleteTagsProps {
  values: any[];
  getTagProps: AutocompleteRenderGetTagProps;
  getOptionLabel?: (value: any) => string;
  maxDisplayedTags?: number;
}

const StyledChip = styled(Chip)(({ disabled }: { theme: any; disabled?: boolean }) => ({
  height: '20px',
  borderWidth: '1px',
  borderRadius: '4px',
  borderColor: colors.lightGray,
  opacity: `${disabled ? 0.7 : 1} !important`,
  maxWidth: '120px !important',
}));

export const JitAutoCompleteTags: FC<JitAutoCompleteTagsProps> = ({
  values,
  getTagProps,
  getOptionLabel,
  maxDisplayedTags,
}) => {
  const displayedTags = values.slice(0, maxDisplayedTags);
  const remainingCount = maxDisplayedTags ? values.length - maxDisplayedTags : 0;

  const getLabel = getOptionLabel || ((value) => String(value));
  const tooltipText = values.slice(maxDisplayedTags).map(getLabel).join(', ');
  const isDisabled = getTagProps({ index: 0 }).disabled;

  return (
    <>
      {displayedTags.map((value, index) => (
        <StyledChip
          clickable={false}
          data-testid='tag-chip'
          deleteIcon={<Delete />}
          label={(
            <TooltipOnlyOnOverflow tooltipText={getOptionLabel ? getOptionLabel(value) : value}>
              <JitText noWrap overflowHiddenEllipsis text={getOptionLabel ? getOptionLabel(value) : value} />
            </TooltipOnlyOnOverflow>
          )}
          size='small'
          variant='outlined'
          {...getTagProps({ index })}
          key={getOptionLabel ? getOptionLabel(value) : value}
        />
      ))}

      {remainingCount > 0 && (
        <JitTooltip followCursor={false} placement='top' title={tooltipText}>
          <StyledChip
            key='more-chip'
            clickable={false}
            data-testid='extra-tag-chip'
            disabled={isDisabled}
            label={`+${remainingCount}`}
            size='small'
            variant='outlined'
          />
        </JitTooltip>
      )}
    </>
  );
};
