import { useEffect, useState, RefObject } from 'react';

interface UseElementWidthOptions {
  initialWidth?: number;
}

export const useUpdateElementWidth = <T extends HTMLElement>(
  elementRef: RefObject<T>,
  options: UseElementWidthOptions = {},
) => {
  const [width, setWidth] = useState<number | null>(options.initialWidth ?? null);

  useEffect(() => {
    const updateWidth = () => {
      if (elementRef.current) {
        const { width: elementWidth } = elementRef.current.getBoundingClientRect();
        setWidth(elementWidth);
      }
    };

    updateWidth();

    const resizeObserver = new ResizeObserver(updateWidth);

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [elementRef]);

  return width;
};
