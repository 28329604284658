import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { EditWorkflowProvider } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowProvider';
import { EditWorkflowPage } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowPage';

export const EditWorkflowPageWrapper = () => (
  <PageLayout>
    <EditWorkflowProvider>
      <EditWorkflowPage />
    </EditWorkflowProvider>
  </PageLayout>
);
