import { CreateMaintainerToken } from '../StepComponents/CreateMaintainerToken/CreateMaintainerToken';

import { useSecretStep } from './useSecretStep';

interface Props {
  incrementStepIndex: () => void;
}

export const useCreateMaintainerTokenStep = ({ incrementStepIndex }: Props) => useSecretStep({
  incrementStepIndex,
  secretNameKey: 'GitlabIntegrationWizard.maintainerToken.tokenName',
  StepComponent: CreateMaintainerToken,
  titleKey: 'GitlabIntegrationWizard.maintainerToken.tabText',
});
