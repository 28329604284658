import { FC, useCallback } from 'react';

import styles from '../../Header.module.scss';

import { NotificationError } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTextInput } from 'components/JitTextInput/JitTextInput';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import colors from 'themes/colors.module.scss';

interface Props {
  nameAlreadyExists: boolean;
  resetNameAlreadyExists: () => void;
}

export const NameInput: FC<Props> = ({
  nameAlreadyExists,
  resetNameAlreadyExists,
}) => {
  const {
    workflow,
    setWorkflow,
  } = useEditWorkflowContext();

  const onWorkflowNameChange = useCallback((newName: string) => {
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      name: newName,
    }));
    resetNameAlreadyExists();
  }, [resetNameAlreadyExists, setWorkflow]);

  return (
    <div className={styles.nameInputWrapper}>
      <JitTextInput
        defaultValue={workflow?.name}
        fullWidth
        onChange={onWorkflowNameChange}
        textAlign='center'
        withBorder={false}
      />

      {nameAlreadyExists
        && (
          <JitTooltip followCursor={false} placement='bottom' title='Looks like this name is already taken.'>
            <div className={styles.iconWrapper}>
              <JitIcon
                centered
                color={colors.error}
                icon={NotificationError}
                withBackSquare={{
                  backgroundColor: colors.white,
                  height: 20,
                  width: 20,
                }}
              />
            </div>
          </JitTooltip>
        )}
    </div>
  );
};
