import { FC } from 'react';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { TeamDetailsPage } from 'pages/TeamsPage/SubPages/TeamDetailsPage/TeamDetailsPage';

export const TeamDetailsPageWrapper: FC = () => (
  <PageLayout>
    <TeamDetailsPage />
  </PageLayout>
);
