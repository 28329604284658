import { FC } from 'react';

import styles from './RunnerTypeSelection.module.scss';

import { Alert, GitlabIcon, Info } from 'assets';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

export enum RunnerType {
  SaaS = 'saas',
  SelfHosted = 'self_hosted',
}

interface Props {
  hasValidSaasRunners: boolean | undefined;
  onContinueNoSaasRunnersButtonClick: () => void;
  onDoneButtonClick: () => void;
  selectedOption: RunnerType | undefined;
  setSelectedOption: (selectedOption: RunnerType | undefined) => void;
  isLoadingRunners: boolean;
}

export const RunnerTypeSelection: FC<Props> = ({
  hasValidSaasRunners,
  onContinueNoSaasRunnersButtonClick,
  onDoneButtonClick,
  selectedOption,
  setSelectedOption,
  isLoadingRunners,
}) => {
  const runnerTypeToText = {
    [RunnerType.SaaS]: 'GitlabIntegrationWizard.runnerTypeSelection.saasTitle',
    [RunnerType.SelfHosted]: 'GitlabIntegrationWizard.runnerTypeSelection.selfHostedTitle',
  };

  const options = Object.values(RunnerType).map((runnerType) => ({
    itemKey: runnerType,
    itemName: runnerTypeToText[runnerType],
  }));

  const invalidSaasRunners = selectedOption === RunnerType.SaaS && hasValidSaasRunners !== undefined && !hasValidSaasRunners;
  const buttonLabel = invalidSaasRunners
    ? 'GitlabIntegrationWizard.runnerTypeSelection.invalidSaasRunner.continueButton'
    : 'GitlabIntegrationWizard.runnerTypeSelection.doneButton';
  const handleClick = invalidSaasRunners ? onContinueNoSaasRunnersButtonClick : onDoneButtonClick;

  return (
    <DialogContentWrapper
      actionButtons={[
        {
          label: buttonLabel,
          disabled: !selectedOption,
          handleClick,
          isLoading: isLoadingRunners,
        },
      ]}
      icon={GitlabIcon}
    >
      <div className={styles.content}>
        <div className={styles.titlesContent}>
          <JitText bold size='l' text='GitlabIntegrationWizard.runnerTypeSelection.title' />

          <JitText muted size='m' text='GitlabIntegrationWizard.runnerTypeSelection.subtitle' />
        </div>

        <div className={styles.dropdownContent}>
          <JitDropdownNew
            defaultDisplayText='GitlabIntegrationWizard.runnerTypeSelection.placeholder'
            displayText={selectedOption ? runnerTypeToText[selectedOption] : undefined}
            menuItems={options}
            onMenuItemClick={({ itemKey }) => {
              setSelectedOption(itemKey as RunnerType | undefined);
            }}
          />
        </div>

        {selectedOption === RunnerType.SelfHosted && (
          <JitInfoBanner
            bgColor={colors.cardContent}
            data-testid='self-hosted-runner-message'
            icon={Info}
            iconColor={colors.blue}
          >
            <div>
              <JitText
                bold
                className={styles.selfHostedRunnerInfoTitle}
                components={{
                  Guidelines: (
                    <JitExternalLink
                      href={i18n.t('GitlabIntegrationWizard.runnerTypeSelection.selfHostedRunnerInfo.guidelines.link')}
                      size='s'
                      text='GitlabIntegrationWizard.runnerTypeSelection.selfHostedRunnerInfo.guidelines.text'
                    />
                  ),
                }}
                size='s'
                text='GitlabIntegrationWizard.runnerTypeSelection.selfHostedRunnerInfo.title'
              />

              <JitText size='s' text='GitlabIntegrationWizard.runnerTypeSelection.selfHostedRunnerInfo.subtitle' />
            </div>
          </JitInfoBanner>
        )}

        {invalidSaasRunners && (
          <JitInfoBanner
            bgColor={colors.failRedBackground02}
            data-testid='no-saas-runner-message'
            icon={Alert}
            iconColor={colors.error}
          >
            <div>
              <JitText bold size='s' text='GitlabIntegrationWizard.runnerTypeSelection.invalidSaasRunner.info.title' />

              <JitText size='s' text='GitlabIntegrationWizard.runnerTypeSelection.invalidSaasRunner.info.subtitle' />
            </div>
          </JitInfoBanner>
        )}
      </div>
    </DialogContentWrapper>
  );
};
