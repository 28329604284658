// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FC, CSSProperties } from 'react';

import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface NewIndicatorProps {
  style?: CSSProperties; // Accept a style object for customization
}

export const NewIndicator: FC<NewIndicatorProps> = ({ style }) => {
  const defaultStyle: CSSProperties = {
    padding: '5px',
    borderRadius: '15px',
    backgroundColor: '#50a689',
  };

  return (
    <div style={{ ...defaultStyle,
      ...style }}
    >
      <JitText
        bold
        color={colors.cards}
        size='xxs'
        text='components.newIndicator.text'
      />
    </div>
  );
};
