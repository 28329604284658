import { FC } from 'react';
import { CellProps } from 'react-table';

import { IconsContainer } from './IconsContainer/IconsContainer';
import styles from './NameWithIconsCell.module.scss';

import { JitText } from 'components/JitText/JitText';
import { ITableCell } from 'types/interfaces';

interface DataTypes {
  vendors: string[];
  name: string;
  description: string;
}

export const NameWithIconsCell: FC<ITableCell> = ({ cell: { value } }: CellProps<object, DataTypes>) => {
  const iconsContainer = <IconsContainer vendors={value.vendors} />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconsWrapper}>
        {iconsContainer}
      </div>

      <div className={styles.textWrapper}>
        <JitText
          bold
          text={value.name}
        />

        <JitText
          muted
          noWrap
          overflowHiddenEllipsis
          size='s'
          text={value.description}
        />
      </div>
    </div>
  );
};
