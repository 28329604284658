import { useMemo } from 'react';

import { useValidateWorkflow } from './useValidateWorkflow';

import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';
import { PERMISSIONS } from 'wrappers/RBAC/constants';

export const useCanSave = (workflow: IWorkflow | undefined) => {
  const {
    hasWorkflowChanged,
    isNewWorkflow,
  } = useEditWorkflowContext();
  const { hasPermission } = useAuthContext();
  const isAllowedToSave = useMemo(() => hasPermission(PERMISSIONS.WRITE_WORKFLOWS), [hasPermission]);

  const missingPermissionsError = !isAllowedToSave ? 'readOnly.tooltip' : null;
  const invalidWorkflowReason = useValidateWorkflow(workflow);
  const workflowNotChangedReason = !isNewWorkflow && !hasWorkflowChanged ? 'pages.workflows.editWorkflowPage.header.cantSaveReasons.notChanged' : null;

  const cantSaveReason = missingPermissionsError || invalidWorkflowReason || workflowNotChangedReason;
  const canSave = !cantSaveReason;

  return {
    canSave,
    cantSaveReason,
  };
};
