import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

export const getStatusDetails = (status: string): { text: string; color: string } | null => {
  const statusMap: Record<string, { textKey: string; color: string }> = {
    failure: {
      textKey: 'common.statuses.failure',
      color: colors.statusRed,
    },
    success: {
      textKey: 'common.statuses.success',
      color: colors.successGreen02,
    },
    running: {
      textKey: 'common.statuses.running',
      color: colors.white,
    },
  };

  const statusDetails = statusMap[status] || null;

  if (!statusDetails) {
    return null;
  }

  const { textKey, color } = statusDetails;

  return {
    text: i18n.t(textKey),
    color,
  };
};
