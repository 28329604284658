import { FC } from 'react';

import styles from './PolicyHeader.module.scss';

import { Link } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { MUIIcon } from 'components/MUIIcon/MUIIcon';
import colors from 'themes/colors.module.scss';

interface Props {
  title: string;
  description: string;
  enabled: number;
  icon: string; // Icon name from @mui/icons-material
  docsLink?: string;
}

export const PolicyHeader: FC<Props> = ({ title, description, enabled, icon, docsLink }) => {
  const enabledColor = enabled > 0 ? colors.successMain : colors.lightGray;

  return (
    <div className={styles.root}>
      <div className={styles.iconContainer}>
        <MUIIcon color={colors.lightGray} iconName={icon} />

        <div className={styles.textContainer}>
          <JitText text={title} />

          <div className={styles.description}>
            <JitText muted size='s' text={description} />

            {docsLink && (
              <JitLink className={styles.docsLink} data-testid='docsLink' href={docsLink} target='_blank' underline='none'>
                <JitText color={colors.iris} size='s' text='pages.policies.policyItem.header.docsLink' />

                <JitIcon color={colors.iris} icon={Link} size={13} />
              </JitLink>
            )}
          </div>
        </div>
      </div>

      <JitText
        bold
        color={enabledColor}
        params={{ count: enabled }}
        size='xs'
        text='pages.policies.policyItem.header.enabled'
      />
    </div>
  );
};
