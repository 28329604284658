
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ChooseGitlabGroup } from '../StepComponents/ChooseGitlabGroup/ChooseGitlabGroup';

import { IGitlabIntegrationWizardStep, IGitlabIntegrationWizardStepStatus } from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { useTenantContext } from 'context/TenantContext';
import { IGroup, useGitlabService } from 'services/GitlabService/useGitlabService';
import { GitlabAccessLevel } from 'types/interfaces';

interface Props {
  incrementStepIndex: () => void;
  selectedGroup?: IGroup;
  setSelectedGroup: (group: IGroup) => void;
}

export const useChooseGitlabGroupStep = ({ incrementStepIndex, selectedGroup, setSelectedGroup }: Props): IGitlabIntegrationWizardStep => {
  const [isStepCompleted, setIsStepCompleted] = useState<boolean>(false);
  const { createInstallation } = useGitlabService();
  const [isInstallationLoading, setIsInstallationLoading] = useState(false);
  const { isGitlabIntegrated, gitlabInstallation } = useTenantContext();
  const { useGitlabOwnerRole } = useFlags();
  const accessLevel = useGitlabOwnerRole ? GitlabAccessLevel.OWNER : GitlabAccessLevel.MAINTAINER;

  useEffect(() => {
    if (gitlabInstallation && !selectedGroup) {
      setSelectedGroup({
        id: gitlabInstallation.installation_id,
        name: gitlabInstallation.owner,
        path: gitlabInstallation.owner,
        fullPath: gitlabInstallation.owner,
      });

      setIsStepCompleted(true);
    }
  }, [isGitlabIntegrated, gitlabInstallation, selectedGroup, setSelectedGroup]);

  const status = useMemo(() => {
    if (isStepCompleted) return IGitlabIntegrationWizardStepStatus.COMPLETE;

    return IGitlabIntegrationWizardStepStatus.ACTIVE;
  }, [isStepCompleted]);

  const isGroupSelected = useMemo(() => !!selectedGroup, [selectedGroup]);

  const onContinueButtonClick = useCallback(async () => {
    if (isGitlabIntegrated) {
      incrementStepIndex();
      return;
    }

    if (!selectedGroup?.id || !selectedGroup?.name) return;

    setIsInstallationLoading(true);
    const response = await createInstallation({
      group_id: selectedGroup.id,
      group_name: selectedGroup.name,
      group_slug: selectedGroup.path,
      access_level: accessLevel,
    });

    if (response?.status === 201 || response?.status === 409) {
      setIsStepCompleted(true);
      incrementStepIndex();
    }

    setIsInstallationLoading(false);
  }, [accessLevel, isGitlabIntegrated, selectedGroup?.id, selectedGroup?.name, selectedGroup?.path, createInstallation, incrementStepIndex]);

  const chooseGitlabGroupStep = useMemo(() => ({
    title: 'GitlabIntegrationWizard.chooseGitlabGroup.tabText',
    component: () => (
      <ChooseGitlabGroup
        isGroupSelected={isGroupSelected}
        isInstallationLoading={isInstallationLoading}
        minAccessLevel={accessLevel}
        onContinueButtonClick={onContinueButtonClick}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
      />
    ),
    status,
  }), [accessLevel, status, isGroupSelected, isInstallationLoading, onContinueButtonClick, selectedGroup, setSelectedGroup]);

  return chooseGitlabGroupStep;
};
