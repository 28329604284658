/* eslint-disable react/jsx-max-depth */
import { parse, stringify } from 'query-string';
import { FC, PropsWithChildren } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { AnalyticsProvider } from 'context/AnalyticsContext/AnalyticsContext';
import { AssetsProvider } from 'context/AssetsContext/AssetsProvider';
import { ConfigurationsProvider } from 'context/ConfigurationsContext/ConfigurationsContext';
import { DialogsProvider } from 'context/DialogsContext/DialogsContext';
import { FilesProvider } from 'context/FilesContext';
import { GithubProvider } from 'context/GithubContext/GithubProvider';
import { IntegrationsProvider } from 'context/IntegrationsContext';
import { NewPipelinesProvider } from 'context/PipelinesContext/PipelinesContextNew';
import { PlanInstanceProvider } from 'context/PlanInstanceContext';
import { PlansProvider } from 'context/PlansContext/PlansProvider';
import { PlanTemplatesProvider } from 'context/PlanTemplatesContext';
import { PullRequestsProvider } from 'context/PullRequestsContext/PullRequestsProvider';
import { ReferralProvider } from 'context/ReferralContext/ReferralContext';
import { SbomProvider } from 'context/SbomContext/SbomProvider';
import { SecretsProvider } from 'context/SecretsContext';
import { SlackProvider } from 'context/SlackContext';
import { TeamsProvider } from 'context/TeamsContext/TeamsProvider';
import { TenantProvider } from 'context/TenantContext/TenantProvider';
import { ToastsProvider } from 'context/ToastsContext/ToastsContext';
import { WebSocketProvider } from 'context/WebSocketContext';

export const ProtectedContextProviders: FC<PropsWithChildren> = ({ children }) => (
  <QueryParamProvider
    adapter={ReactRouter6Adapter}
    options={{
      searchStringToObject: parse,
      objectToSearchString: stringify,
    }}
  >
    <AnalyticsProvider>
      <WebSocketProvider>
        <ReferralProvider>
          <ToastsProvider>
            <TenantProvider>
              <GithubProvider>
                <DialogsProvider>
                  <AssetsProvider>
                    <PlanTemplatesProvider>
                      <PlansProvider>
                        <PlanInstanceProvider>
                          <NewPipelinesProvider>
                            <ConfigurationsProvider>
                              <PullRequestsProvider>
                                <SlackProvider>
                                  <FilesProvider>
                                    <IntegrationsProvider>
                                      <SecretsProvider>
                                        <TeamsProvider>
                                          <SbomProvider>
                                            {children}
                                          </SbomProvider>
                                        </TeamsProvider>
                                      </SecretsProvider>
                                    </IntegrationsProvider>
                                  </FilesProvider>
                                </SlackProvider>
                              </PullRequestsProvider>
                            </ConfigurationsProvider>
                          </NewPipelinesProvider>
                        </PlanInstanceProvider>
                      </PlansProvider>
                    </PlanTemplatesProvider>
                  </AssetsProvider>
                </DialogsProvider>
              </GithubProvider>
            </TenantProvider>
          </ToastsProvider>
        </ReferralProvider>
      </WebSocketProvider>
    </AnalyticsProvider>
  </QueryParamProvider>
);
