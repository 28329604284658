import { FC, ReactElement, ReactNode } from 'react';

import styles from './EmptyPage.module.scss';

import { Background } from 'components/Pages/Background/Background';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';

interface IEmptyWorkflowPageProps {
  header?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  bgImage?: ReactElement;
}

export const EmptyPage: FC<IEmptyWorkflowPageProps> = ({
  header,
  content,
  footer,
  bgImage,
}) => (
  <PageLayout>
    <div className={styles.root} data-testid='empty-page'>
      <div className={styles.backgroundWrapper}>
        <Background />

        {bgImage && <div className={styles.bgImage}>{bgImage}</div>}

      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.pageHeader}>{header}</div>

        <div className={styles.pageContent}>{content}</div>

        <div className={styles.pageFooter}>{footer}</div>
      </div>
    </div>
  </PageLayout>
);
