/* eslint-disable react/no-array-index-key */
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isArray } from 'lodash';
import { FC } from 'react';

import { Header } from '../../AssetManagementTable';

import styles from './AssetsTableRow.module.scss';
import { AssetsTableRowCheckBox } from './AssetsTableRowCheckBox';

import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { useGetScanButton } from 'components/JitDialogs/AssetsManagementDialog/hooks';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTag } from 'components/JitTag/JitTag';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { TooltipOnlyOnOverflow } from 'components/TooltipOnlyOnOverflow/TooltipOnlyOnOverflow';
import colors from 'themes/colors.module.scss';
import { AssetStatus } from 'types/enums';
import { IAsset } from 'types/interfaces';
import { iconsMap } from 'utils';

interface Props {
  asset: IAsset;
  isDisabled: boolean;
  checkAsset: (assetId: string, checked: boolean) => void;
  headers: Header[];
}

const getStatusDetails = (asset: IAsset): { statusColor: string, status: string } => {
  if (asset.status === AssetStatus.FAILED) {
    return {
      statusColor: colors.failRed,
      status: 'Error',
    };
  }
  if (!asset.is_covered) {
    return {
      statusColor: colors.lightGray,
      status: 'Not covered',
    };
  }
  return {
    statusColor: colors.statusGreen02,
    status: 'Covered',
  };
};

export const AssetTableRow: FC<Props> = ({
  asset,
  isDisabled,
  checkAsset,
  headers,
}) => {
  const { uiEnableManualAssetScan } = useFlags();

  const scanButton = useGetScanButton({
    plan_items: asset.plan_items || [],
    assetId: asset.asset_id,
  });

  const shouldRenderScanButton = uiEnableManualAssetScan && asset.plan_items && asset.plan_items.length > 0;

  return (
    <div className={styles.itemContainer} data-testid={`AssetTableRow - ${asset.asset_name}`}>
      {headers.map((header, index) => {
        const content = asset[header.key as keyof typeof asset] as string | undefined;
        if (index === 0) {
          return (
            <div key={`assetManagement-${asset.asset_id}-${index}`} className={styles.cell}>
              <AssetsTableRowCheckBox
                asset={asset}
                checkAsset={checkAsset}
                isDisabled={isDisabled}
              />

              <TooltipOnlyOnOverflow tooltipText={content}>
                <div className={styles.archiveLabelWrapper}>
                  <JitText color={colors.lightGray} horizontalAlign='center' noWrap overflowHiddenEllipsis text={content} />

                  {asset.is_archived ? (<JitTag color={colors.archivedLabel} text='dialogs.assetsManagement.archived' />) : null}
                </div>
              </TooltipOnlyOnOverflow>
            </div>
          );
        }
        if (isArray(content)) {
          const displayContent = content.join(', ');
          return (
            <JitTooltip
              key={`assetManagement-${asset.asset_id}-${index}`}
              childrenWrapperClassName={styles.cell}
              followCursor
              placement='right'
              title={displayContent}
            >

              <JitText color={colors.lightGray} noWrap overflowHiddenEllipsis size='m' text={displayContent} />
            </JitTooltip>
          );
        }
        if (header.key === 'status') {
          const { statusColor, status } = getStatusDetails(asset);
          return (
            <div key={`assetManagement-${asset.asset_id}-${index}`} className={styles.statusCell}>
              {asset.status === AssetStatus.FAILED && !!asset.status_details && (
              <div key={`assetManagement-${asset.asset_id}-${index}`} className={styles.statusDetailsContainer} data-testid='assetDetailsToolTip'>
                <JitTooltip className={styles.statusDetailsTooltip} followCursor={false} placement='top' title={asset.status_details}>
                  <JitIcon size={21} {...iconsMap.configuration} />
                </JitTooltip>
              </div>
              )}

              <TooltipOnlyOnOverflow tooltipText={status}>
                <JitText
                  color={statusColor}
                  noWrap
                  overflowHiddenEllipsis
                  text={status}
                />
              </TooltipOnlyOnOverflow>
            </div>
          );
        }
        if (header.key === 'actions' && shouldRenderScanButton) {
          return (
            <div key={`assetManagement-${asset.asset_id}-${index}`} className={styles.cell}>
              <JitActionButton actionButton={scanButton} />
            </div>
          );
        }
        return (
          <div key={`assetManagement-${asset.asset_id}-${index}`} className={styles.cell}>
            <TooltipOnlyOnOverflow tooltipText={content}>
              <JitText color={colors.lightGray} noWrap overflowHiddenEllipsis size='m' text={content} />
            </TooltipOnlyOnOverflow>
          </div>
        );
      })}
    </div>
  );
};
