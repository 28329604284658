interface ConnectWebhooksCurlCommandProps {
  tenantId: string;
  installationId: string;
}

export const connectWebhooksCurlCommand = ({ tenantId, installationId }: ConnectWebhooksCurlCommandProps) => (
  `# Replace <glpat-xxx> with your GitLab personal access token
# Replace <webhook_secret_token> with any string you want to use as the webhook secret token
curl --request POST \\
--header "PRIVATE-TOKEN: <glpat-xxx>" \\
--header "Content-Type: application/json" \\
--url "https://gitlab.com/api/v4/groups/${installationId}/hooks" \\
--data '{
  "token": "<webhook_secret_token>",
  "url": "https://api.jit.io/gitlab/webhook",
  "name": "Jit Security",
  "custom_headers": [
    { "key": "Tenant-Id", "value": "${tenantId}" },
    { "key": "Installation-Id", "value": "${installationId}" }
  ],
  "push_events": true,
  "note_events": true,
  "subgroup_events": true,
  "merge_requests_events": true,
  "deployment_events": true
}'`
);
