import colors from 'themes/colors.module.scss';

// reference for customizing the plan card: https://docs.stigg.io/docs/pricing-table#custom-css
export const customCss = `
  .stigg-plan-offering-container {
    background-color: #1A1E26;
    border-radius: 8px;
    border: none;
  }
  .stigg-current-plan {
    background-color: ${colors.cardContent};
  }
  .stigg-plan-header {
    font-weight: 700;
  }
  .stigg-paywall-plan-button-text {
    color: ${colors.premiumPink};
  }
  .stigg-plan-entitlements-title {
    text-align: start;
  }
  .stigg-paywall-plan-button {
    background-color: inherit;
    border-radius: 4px;
    border: 1px solid ${colors.premiumPink};
    text-transform: uppercase;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.24px;
    color: ${colors.jitPink} !important;
  }
  .stigg-paywall-plan-button:hover {
    background-color: inherit;
  }

  .stigg-plan-jit-free .stigg-paywall-plan-button {
    background-color: transparent;
    border: 1px solid ${colors.gray};
    scale: 1;
    cursor: default;
  }
  
  .stigg-watermark svg {
    display: none !important;
  }

  .stigg-entitlement-name {
    text-align: start;
  }
  .stigg-paywall-plans-layout {
    height: 70vh;
  }
  .stigg-price-unit-and-billing-period-text {
    color: ${colors.white};
    text-align: start;
  }
  .stigg-price-point {
    color: ${colors.white};
    text-align: start;
  }
  .stigg-plan-offering-container:hover {
    box-shadow: 0 0 0 1px ${colors.premiumPink};
  }
  .stigg-current-plan {
    background-color: #1A1E26;
  }
  .stigg-paywall-plan-button:disabled {
    background-color: transparent;
    border: 1px solid ${colors.gray};
    scale: 1;
  }
  .stigg-paywall-plan-button:disabled:hover {
    background-color: transparent;
  }
  .stigg-plan-header-divider {
    // in order to make the divider invisible
    background-color: transparent;
  }

  // for making sure the plan cards are rendering in the correct order
  .stigg-plan-jit-free {
    order: 1;
  }
  .stigg-plan-jit-growth {
    order: 2;
  }
  .stigg-plan-jit-enterprise {
    order: 3;
  }
  
  .stigg-subscription-plan-name {
    text-align: start;
  }

  .stigg-checkout-layout {
    width: 90vw !important;
    overflow-y: auto;
    border: 0px;
    text-align: start;
  }
  
  .stigg-checkout-layout [class$="SummaryContainer"] {
    max-width: 470px;
  }
`;
