import { FC, useEffect } from 'react';

import { ReviewCard, Carousel } from './components';
import { loginTitle, reviews } from './constants';
import styles from './LoginPage.module.scss';

import { JitFavicon, LoginJitTeams, LoginLogos, LoginBg } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { useNotAuthenticatedAnalyticsClient } from 'context/AnalyticsContext/hooks/useNotAuthenticatedAnalyticsClient';
import colors from 'themes/colors.module.scss';
import { Interval } from 'types/types';
import { setIntervalImmediately } from 'utils';

let hideElementsInterval: Interval;
let descriptionUpdateInterval: Interval;

const getFronteggContainer = () => {
  const container = document.querySelector('#frontegg-login-box-container-default');
  return container?.shadowRoot || null;
};

const hidePoweredByFronteggAndSignUp = () => {
  hideElementsInterval = setIntervalImmediately(() => {
    const container = getFronteggContainer();
    const poweredByFrontegg: HTMLElement | undefined = container?.querySelector('[data-test-id="powered-by-logo"]') || undefined;
    const signUpElement: HTMLElement | undefined = container?.querySelector('h6') || undefined;

    if (poweredByFrontegg && signUpElement) {
      signUpElement.style.display = 'none';
      poweredByFrontegg.style.display = 'none';
      clearInterval(hideElementsInterval);
    }
  }, 10, 100);
};

const updateDescriptionText = () => {
  const container = getFronteggContainer();
  const descriptionElement = container?.querySelector('[data-test-id="social-login-failure-description"]') as HTMLElement;

  if (descriptionElement) {
    descriptionElement.innerHTML = `It looks like you’re signing up with a personal email.
    For the best Jit experience, we recommend using your work email for:
  • Seamless company integration
  • Team collaboration features
  • Enhanced security
No work email? No problem - let’s chat about how Jit can help you! <a href="mailto:contact@jit.io">contact@jit.io</a>`;
  }
};

const updateDescriptionTextPeriodically = (): void => {
  descriptionUpdateInterval = setInterval(() => {
    const container = getFronteggContainer();
    const descriptionElement = container?.querySelector('[data-test-id="social-login-failure-description"]') as HTMLElement;

    if (descriptionElement) {
      updateDescriptionText();
      clearInterval(descriptionUpdateInterval);
    }
  }, 10);
};

export const LoginPage: FC = () => {
  const { sendPageViewedEventNotAuthenticated } = useNotAuthenticatedAnalyticsClient();

  useEffect(() => {
    hidePoweredByFronteggAndSignUp();
    updateDescriptionTextPeriodically();

    return () => {
      clearInterval(hideElementsInterval);
      clearInterval(descriptionUpdateInterval);
    };
  }, []);

  useEffect(() => {
    sendPageViewedEventNotAuthenticated('login', '/login');
  }, [sendPageViewedEventNotAuthenticated]);

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${LoginBg})` }}>
      <div className={styles.wrapper}>
        <div className={styles.favicon}>
          <JitFavicon
            fill={colors.jitPink}
          />
        </div>

        <JitText bold className='ReviewCard_Header' size='1vw' text={loginTitle} />

        <div className={styles.content}>
          <div className={styles.carouselContainer}>
            <Carousel
              ChildComponent={ReviewCard}
              options={reviews}
            />
          </div>

          <img
            alt='JitTeams Icon'
            className={styles.icon}
            src={LoginJitTeams}
          />
        </div>

      </div>

      <img alt='Logos Icon' src={LoginLogos} />
    </div>
  );
};
