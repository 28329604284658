import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { constants } from 'globalConstants';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { useWorkflowsService } from 'services/WorkflowsService/useWorkflowsService';
import { Queries } from 'types/enums/Queries';

interface Props {
  isNewWorkflow: boolean;
}

export const useSaveWorkflow = ({ isNewWorkflow }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { WORKFLOWS } = constants.routes;

  const {
    workflow,
    setWorkflow,
  } = useEditWorkflowContext();
  const {
    createWorkflow,
    updateWorkflow,
  } = useWorkflowsService();

  return useMutation(
    async () => {
      if (!workflow) throw new Error('Workflow is undefined'); // Ensures workflow exists

      if (isNewWorkflow) {
        return createWorkflow(workflow);
      }
      return updateWorkflow(workflow);
    },
    {
      onSuccess: (response) => {
        if (!response.data) return;
        setWorkflow(response.data);
        queryClient.setQueryData([Queries.Workflow, response.data.id], response.data);

        if (isNewWorkflow) navigate(`/${WORKFLOWS}/${response.data.id}`);
      },
    },
  );
};
