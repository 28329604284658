import { useCallback } from 'react';

import { IRuleTemplate, RuleSettings, DataType, Condition } from 'pages/PoliciesPage/types';
import { getDependentFields } from 'pages/PoliciesPage/utils';

export const useManageSettings = ({
  settings,
  ruleTemplateInputs,
  onSettingsChange,
}: {
  settings: RuleSettings;
  ruleTemplateInputs: IRuleTemplate['inputs'];
  onSettingsChange: (updatedSettings: RuleSettings) => void;
}) => {
  const addDependentFields = useCallback((
    settingsToUpdate: RuleSettings,
    changedField: string,
    conditionIndex?: number,
  ): RuleSettings => {
    const updatedSettings = { ...settingsToUpdate };

    const currentSettings = conditionIndex !== undefined ? updatedSettings.conditions?.[conditionIndex] : updatedSettings;
    const fieldsToAdd = getDependentFields(changedField, ruleTemplateInputs, currentSettings);

    if (conditionIndex === undefined) {
      fieldsToAdd.forEach((field) => {
        if (!(field in updatedSettings)) {
          updatedSettings[field] = '';
        }
      });
    } else if (updatedSettings.conditions?.[conditionIndex]) {
      const updatedCondition = { ...updatedSettings.conditions[conditionIndex] };
      fieldsToAdd.forEach((field) => {
        if (!(field in updatedCondition)) {
          (updatedCondition[field as keyof typeof updatedCondition] as string) = '';
        }
      });
      updatedSettings.conditions = [
        ...updatedSettings.conditions.slice(0, conditionIndex),
        updatedCondition,
        ...updatedSettings.conditions.slice(conditionIndex + 1),
      ];
    }

    return updatedSettings;
  }, [ruleTemplateInputs]);

  const resetDependentFields = useCallback((
    settingsToUpdate: RuleSettings,
    changedField: string,
    conditionIndex?: number,
  ): RuleSettings => {
    const updatedSettings = { ...settingsToUpdate };

    const currentSettings = conditionIndex !== undefined ? updatedSettings.conditions?.[conditionIndex] : updatedSettings;
    const fieldsToReset = getDependentFields(changedField, ruleTemplateInputs, currentSettings);

    if (conditionIndex === undefined) {
      fieldsToReset.forEach((field) => {
        if (field in updatedSettings) {
          delete updatedSettings[field];
        }
      });
    } else if (updatedSettings.conditions?.[conditionIndex]) {
      const updatedCondition = { ...updatedSettings.conditions[conditionIndex] };
      fieldsToReset.forEach((field) => {
        if (field in updatedCondition && changedField !== 'conditionOperator') {
          delete updatedCondition[field as keyof typeof updatedCondition];
        }
      });
      updatedSettings.conditions = [
        ...updatedSettings.conditions.slice(0, conditionIndex),
        updatedCondition,
        ...updatedSettings.conditions.slice(conditionIndex + 1),
      ];
    }

    return updatedSettings;
  }, [ruleTemplateInputs]);

  const onFieldChange = useCallback((field: string, value: DataType | Condition[], conditionIndex?: number) => {
    const updatedSettings = { ...settings };

    if (conditionIndex !== undefined && updatedSettings.conditions) {
      const updatedConditions = [...updatedSettings.conditions];
      updatedConditions[conditionIndex] = {
        ...updatedConditions[conditionIndex],
        [field]: value,
      };
      updatedSettings.conditions = updatedConditions;
    } else {
      updatedSettings[field] = value;
    }

    let finalSettings = resetDependentFields(updatedSettings, field, conditionIndex);
    finalSettings = addDependentFields(finalSettings, field, conditionIndex);
    return onSettingsChange(finalSettings);
  }, [addDependentFields, onSettingsChange, resetDependentFields, settings]);

  const handleAddCondition = useCallback(() => {
    const newCondition: Condition = {
      conditionEntity: '',
      conditionValue: '',
    };
    if (!settings.conditions) {
      onFieldChange('conditions', [newCondition]);
    } else {
      onFieldChange('conditions', [...settings.conditions, newCondition]);
    }
  }, [settings, onFieldChange]);

  const handleRemoveCondition = useCallback((index: number) => {
    const newConditions = settings.conditions?.filter((_, i) => i !== index);
    onFieldChange('conditions', newConditions);
  }, [settings, onFieldChange]);

  return {
    onFieldChange,
    handleAddCondition,
    handleRemoveCondition,
  };
};
