import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { useCurrentTeam } from '../hooks/useCurrentTeam/useCurrentTeam';

import { ResourcePageContent } from './components/ResourcePageContent/ResourcePageContent';
import styles from './ResourcePage.module.scss';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';

const getCurrentResourceIdFromPath = (pathname: string): string => {
  const pathParts = pathname.split('/');
  return pathParts[pathParts.length - 1];
};

export const ResourcePage: FC = () => {
  const { specificTeam } = useCurrentTeam();
  const { pathname } = useLocation();
  const selectedAssetId = getCurrentResourceIdFromPath(pathname);
  const relevantAsset = specificTeam.resources.data.find((asset) => asset.asset_id === selectedAssetId);
  return (
    <PageLayout>
      <div className={styles.pageWrapper} data-testid='resource-page'>
        {relevantAsset ? (
          <ResourcePageContent relevantAsset={relevantAsset} />
        ) : (
          <LoadingBar />
        )}
      </div>
    </PageLayout>
  );
};
