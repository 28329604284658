import { Checkout, OnCheckoutCompletedParams } from '@stigg/react-sdk';
import { FC, useEffect, useState } from 'react';

import { ApprovalText } from './ApprovalText';
import { BillingText } from './BillingText';

import { useSendAnalyticsEvent } from 'context/AnalyticsContext';

interface StiggCheckoutProps {
  planId: string;
  onCheckoutCompleted: ({ success }: { success: boolean }) => void;
}

export const StiggCheckout: FC<StiggCheckoutProps> = ({ planId, onCheckoutCompleted }) => {
  const [checkoutStep, setCheckoutStep] = useState(0);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  useEffect(() => {
    sendAnalyticsEvent({
      action: 'checkout-started',
      params: {
        plan_id: planId,
      },
    });
  }, [planId, sendAnalyticsEvent]);

  const handleCheckoutCompleted = async (params: OnCheckoutCompletedParams) => {
    sendAnalyticsEvent({
      action: 'checkout-completed',
      params: {
        success: params.success.toString(),
        plan_id: planId,
      },
    });
    onCheckoutCompleted(params);
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    // eslint-disable-next-line consistent-return
    const checkElements = () => {
      const subscribeButton = document.querySelector('.stigg-checkout-summary-cta-button-text');
      const watermark = document.querySelector('.stigg-watermark');
      const checkoutContent = document.querySelector('[class*="CheckoutContent"]');

      if (subscribeButton && watermark && checkoutContent) {
        setCheckoutStep((prev) => prev + 1);
        clearInterval(intervalId);

        const observer = new MutationObserver(() => {
          const nextButton = document.querySelector('[class*="NextButton"]');
          if (nextButton) {
            setCheckoutStep((prev) => prev + 1);
          }
        });

        const targetNode = document.querySelector('.stigg-checkout-layout');
        if (targetNode) {
          observer.observe(targetNode, {
            subtree: true,
            childList: true,
            attributes: true,
          });
        }

        return () => observer.disconnect();
      }
    };

    intervalId = setInterval(checkElements, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Checkout onCheckoutCompleted={handleCheckoutCompleted} planId={planId} />

      <ApprovalText checkoutStep={checkoutStep} />

      <BillingText checkoutStep={checkoutStep} />
    </>
  );
};
