import { FC, useEffect } from 'react';

import { CoverageOffering } from './components/CoverageOffering/CoverageOffering';
import { TheLoop } from './components/TheLoop/TheLoop';
import { securityPlansInit } from './hooks/constants';
import { useLoopSegments } from './hooks/useLoopSegments';
import { useSecurityMapping } from './hooks/useSecurityMapping';
import styles from './SecurityMappingPage.module.scss';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { PageTitles } from 'components/PageTitles/PageTitles';

export const SecurityMappingPage: FC = () => {
  const { securityPlans, toggleSecurityPlanSelection, linkToPlan } = useSecurityMapping(securityPlansInit);
  const { segments, updateSegments } = useLoopSegments(securityPlans);

  useEffect(() => {
    updateSegments();
  }, [securityPlans, updateSegments]);

  return (
    <PageLayout>
      <div className={styles.wrapper}>
        <div className={styles.leftPanel}>
          <PageTitles title='pages.securityMapping.title' />

          <CoverageOffering
            handlePlanClick={toggleSecurityPlanSelection}
            handlePlanLinkClick={linkToPlan}
            securityPlans={securityPlans}
          />
        </div>

        <div className={`${styles.rightPanel} ${styles.dots}`}>
          <TheLoop segments={segments} />
        </div>
      </div>
    </PageLayout>
  );
};
