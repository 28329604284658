import { useEffect, useState } from 'react';

export const useCheckoutTextObserver = (
  containerSelector: string,
  containerClassName: string,
  insertAfterSelector: string,
  watchDeps: unknown[] = [],
) => {
  const [buttonText, setButtonText] = useState('');
  const [container, setContainer] = useState<Element | null>(null);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line max-statements, consistent-return
    const timeoutId = setTimeout(() => {
      const updateButtonText = () => {
        const buttonTextElement = document.querySelector('.stigg-checkout-summary-cta-button-text');
        const text = buttonTextElement?.textContent || '';

        if (text.includes('Subscribe') || text.includes('Update subscription')) {
          setButtonText(text);
          setShouldShow(true);
        } else {
          setButtonText('');
          setShouldShow(false);
        }
      };

      const insertAfterEl = document.querySelector(insertAfterSelector);
      if (insertAfterEl) {
        let localContainer = document.querySelector(containerSelector);
        if (!localContainer) {
          localContainer = document.createElement('div');
          localContainer.className = containerClassName;
          if (containerClassName === 'billing-text-container') {
            const nextElement = insertAfterEl.nextElementSibling;
            if (nextElement) {
              nextElement.insertAdjacentElement('afterend', localContainer);
            } else {
              insertAfterEl.insertAdjacentElement('afterend', localContainer);
            }
          } else {
            insertAfterEl.insertAdjacentElement('afterend', localContainer);
          }
        }
        setContainer(localContainer);

        updateButtonText();

        const observer = new MutationObserver(updateButtonText);
        const targetNode = document.querySelector('.stigg-checkout-layout');
        if (targetNode) {
          observer.observe(targetNode, {
            subtree: true,
            childList: true,
            characterData: true,
          });
        }
        return () => {
          observer.disconnect();
        };
      }
    }, 2000); // 2 seconds delay

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, watchDeps);

  return { container,
    buttonText,
    shouldShow };
};
