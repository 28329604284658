import { useTenantsState } from '@frontegg/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TenantRow } from '../SelectTenantPopper/components/TenantRow/TenantRow';
import { SelectTenantPopper } from '../SelectTenantPopper/SelectTenantPopper';

import styles from './NavbarHeader.module.scss';

import { Bell, ChevronExpand, Docs, JitFavicon } from 'assets';
import { IconButton } from 'components/IconButton/IconButton';
import { JitDivider } from 'components/JitDivider/JitDivider';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitPopper } from 'components/JitPopper/JitPopper';
import { JitUpgradeButton } from 'components/JitUpgradeButton/JitUpgradeButton';
import { config } from 'config';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { useGetCustomerTrialDetails } from 'context/StiggContext/useGetCustomerTrialDetails';
import colors from 'themes/colors.module.scss';

interface Props {
  isCollapsed: boolean;
}

export const NavbarHeader: FC<Props> = ({ isCollapsed }) => {
  const { t } = useTranslation();
  const [isPopperOpened, setIsPopperOpened] = useState(false);

  const { frontEggUser, handleLogout } = useAuthContext();

  const { useCreateOrganization } = useFlags();

  const { tenants } = useTenantsState();
  const tenantName = tenants.find((tenant) => tenant.tenantId === frontEggUser.tenantId)?.name || '';
  const { daysLeft, isOnTrial, hasPaidSubscription } = useGetCustomerTrialDetails(true);
  const upgradeBtnText = isOnTrial && daysLeft ? t('common.pricingPlan.navbar.daysLeft', { daysLeft }) : t('common.pricingPlan.navbar.upgrade');

  useEffect(() => {
    if (isCollapsed) {
      setIsPopperOpened(false);
    }
  }, [isCollapsed]);

  const shouldHandleHover = useCreateOrganization || tenants.length > 1;
  const endAdornment = useMemo(() => (shouldHandleHover
    ? <JitIcon icon={ChevronExpand} iconClassName={styles.chevronIcon} size={15} />
    : undefined), [shouldHandleHover]);

  const onDocsClick = useCallback(() => {
    window.open(config.docsUrl, '_blank');
  }, []);

  const onBeamerClick = useCallback(() => {
    // @ts-ignore We should always have the global Beamer instance (imported from the index.html)
    window.Beamer.show();
  }, []);

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.headerButtons}>
        <Link to='/'>
          <JitIcon color={colors.jitPink} icon={JitFavicon} size={28} />
        </Link>

        {!isCollapsed && (
          <>
            {!hasPaidSubscription && upgradeBtnText && (
              <div className={styles.upgradeBtnWrapper}>
                <JitUpgradeButton size='small' text={upgradeBtnText} textSize='s' />
              </div>
            )}

            <span>
              <IconButton icon={Docs} onClick={onDocsClick} size={18} tooltipText='navbar.buttons.docs' />
            </span>

            <div className={`beamerTrigger ${styles.beamerTrigger}`}>
              <IconButton icon={Bell} onClick={onBeamerClick} size={20} tooltipText='navbar.buttons.whatsNew' />
            </div>
          </>
        )}
      </div>

      <JitDivider />

      <JitPopper
        content={<SelectTenantPopper handleLogout={handleLogout} shouldShowArrow={false} shouldShowLogout={false} />}
        isWithArrow={false}
        open={isPopperOpened && !isCollapsed}
        shadowVariant='none'
        transition={false}
      >
        <TenantRow
          backgroundColor='transparent'
          endAdornment={endAdornment}
          isLast={false}
          onClick={() => { setIsPopperOpened((prevState) => !prevState); }}
          shouldHandleHover={shouldHandleHover}
          shouldShowName={!isCollapsed}
          tenantName={tenantName}
        />
      </JitPopper>

      <JitDivider />
    </div>
  );
};
