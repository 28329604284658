import { FC } from 'react';

import { JitText } from 'components/JitText/JitText';

interface ConditionTitleProps {
  workflowTriggerName?: string;
}

/**
 * Displays the CTA question text for the condition step.
 */
export const ConditionTitle: FC<ConditionTitleProps> = ({ workflowTriggerName }) => (
  <JitText
    bold
    data-testid='conditionCtaQuestion'
    muted
    params={{ triggerName: workflowTriggerName }}
    size='s'
    text='pages.workflows.editWorkflowPage.stepDetails.condition.ctaQuestion'
  />
);
