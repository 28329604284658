import { isEqual, isEmpty, cloneDeepWith } from 'lodash';
import { useCallback, useMemo } from 'react';

import { IPolicyRule, IRuleTemplate, RuleSettings, DataType, Condition, BaseRuleTemplate } from '../../../types';
import { getDynamicConfig } from '../../../utils';

interface UsePolicyFormParams {
  ruleSettingsData: RuleSettings;
  editRuleSettingsData: RuleSettings | undefined;
  ruleTemplate: BaseRuleTemplate;
  isEditMode: boolean;
}

export const usePolicyForm = ({ ruleSettingsData, editRuleSettingsData, ruleTemplate, isEditMode }: UsePolicyFormParams) => {
  // eslint-disable-next-line max-statements
  const validateInput = useCallback((
    value: DataType,
    inputTemplate: IRuleTemplate['inputs'][keyof IRuleTemplate['inputs']],
    settings: IPolicyRule['settings'] | Condition,
    fieldName: string,
  ): boolean => {
    if (!inputTemplate) return true;

    let dynamicTemplate;
    try {
      dynamicTemplate = getDynamicConfig(inputTemplate, settings, fieldName);
    } catch (error) {
      dynamicTemplate = inputTemplate;
    }

    // Required field validation
    if ((dynamicTemplate?.required || inputTemplate.required)) {
      if (dynamicTemplate?.inputType === 'number') {
        if (value === undefined || value === null || value === '') return false;
      } else if (isEmpty(value)) {
        return false;
      }
    }

    // Number validation
    if (dynamicTemplate?.inputType === 'number' && dynamicTemplate?.validation && value !== undefined && value !== null && value !== '') {
      const numValue = Number(value);
      const { min, max } = dynamicTemplate.validation;

      if ((min !== undefined && numValue < min)
          || (max !== undefined && numValue > max)) {
        return false;
      }
    }

    // Multi-select validation
    if (dynamicTemplate?.multi && Array.isArray(value)
        && dynamicTemplate?.required && value.length === 0) {
      return false;
    }

    return true;
  }, []);

  const validateForm = useCallback((currentSettings: RuleSettings | undefined): boolean => {
    if (!isEditMode || !currentSettings) return true;

    // Check base fields
    const baseFieldsValid = Object.entries(currentSettings)
      .filter(([field]) => field !== 'conditions')
      .every(([field, value]) => {
        const inputTemplate = ruleTemplate.inputs[field];
        return validateInput(value as DataType, inputTemplate, currentSettings, field);
      });

    if (!baseFieldsValid) return false;

    // Check conditions if they exist
    const conditionsValid = !Array.isArray(currentSettings.conditions) || currentSettings.conditions.every((condition) => Object.entries(condition).every(([field, value]) => {
      const inputTemplate = ruleTemplate.inputs[field];
      return validateInput(value, inputTemplate, condition, field);
    }));

    return conditionsValid;
  }, [isEditMode, ruleTemplate.inputs, validateInput]);

  const isDirty = useMemo(() => {
    if (!isEditMode || !editRuleSettingsData) return false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const customizer = (value: any) => {
      if (typeof value === 'number') {
        return value.toString();
      }
      return undefined; // Return undefined to handle the value in the default way
    };

    const normalizedSettings = cloneDeepWith(ruleSettingsData, customizer);
    const normalizedEditSettings = cloneDeepWith(editRuleSettingsData, customizer);

    return !isEqual(normalizedSettings, normalizedEditSettings);
  }, [isEditMode, ruleSettingsData, editRuleSettingsData]);

  const isValid = useMemo(
    () => validateForm(editRuleSettingsData),
    [validateForm, editRuleSettingsData],
  );

  return {
    isDirty,
    isValid,
  };
};
