import { FC, useEffect } from 'react';

import { PageContent } from './components/PageContent/PageContent';
import { useHandleImportTeamsWebSocketNotifications } from './hooks/useHandleImportTeamsWebSocketNotifications';
import styles from './TeamsPage.module.scss';

import { PageTitles } from 'components/PageTitles/PageTitles';
import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { WebSocketNotificationTopics } from 'types/enums';

export const TeamsPage: FC = () => {
  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handleImportTeamsWebSocketNotification } = useHandleImportTeamsWebSocketNotifications();

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.Team, handleImportTeamsWebSocketNotification);
  }, [handleImportTeamsWebSocketNotification, websocketSubscribe]);

  return (
    <div className={styles.wrapper}>
      <PageTitles subtitle='pages.teams.subtitle' title='pages.teams.title' />

      <PageContent />
    </div>
  );
};
