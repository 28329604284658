import { createContext, useContext, Dispatch, SetStateAction } from 'react';

import { IWorkflow, Step } from 'types/interfaces/Workflows/IWorkflow';
import { IWorkflowStepOption, IWorkflowStepOptions } from 'types/interfaces/Workflows/IWorkflowStepOptions';

interface EditWorkflowContextType {
  workflow: IWorkflow | undefined;
  setWorkflow: Dispatch<SetStateAction<IWorkflow>>;
  isNewWorkflow: boolean;
  isLoadingWorkflow: boolean;
  fetchedWorkflow: IWorkflow | undefined;
  isFailedFetchingWorkflow: boolean;
  hasWorkflowChanged: boolean;

  getStepNextOptions: (step: Step) => IWorkflowStepOption[];
  stepsOptions?: IWorkflowStepOptions;
  isLoadingStepsOptions: boolean;

  openStepDetails: (stepId: string) => void;
  selectedStepId: string | undefined;
  setSelectedStepId: Dispatch<SetStateAction<string | undefined>>;
  closeStepDetailsPanel: () => void;
}

export const EditWorkflowContext = createContext<EditWorkflowContextType | null>(null);

export const useEditWorkflowContext = () => {
  const context = useContext(EditWorkflowContext);
  if (!context) {
    throw new Error('useEditWorkflowContext must be used within an EditWorkflowContext.Provider');
  }
  return context;
};
