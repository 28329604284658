import Popper, { PopperProps } from '@mui/material/Popper';
import { FC } from 'react';

import { AutocompleteVariant } from '../JitAutocompleteForm';

interface CustomPopperProps extends PopperProps {
  variant?: AutocompleteVariant;
  inputWidth?: number;
}

const variantToPopperProps: Record<AutocompleteVariant, Record<string, unknown>> = {
  default: {},
  light: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  },
};

export const CustomPopper: FC<CustomPopperProps> = ({
  variant = 'default',
  inputWidth,
  style,
  ...props
}) => (
  <Popper
    {...props}
    {...variantToPopperProps[variant]}
    style={{
      ...style,
      width: inputWidth,
    }}
  />
);
