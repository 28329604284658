import { Cancel, CheckCircle, Error, Info } from '@mui/icons-material';
import { FC } from 'react';

import styles from './JitSnackBar.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import { SnackBarAction, SnackBarType } from 'utils/hooks/useSnackBar';

interface Props {
  title: string;
  description?: string;
  type: SnackBarType;
  action?: SnackBarAction;
}

const variantToIcon: Record<SnackBarType, JSX.Element | undefined> = {
  success: <CheckCircle color='success' fontSize='small' />,
  error: <Cancel color='error' fontSize='small' />,
  warning: <Error color='warning' fontSize='small' />,
  info: <Info color='info' fontSize='small' />,
  loading: <CircularLoadingSpinner />,
  default: undefined,
};

export const JitSnackBar: FC<Props> = ({ title, description = '', type, action }) => {
  const isLoading = type === 'loading';
  const iconClassName = isLoading ? '' : styles.icon;

  return (
    <div className={styles.root} data-testid='jit-snackbar'>
      <div className={iconClassName} data-testid='jit-snackbar-icon'>{variantToIcon[type]}</div>

      <div className={styles.textContainer} data-testid='jit-snackbar-text-container'>
        <JitText bold data-testid='jit-snackbar-title' size='l' text={title} />

        <JitText className={styles.description} data-testid='jit-snackbar-description' muted text={description} />

        {action && (
          <JitText
            className={styles.actionText}
            color={colors.iris}
            data-testid='jit-snackbar-action'
            onClick={action.handleClick}
            text={action.label}
          />
        )}
      </div>
    </div>
  );
};
