import { FC } from 'react';

import { WorkflowHistoryTable } from './components/WorkflowHistoryTable/WorkflowHistoryTable';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { useWorkflowHistory } from 'pages/WorkflowsPage/hooks/useWorkflowHistory';

interface IWorkflowHistoryViewProps {
  workflowId: string;
}

export const WorkflowHistoryView: FC<IWorkflowHistoryViewProps> = ({ workflowId }) => {
  const {
    runs,
    workflow,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useWorkflowHistory(workflowId);

  return (
    <div data-testid='history-view'>
      {isLoading
        ? <LoadingBar />
        : (
          <WorkflowHistoryTable
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            runs={runs}
            workflow={workflow}
          />
        )}
    </div>
  );
};
