import { FC, useMemo } from 'react';

import { CreateWorkflowButton } from '../components/CreateWorkflowButton/CreateWorkflowButton';

import styles from './EmptyWorkflowPage.module.scss';

import { Link, PremiumLock, WorkflowEmptyBG } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { JitUpgradeButton } from 'components/JitUpgradeButton/JitUpgradeButton';
import { EmptyPage } from 'components/Pages/EmptyPage/EmptyPage';
import { PremiumFeatureBanner } from 'components/PremiumFeatureBanner/PremiumFeatureBanner';
import { config } from 'config';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

interface EmptyWorkflowPageProps {
  isEntitled: boolean;
  isReadOnly: boolean;
}

interface ITitlesTexts {
  title: string;
  subtitle1: string;
  subtitle2: string;
}

export const EmptyWorkflowPage: FC<EmptyWorkflowPageProps> = ({ isEntitled, isReadOnly }) => {
  const { t } = i18n;

  const emptyPageType = useMemo(() => (isEntitled ? 'entitled' : 'notEntitled'), [isEntitled]);

  const labels: ITitlesTexts = useMemo(
    () => t(`pages.workflows.emptyPage.${emptyPageType}`, { returnObjects: true }),
    [t, emptyPageType],
  );

  const upgradeNowTexts = useMemo(
    () => t('common.pricingPlan.upgradeNow').toUpperCase(),
    [t],
  );

  const premiumBannerMessageText = useMemo(
    () => t('pages.workflows.premiumBannerMessage'),
    [t],
  );

  const bgImage = useMemo(() => <WorkflowEmptyBG />, []);

  const Header = useMemo(() => (<PremiumFeatureBanner isEntitled={isEntitled} message={premiumBannerMessageText} />), [isEntitled, premiumBannerMessageText]);

  const Content = useMemo(
    () => (
      <div className={styles.content} data-testid='empty-workflow-page-content'>
        <div className={styles.title}>
          {!isEntitled && <JitIcon icon={PremiumLock} size={20} />}

          <JitText className={styles.contentTitle} size='xxl' text={labels.title} />
        </div>

        <div className={styles.subtitle}>
          <JitText muted text={labels.subtitle1} />

          <JitText muted text={labels.subtitle2} />
        </div>

        {isEntitled ? <CreateWorkflowButton isFirstWorkflow isReadOnly={isReadOnly} /> : <JitUpgradeButton text={upgradeNowTexts} />}
      </div>
    ),
    [isEntitled, labels, upgradeNowTexts, isReadOnly],
  );

  const Footer = useMemo(
    () => (!isEntitled ? (
      <JitLink
        className={styles.footer}
        color={colors.blue02}
        href={config.contactUsUrl}
        rel='noreferrer'
        target='_blank'
        title='contact us'
        underline='hover'
      >
        <JitText color={colors.iris} text='common.pricingPlan.extendFreeTrial' />

        <JitIcon color={colors.iris} icon={Link} size={12} />
      </JitLink>
    ) : null),
    [isEntitled],
  );

  return <EmptyPage bgImage={bgImage} content={Content} footer={Footer} header={Header} />;
};
