import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import colors from 'themes/colors.module.scss';

// In general we had issue in how we determine the min width the input needs.
// If we have tags it was pretty easy because we could determine the size by using fit-content and leave space
// for the input and end adornment.
// The difficult part was how to determine the min-width with only text input as children, it's not straight forward.
// So we had to calculate the width of the text (JS function) and pass it as prop.

export const LightTextField = styled(TextField)<{
  hasTags: boolean;
  minInputWidth: number | null;
  optionsWidth?: number;
}>(({ hasTags, minInputWidth, optionsWidth }) => ({
  '& .MuiOutlinedInput-root': {
    color: colors.white,
    display: 'flex',
    padding: '4px !important',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    minWidth: 'fit-content',
    width: hasTags ? 'fit-content' : 'fit-content',
    '& input': {
      padding: '4px !important',
      minWidth: hasTags ? 'unset !important' : `${minInputWidth}px !important`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-disabled': {
      '& input': {
        '-webkit-text-fill-color': `${colors.white} !important`,
      },
    },
    '&.Mui-focused': {
      width: hasTags
        ? 'fit-content'
        : optionsWidth
          ? `${optionsWidth}px`
          : minInputWidth
            ? `${minInputWidth}px`
            : 'fit-content',
      '& input': {
        minWidth: hasTags
          ? '40px !important'
          : optionsWidth
            ? `${optionsWidth}px !important`
            : minInputWidth && minInputWidth < 80
              ? '80px !important'
              : `${minInputWidth}px !important`,
      },
    },
  },
  '& .Mui-focused': {
    color: `${colors.white} !important`,
  },
}));
