import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';

import './index.scss';
import './locale/i18n';

import { App } from 'App';
import { config } from 'config';
// eslint-disable-next-line import/no-unresolved
import 'unfonts.css';

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/
Sentry.init({
  dsn: config.sentryDsn,
  integrations: [
    new BrowserTracing({ tracingOrigins: ['^((?!getbeamer).)*$'] }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: config.isProduction ? 1.0 : 0,
  environment: process.env.VITE_ENVIRONMENT || process.env.NODE_ENV,
  beforeSend: (e) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return e;
  },
});

const container = document.getElementById('root');
const root = createRoot(container!); // Ensure container is not null
root.render(<App />);
