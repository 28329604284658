import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CompletionStatusRow } from '../../../components/CompletionStatusRow/CompletionStatusRow';
import { useCurrentTeam } from '../hooks/useCurrentTeam/useCurrentTeam';

import { useGetActionComponents } from './components/actionComponents/useGetActionComponents/useGetActionComponents';
import { BaseTaskDialog } from './components/BaseTaskDialog/BaseTaskDialog';
import { BaseTaskGuide } from './components/BaseTaskGuide/BaseTaskGuide';
import { useGetDataComponents } from './components/dataComponents/useGetDataComponents/useGetDataComponents';
import styles from './GuidePage.module.scss';

import { JitDivider } from 'components/JitDivider/JitDivider';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { constants } from 'globalConstants';
import { useTenantService } from 'services/TenantService/useTenantService';
import { ITeamCheckDetails } from 'types/interfaces/Teams/ITeam';

export const GuidePage: FC = () => {
  const {
    teamsPortal: { BASE_ROUTE: BASE_TEAMS_PORTAL_ROUTE, WELCOME },
  } = constants.routes;
  const [openedDialogKey, setOpenedDialogKey] = useState<string | null>(null);
  const { currentTeam, isLoadingSpecificTeam } = useCurrentTeam();
  const { updateUser } = useTenantService();

  const { frontEggUser, setViewedWelcomeTeamsPage } = useAuthContext();
  const navigate = useNavigate();

  const handleUserHaveNoTeams = async () => {
    await updateUser({
      user_id: frontEggUser.id,
      metadata: {
        viewed_welcome_teams_page: {
          ...JSON.parse(frontEggUser?.metadata ?? '{}')?.viewed_welcome_teams_page,
          [frontEggUser.tenantId]: false,
        },
      },
    });
    setViewedWelcomeTeamsPage(false);
    navigate(`/${BASE_TEAMS_PORTAL_ROUTE}/${WELCOME}`);
  };

  useEffect(() => {
    if (!currentTeam && !isLoadingSpecificTeam) {
      handleUserHaveNoTeams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checksMap = useMemo(() => currentTeam?.checks?.reduce((acc: { [key: string]: ITeamCheckDetails }, checkTemplate: ITeamCheckDetails) => {
    acc[checkTemplate.checkSlug] = checkTemplate;
    return acc;
  }, {}), [currentTeam?.checks]);

  const updateCheck = useCallback((updatedCheck: ITeamCheckDetails, value?: string) => {
    const check = checksMap?.[updatedCheck.checkSlug];
    if (check) {
      check.isCompleted = true;
      check.value = value;
      check.metadata = updatedCheck.metadata;
    }
    if (currentTeam?.checks?.every((teamCheck) => !teamCheck.isRequired || teamCheck.isCompleted)) {
      currentTeam.isActivated = true;
    }
  }, [checksMap, currentTeam]);

  const actionComponentMap = useGetActionComponents(currentTeam, checksMap, setOpenedDialogKey, updateCheck);
  const dataComponents = useGetDataComponents(currentTeam, checksMap);

  const checksComponents = useMemo(() => {
    let isFollowingDisabled = false;
    return currentTeam?.checks?.map((check, index) => {
      if (index > 0 && !isFollowingDisabled) {
        const prevTemplate = currentTeam?.checks?.[index - 1];
        isFollowingDisabled = !!(prevTemplate?.isRequired && !prevTemplate?.isCompleted);
      }
      if (check?.isHidden) {
        return null;
      }

      const actionComponentKey = check?.options?.button?.actionKey as keyof typeof actionComponentMap;
      const Button = check?.options?.button && (() => actionComponentMap?.[actionComponentKey] || null);
      const rightComponent = (() => dataComponents?.[check.dataComponent as keyof typeof dataComponents] || null) as FC;

      return (
        <BaseTaskGuide
          key={check.checkSlug}
          description={check.description}
          isCompleted={!!check?.isCompleted}
          isDisabled={isFollowingDisabled}
          MainComponent={Button}
          RightComponent={rightComponent}
          title={check.title}
        />
      );
    });
  }, [actionComponentMap, currentTeam?.checks, dataComponents]);

  const checksToShow = useMemo(() => currentTeam?.checks?.filter((check) => !check.isHidden) || [], [currentTeam?.checks]);
  const completedChecksCount = useMemo(() => checksToShow?.filter((check) => check.isCompleted).length, [checksToShow]);

  return (
    <PageLayout>
      <div className={styles.wrapper} data-testid='teams-portal-guide-content'>
        <CompletionStatusRow
          completed={completedChecksCount}
          description='pages.teamsPortal.guidePage.quickStartGuide.description'
          title='pages.teamsPortal.guidePage.quickStartGuide.title'
          total={checksToShow.length}
        />

        <div className={styles.separator}>
          <JitDivider />
        </div>

        <div className={styles.tasksWrapper}>

          {checksComponents}

        </div>

        {openedDialogKey && checksMap?.[openedDialogKey] && currentTeam && (
        <BaseTaskDialog
          check={checksMap?.[openedDialogKey]}
          handleClose={() => setOpenedDialogKey(null)}
          isOpen={!!openedDialogKey}
          teamId={currentTeam.id}
          updateCheck={updateCheck}
        />
        )}
      </div>
    </PageLayout>

  );
};
