import { FC, PropsWithChildren } from 'react';

import styles from './GeneralPage.module.scss';

interface Props {
}

export const GeneralPage: FC<PropsWithChildren<Props>> = ({ children }) => (
  <div className={styles.wrapper}>

    {children}
  </div>
);
