import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useCallback, useMemo, useState } from 'react';

import styles from './GitlabIntegrationWizard.module.scss';
import { useTemporaryWizardSteps } from './hooks/useTemporaryWizardSteps';

import { useWizardSteps } from 'components/GitlabIntegrationWizard/hooks/useWizardSteps';
import { StepsDialog } from 'components/JitDialogs/StepsDialog/StepsDialog';
import { SCMWizardProps } from 'utils/hooks/useScmIntegration';

export enum IGitlabIntegrationWizardStepStatus {
  INCOMPLETE,
  ACTIVE,
  COMPLETE,
}

export interface IGitlabIntegrationWizardStep {
  title: string,
  component: FC,
  status: IGitlabIntegrationWizardStepStatus,
}

export const GitlabIntegrationWizard: FC<SCMWizardProps> = ({ handleClose }) => {
  const { uiShowNewGitlabOnboarding } = useFlags();
  const [stepIndex, setStepIndex] = useState(0);

  const incrementStepIndex = useCallback(() => setStepIndex(stepIndex + 1), [stepIndex]);

  const deprecatedWizardSteps = useTemporaryWizardSteps({
    incrementStepIndex,
    handleClose,
  });

  const newWizardSteps = useWizardSteps({
    incrementStepIndex,
    handleClose,
  });

  const steps = uiShowNewGitlabOnboarding ? newWizardSteps : deprecatedWizardSteps;

  const StepComponent = useMemo(() => (stepIndex < steps.length ? steps[stepIndex].component : () => null), [stepIndex, steps]);

  const formattedSteps = useMemo(
    () => steps.map((step, index) => ({
      stepName: step.title,
      isSelected: stepIndex === index,
      isVerified: IGitlabIntegrationWizardStepStatus.COMPLETE === step.status,
      content: <StepComponent />,
    })),
    [steps, stepIndex, StepComponent],
  );

  return (
    <div className={styles.background} data-testid='gitlab-integration-wizard'>

      <StepsDialog
        height='m'
        isOpen
        onClose={handleClose}
        setStep={setStepIndex}
        steps={formattedSteps}
        title='GitlabIntegrationWizard.title'
        width='xl'
        withDivider
      />
    </div>
  );
};
