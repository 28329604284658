import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import styles from './Layout.module.scss';

import { DocsQA } from 'components/DocsQA/DocsQA';
import { Navbar } from 'components/Navbar/Navbar';
import { AllToasts } from 'wrappers/AllToasts/AllToasts';
import { SharedLayout } from 'wrappers/SharedLayout/SharedLayout';

interface Props {
  showNavbar?: boolean;
}

export const Layout: FC<Props> = ({ showNavbar = true }) => (
  <div className={styles.wrapper}>
    <SharedLayout displayToastsTopScreen />

    <div className={styles.contentWrapper}>
      <DocsQA />

      {showNavbar && <Navbar />}

      <div className={styles.root}>
        <AllToasts displayTopScreen={false} />

        <Outlet />
      </div>
    </div>
  </div>
);
