import { FC } from 'react';

import { ResourcesDashboardPage } from './ResourcesDashboardPage';
import { useRedirectToResourcePage } from './useRedirectToResourcePage';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { GraphProvider } from 'context/GraphContext/GraphProvider';

export const ResourcesDashboardPageWrapper: FC = () => {
  useRedirectToResourcePage();

  return (
    <PageLayout>
      <GraphProvider>
        <ResourcesDashboardPage />
      </GraphProvider>
    </PageLayout>
  );
};
