/* eslint-disable react/no-multi-comp */
import { SvgIconComponent } from '@mui/icons-material';
import { FC, lazy, Suspense } from 'react';

/**
 * Component to dynamically load and render an MUI icon.
 * @param iconName The name of the MUI icon to load.
 */
interface MUIIconProps {
  iconName: string;
  color?: string;
  className?: string;
}

const DefaultIcon: FC = () => <span style={{ display: 'none' }} />;
const FallBackIcon: FC = () => (
  <div
    data-testid='fallback'
    style={{ width: 24,
      height: 24 }}
  />
);

export const MUIIcon: FC<MUIIconProps> = ({ iconName, color, className }) => {
  const LazyIcon = lazy(() => import('@mui/icons-material')
    .then((module) => {
      const Icon = module[iconName as keyof typeof module] as SvgIconComponent | undefined;
      return { default: Icon || DefaultIcon };
    })
    .catch(() => ({ default: DefaultIcon })));

  return (
    <Suspense fallback={<FallBackIcon />}>
      <LazyIcon
        className={className}
        data-testid='policy-icon'
        style={{ color }}
      />
    </Suspense>
  );
};
