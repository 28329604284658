import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { FC, PropsWithChildren, useMemo } from 'react';

import { config } from 'config';
import { useAuthContext } from 'context/AuthContext/AuthContext';

export const FeatureFlagProvider: FC<PropsWithChildren> = ({ children }) => {
  const { frontEggUser: { tenantId = '' } } = useAuthContext();
  const LaunchDarklyConfig = useMemo(() => ({
    clientSideID: config.launchDarklyClientId || '',
    user: {
      key: tenantId,
      name: tenantId,
    },
  }), [tenantId]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const LaunchDarklyProvider = useMemo(() => withLDProvider(LaunchDarklyConfig)(() => <>{children}</>), [LaunchDarklyConfig, children]);

  return (
    <LaunchDarklyProvider />
  );
};
