
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FC, useState } from 'react';

import { WorkflowInfoPopper } from './components/WorkflowInfoPopper/WorkflowInfoPopper';
import styles from './WorkflowInfoButton.module.scss';

import { IconButton } from 'components/IconButton/IconButton';
import { JitPopper } from 'components/JitPopper/JitPopper';
import colors from 'themes/colors.module.scss';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

interface WorkflowInfoProps {
  workflow: IWorkflow;
  disabled: boolean;
}

export const WorkflowInfoButton: FC<WorkflowInfoProps> = ({ workflow, disabled }) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  return (
    <JitPopper
      className={styles.popper}
      content={<WorkflowInfoPopper createdAt={workflow.createdAt} createdBy={workflow.creatorUserName} />}
      isWithArrow={false}
      open={isInfoOpen}
      placement='bottom'
      shadowVariant='none'
      transition={false}
    >

      <IconButton
        backSquareColor={isInfoOpen ? colors.iris : colors.deepPurple}
        color={isInfoOpen ? colors.cards : colors.iris}
        icon={InfoOutlinedIcon}
        isDisabled={disabled}
        isToggled={isInfoOpen}
        onClick={() => setIsInfoOpen((prev) => !prev)}
        size={22}
        testId='workflow-info-button'
        tooltipText={isInfoOpen ? '' : 'pages.workflows.editWorkflowPage.header.tooltips.details'}
      />
    </JitPopper>
  );
};
