import { t } from 'i18next';
import { useState, useCallback, ChangeEvent } from 'react';
import { useQueryClient } from 'react-query';

import { useSecretService } from 'services/SecretsService/useSecretService';
import { Queries } from 'types/enums/Queries';
import { useSnackBar } from 'utils/hooks/useSnackBar';

export const useSecretManagement = (secretExists: boolean, secretName: string, incrementStepIndex: () => void) => {
  const [secretValue, setSecretValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { saveTenantSecret } = useSecretService();
  const { showSnackBar } = useSnackBar();
  const queryClient = useQueryClient();

  const onSecretValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSecretValue(event.target.value);
  }, []);

  const onContinueButtonClick = useCallback(async () => {
    if (secretExists) {
      incrementStepIndex();
      return;
    }

    setIsLoading(true);
    try {
      await saveTenantSecret({
        secretName,
        secretValue,
      });
      await queryClient.invalidateQueries([Queries.TenantSecrets]);
      incrementStepIndex();
    } catch (error) {
      showSnackBar({
        title: t('GitlabIntegrationWizard.connectWebhooks.tokenInput.errorSavingSecret'),
        description: t('GitlabIntegrationWizard.connectWebhooks.tokenInput.errorSavingSecretDescription'),
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, [secretValue, saveTenantSecret, incrementStepIndex, secretExists, secretName, showSnackBar, queryClient]);

  return {
    secretValue,
    isLoading,
    onSecretValueChange,
    onContinueButtonClick,
  };
};
