import { FC, useMemo } from 'react';

import styles from './GitlabStartScanningSection.module.scss';

import { ChevronOpened, Info } from 'assets';
import { useCompleteOnboardingStep } from 'components/GitlabIntegrationWizard/hooks/useCompleteOnboardingStep';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  initialOnboardingCompleted: boolean;
  dataTestId?: string;
}

export const GitlabStartScanningSection: FC<Props> = ({ initialOnboardingCompleted, dataTestId }) => {
  const {
    isCompleteOnboardingLoading,
    onCompleteOnboardingButtonClick,
    hasCompleteOnboardingPressedAndFinished,
  } = useCompleteOnboardingStep();

  const shouldShowStartScanningInfoBanner = useMemo(
    () => !initialOnboardingCompleted,
    [initialOnboardingCompleted],
  );

  return (
    <div
      data-testid={dataTestId}
    >
      {
          shouldShowStartScanningInfoBanner && (
          <JitInfoBanner
            bgColor={colors.cardContent}
            className={styles.infoBanner}
            data-testid='start-scanning-info-banner'
            icon={Info}
            iconColor={colors.blue}
          >
            <JitText
              color={colors.white}
              text='GitlabIntegrationWizard.integrateGitlab.startScanningInfo'
            />
          </JitInfoBanner>
          )
        }

      <JitGradientButton
        data-testid='start-scanning-button'
        disabled={initialOnboardingCompleted}
        isLoading={isCompleteOnboardingLoading || (hasCompleteOnboardingPressedAndFinished && !initialOnboardingCompleted)}
        onClick={onCompleteOnboardingButtonClick}
        sx={{
          padding: '16px 20px',
          height: '46px',
          marginTop: '20px',
          width: '167px',
        }}
      >
        <div className={styles.buttonContent}>
          <JitText bold size='s' text='GitlabIntegrationWizard.integrateGitlab.startScanningButton' />

          <JitIcon
            icon={ChevronOpened}
            style={{ transform: 'rotate(90deg)' }}
          />
        </div>
      </JitGradientButton>
    </div>
  );
};
