import { FC } from 'react';

import styles from './JitSidePanel.module.scss';

import { Cross } from 'assets';
import { JitDivider } from 'components/JitDivider/JitDivider';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';
import commonStyles from 'themes/common.module.scss';
import { stopPropagation } from 'utils';

interface Props {
  children: React.ReactNode;
  closePanel: () => void;
  title: string;
  showCloseButton?: boolean;
}

export const JitSidePanel: FC<Props> = ({
  children,
  closePanel,
  title,
  showCloseButton = true,
}) => (
  <div className={styles.body} data-testid='JitSidePanel' onClick={stopPropagation} role='presentation'>
    <div className={styles.headerWrapper}>
      <div className={styles.titleWrapper}>
        <JitText color={colors.lightGray} size='s' text={title} />

        <div className={styles.titleRightPart}>
          {showCloseButton && (
            <JitIcon icon={Cross} onClick={closePanel} size={20} />
          )}
        </div>
      </div>
    </div>

    <JitDivider />

    <div className={`${styles.content} ${commonStyles.scrollInnerShadow}`}>
      {children}
    </div>
  </div>
);
