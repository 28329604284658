import { FC } from 'react';
import { createPortal } from 'react-dom';

import styles from './ApprovalText.module.scss';
import { useCheckoutTextObserver } from './hooks/useCheckoutTextObserver';

import { JitText } from 'components/JitText/JitText';
import { config } from 'config';

interface CheckoutStepProps {
  checkoutStep: number;
}

export const ApprovalText: FC<CheckoutStepProps> = ({ checkoutStep }) => {
  const { container, buttonText, shouldShow } = useCheckoutTextObserver(
    '.approval-text-container',
    'approval-text-container',
    '.stigg-watermark',
    [checkoutStep],
  );

  if (!container || !buttonText || !shouldShow) return null;

  return createPortal(
    <p className={styles.approvalText}>
      <JitText
        display='inline'
        params={{ buttonText }}
        size={12}
        text='common.checkout.termsAgreement'
      />

      {' '}

      <a href={config.docsTermsOfServiceLink} rel='noreferrer' target='_blank'>
        <JitText
          color='inherit'
          display='inline'
          size={12}
          text='common.checkout.terms'
        />
      </a>
    </p>,
    container,
  );
};
