import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { useCallback } from 'react';
import { QueryFunctionContext } from 'react-query';

import { getApiUrls } from 'services/apiUrls';
import { GET_CHILD_TEAMS_LIMIT, GET_MEMBERS_LIMIT, GET_TEAMS_PAGE_LIMIT } from 'services/TeamsService/constants';
import { useClient } from 'services/useClient';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { IGetTeamResponseItem, IMember, ITeam, ITeamCheckDetails } from 'types/interfaces/Teams/ITeam';
import { ITeamPreferences, ITeamPreferencesPayload, TeamPreferencesType } from 'types/interfaces/Teams/ITeamPreferences';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';
import { ImportTeamsMapRequest } from 'types/interfaces/Teams/TeamsStructure';
import { parseObjectToQueryParams } from 'utils';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';
import { useSnackBar } from 'utils/hooks/useSnackBar';

const serviceName = 'teams';

type FetchTeamsParams = {
  limit?: number;
  page?: number;
  after?: string;
  sort_by?: TeamSortBy;
  sort_order?: TeamSortOrder;
  search_value?: string;
  include_members?: boolean;
  member_search_key?: string;
  member_search_value?: string;
  search_key?: string;
  include_image?: boolean;
  user_id?: string;
};

export type FetchTeamOptions = {
  sortBy: TeamSortBy;
  sortOrder: TeamSortOrder;
  after?: string;
  searchValue?: string;
  searchKey?: string;
  memberSearch?: MemberSearch;
  limit?: number;
  page?: number;
  displayImage?: boolean;
  displayMembers?: boolean;
  userId?: string;
};

export type MemberSearch = {
  key: string;
  value: string;
};

export const useTeamsService = () => {
  const { client } = useClient();
  const { showSnackBar } = useSnackBar();

  const fetchTeams = useCallback(
    async ({
      queryKey,
      pageParam,
    }: QueryFunctionContext<[string, FetchTeamOptions]>): Promise<IPaginatedResponse<IGetTeamResponseItem> | undefined> => {
      const [, queryParams] = queryKey;

      const {
        sortBy,
        sortOrder,
        after,
        searchValue,
        searchKey,
        memberSearch,
        limit = GET_TEAMS_PAGE_LIMIT,
        displayImage,
        userId,
        displayMembers = true,
        page = 1,
      } = queryParams;

      const params: FetchTeamsParams = {
        limit,
        after: after || pageParam?.after,
        page,
        sort_by: sortBy,
        sort_order: sortOrder,
        user_id: userId,
        ...(searchValue && { search_value: searchValue }),
        ...(memberSearch && {
          member_search_key: memberSearch.key,
          member_search_value: memberSearch.value,
        }),
        ...(searchKey && { search_key: searchKey }),
        ...(displayImage && { include_image: displayImage }),
        ...(displayMembers && { include_members: displayMembers }),
      };

      const url = getApiUrls.teamsService.fetchTeams();
      const response = await client.get<IPaginatedResponse<IGetTeamResponseItem>>({
        url,
        allowedStatuses: [200],
        requestConfig: { params },
      });

      if (response?.status === 200) {
        return {
          data: camelizeSnakeCaseKeys(response.data.data) as IGetTeamResponseItem[],
          metadata: response.data.metadata,
        };
      }

      throw new Error('Error fetching teams');
    },
    [client],
  );

  type FetchTeamByIdParams = {
    teamId: string;
    includeChecks?: boolean;
    includePosition?: boolean;
  };

  const fetchTeamById = useCallback(
    async ({ queryKey }: QueryFunctionContext<[string, FetchTeamByIdParams]>): Promise<ITeam | undefined> => {
      const [, params] = queryKey;
      const { teamId, includeChecks, includePosition } = params;

      const url = getApiUrls.teamsService.fetchTeamById(teamId);
      const response = await client.get<ITeam>({
        url,
        requestConfig: {
          params: {
            ...(includeChecks && { include_checks: includeChecks }),
            ...(includePosition && { include_rank: includePosition }),
          },
        },
        allowedStatuses: [200],
      });

      if (response?.status === 200) {
        return camelizeSnakeCaseKeys(response.data) as ITeam;
      }
      return undefined;
    },
    [client],
  );

  const fetchMembersById = useCallback(async (teamId: string, after?: string): Promise<IPaginatedResponse<IMember> | undefined> => {
    const params = {
      limit: GET_MEMBERS_LIMIT,
      after,
    };
    const url = getApiUrls.teamsService.fetchMembersById(teamId);
    const response = await client.get<IPaginatedResponse<IMember>>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params,
      },
    });
    if (response?.status === 200) {
      return {
        data: camelizeSnakeCaseKeys(response!.data.data) as IMember[],
        metadata: response!.data.metadata,
      };
    }
    return undefined;
  }, [client]);

  const fetchTeamChildren = useCallback(async (teamId: string, after?: string): Promise<IPaginatedResponse<ITeam> | undefined> => {
    const params = {
      limit: GET_CHILD_TEAMS_LIMIT,
      after,
    };
    const stringParams = parseObjectToQueryParams(params);
    const url = `${serviceName}/${teamId}/children?${stringParams}`;
    const response = await client.get<IPaginatedResponse<ITeam>>({
      url,
      allowedStatuses: [200],
    });
    if (response?.status === 200) {
      return {
        data: camelizeSnakeCaseKeys(response!.data.data) as ITeam[],
        metadata: response!.data.metadata,
      };
    }
    return undefined;
  }, [client]);

  const patchPreferences = useCallback(async (teamId: string, teamName: string, payload: ITeamPreferencesPayload): Promise<ITeamPreferences | undefined> => {
    const url = getApiUrls.teamsService.preferences(teamId);
    const response = await client.patch<ITeamPreferences>({
      url,
      requestConfig: {
        data: payload,
      },
      allowedStatuses: [200, 404],
    });
    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response!.data) as ITeamPreferences;
    }

    if (response?.status === 404) {
      showSnackBar({
        title: 'toasts.teamPreferencesNotFound.title',
        description: t('toasts.teamPreferencesNotFound.subtitle', { teamName }),
        type: 'error',
      });
    }

    return undefined;
  }, [client, showSnackBar]);

  const fetchPreferences = useCallback(
    async (teamId: string, teamName: string, type?: TeamPreferencesType): Promise<ITeamPreferences | undefined> => {
      const params = {
        type,
      };
      const url = getApiUrls.teamsService.preferences(teamId);
      const response = await client.get<ITeamPreferences>({
        url,
        allowedStatuses: [200, 404],
        requestConfig: {
          params,
        },
      });
      if (response?.status === 200) {
        return camelizeSnakeCaseKeys(response!.data) as ITeamPreferences;
      }

      if (response?.status === 404) {
        showSnackBar({
          title: 'toasts.teamPreferencesNotFound.title',
          description: t('toasts.teamPreferencesNotFound.subtitle', { teamName: teamId }),
          type: 'error',
        });
      }

      return undefined;
    },
    [client, showSnackBar],
  );

  const updateTeamChecks = useCallback(async (teamId: string, checks: {
    check_slug: string;
    is_completed: boolean;
    value?: string | boolean;
  }[]): Promise<ITeamCheckDetails[] | undefined> => {
    const url = getApiUrls.teamsService.updateTeamChecks(teamId);
    const response = await client.put<AxiosResponse<ITeamCheckDetails>>({
      url,
      allowedStatuses: [200, 404],
      requestConfig: {
        data: { data: checks },
      },
    });
    if (response?.status === 200) {
      return camelizeSnakeCaseKeys(response!.data.data) as ITeamCheckDetails[];
    }
    return undefined;
  }, [client]);

  const uploadTeamsStructure = useCallback(async (file: File): Promise<AxiosResponse<{}> | undefined> => {
    const url = getApiUrls.teamsService.uploadTeamsStructure();
    const data = {
      content: JSON.parse(await file.text()),
    };

    return client.post<{}>({
      url,
      allowedStatuses: [202, 400],
      requestConfig: {
        data,
      },
    });
  }, [client]);

  const getTeamsStructureExample = useCallback(async (): Promise<AxiosResponse<ImportTeamsMapRequest> | undefined> => {
    const url = getApiUrls.teamsService.getTeamsStructureExample();
    const response = await client.get<ImportTeamsMapRequest>({
      url,
      allowedStatuses: [200],
    });

    if (response?.status !== 200) return undefined;
    return response;
  }, [client]);

  const deleteTeamById = useCallback(async (teamId: string) => {
    const url = getApiUrls.teamsService.deleteTeamById(teamId);
    const response = await client.delete<undefined>({
      url,
      allowedStatuses: [204],
    });
    if (response?.status === 204) {
      return response;
    }

    return undefined;
  }, [client]);

  return {
    fetchTeams,
    fetchTeamById,
    deleteTeamById,
    fetchMembersById,
    fetchTeamChildren,
    patchPreferences,
    fetchPreferences,
    updateTeamChecks,
    uploadTeamsStructure,
    getTeamsStructureExample,
  };
};

