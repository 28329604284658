export const policyRuleOptionsCallbackMap = {
  fetchFindingPriorityFactorsOptions: 'fetchFindingPriorityFactorsOptions',
  fetchAssetPriorityFactorsOptions: 'fetchAssetPriorityFactorsOptions',
  fetchFindingTypesOptions: 'fetchFindingTypesOptions',
  fetchAssetTypesOptions: 'fetchAssetTypesOptions',
  fetchFindingTeamsOptions: 'fetchFindingTeamsOptions',
  fetchAssetNamesOptions: 'fetchFindingTypesOptions',
};

export const allowIgnoreFindingsPolicySlug = 'allow_ignore_platform_findings';
