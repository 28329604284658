import { FC } from 'react';

import { IntegrationsPage } from './IntegrationsPage';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';

export const IntegrationsPageWrapper: FC = () => (
  <PageLayout>
    <IntegrationsPage />
  </PageLayout>
);
