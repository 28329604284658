import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { PageHeader, PageHeaderVariant } from './components/PageHeader/PageHeader';
import styles from './TeamsPortalLayout.module.scss';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { useCurrentTeam } from 'pages/TeamsPortal/hooks/useCurrentTeam/useCurrentTeam';
import { TeamSortBy } from 'types/interfaces/Teams/TeamSorting';
import { SharedLayout } from 'wrappers/SharedLayout/SharedLayout';

interface TeamsPortalLayoutProps {
  isWelcomeView: boolean;
}

export const TeamsPortalLayout: FC<TeamsPortalLayoutProps> = ({ isWelcomeView }) => {
  const { currentTeam, handleCurrentTeamChange, initTeams, isInitLoading, teams, isLoadingSpecificTeam } = useCurrentTeam({ sortBy: TeamSortBy.NAME });

  if (!isInitLoading && !teams.hasReachedEnd) {
    initTeams();
  }

  return (
    <div className={styles.root} data-testid='teams-portal-layout'>
      <SharedLayout includeBackground />

      <div className={styles.header}>
        <PageHeader
          currentTeam={currentTeam}
          setCurrentTeam={handleCurrentTeamChange}
          teams={teams.data}
          variant={isWelcomeView ? PageHeaderVariant.Welcome : PageHeaderVariant.Default}
        />
      </div>

      <div className={styles.content} data-testid='teams-portal-content'>
        {isInitLoading || (isLoadingSpecificTeam && !isWelcomeView) ? <LoadingBar /> : <Outlet />}
      </div>

    </div>
  );
};
