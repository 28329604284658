/* eslint-disable react/no-unstable-nested-components */
import { FC } from 'react';
import { Tooltip, XAxis, YAxis, ResponsiveContainer, Line, LineChart, DotProps, CartesianGrid, ReferenceLine, ReferenceLineProps } from 'recharts';
import { ActiveShape } from 'recharts/types/util/types';

import { CustomDot, CustomDotProps } from '../components/CustomDot/CustomDot';
import { GraphTooltip } from '../components/GraphTooltip/GraphTooltip';
import { commonAxisProps } from '../constants';

import colors from 'themes/colors.module.scss';

type Point = {
  label: string;
  value: number | null;
};

type GradientColor = {
  color: string;
  offset: string;
};

type Props = {
  data: Point[];
  gradientColors?: GradientColor[];
  customXTickFormatter?: (value: string, index: number) => string;
  singleColor?: string | null;
  CustomTooltip?: FC<{ label?: string; value?: number }>;
  isValuePercentage?: boolean;
  referenceLineProps?: ReferenceLineProps;
  yAxisDomain?: number[] | null;
};

const defaultChartProps = {
  overflow: 'visible',
};

const gradientId = 'lineGradient';

export const JitLinearGradientChart: FC<Props> = ({
  data,
  gradientColors,
  customXTickFormatter,
  singleColor,
  CustomTooltip,
  isValuePercentage,
  referenceLineProps,
  yAxisDomain,
}) => (
  <ResponsiveContainer height={180} width='100%'>
    <LineChart
      data={data}
      {...defaultChartProps}
    >
      <defs>
        <linearGradient id={gradientId} x1='0' x2='0' y1='0' y2='1'>
          {gradientColors?.map((stopColor) => (
            <stop key={stopColor.offset} offset={stopColor.offset} stopColor={stopColor.color} />
          ))}
        </linearGradient>
      </defs>

      <CartesianGrid
        horizontal
        stroke={colors.cardsDivider}
        strokeWidth={1}
        vertical={false}
      />

      {referenceLineProps && (
        <ReferenceLine
          label={referenceLineProps.label}
          stroke={referenceLineProps.stroke}
          strokeDasharray={referenceLineProps.strokeDasharray}
          y={referenceLineProps.y}
        />
      )}

      <XAxis
        dataKey='label'
        dy={10}
        tickFormatter={customXTickFormatter}
        {...commonAxisProps}
      />

      <YAxis
        width={20}
        {...(yAxisDomain === null ? {} : { domain: yAxisDomain || [0, 100] })}
        {...commonAxisProps}
      />

      {
      CustomTooltip && (
      <Tooltip
        content={({ active, payload }) => {
          const payloadData = payload?.[0]?.payload;

          return (
            <GraphTooltip active={active} payload={payload}>
              <CustomTooltip {...payloadData} isPercentage={isValuePercentage} />
            </GraphTooltip>
          );
        }}
        cursor={false}
        wrapperStyle={{ outline: 'none' }}
      />
      )
      }

      <Line
        activeDot={((props: CustomDotProps) => <CustomDot {...props} singleColor={singleColor} />) as ActiveShape<DotProps>}
        dataKey='value'
        dot={false}
        isAnimationActive={data.length > 1}
        stroke={singleColor || `url(#${gradientId})`}
        strokeWidth={3}
        type='monotone'
      />
    </LineChart>
  </ResponsiveContainer>
);
