import { FC } from 'react';

import styles from './VerifyPermissionsStep.module.scss';

import { GitlabIcon } from 'assets';
import { JitButton } from 'components/JitButton/JitButton';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitText } from 'components/JitText/JitText';
import colors from 'themes/colors.module.scss';

interface Props {
  onContinueButtonClick: () => void;
  onClose: () => void;
}

export const VerifyPermissionsStep: FC<Props> = ({ onContinueButtonClick, onClose }) => (
  <DialogContentWrapper
    actionButtons={[{
      label: 'GitlabIntegrationWizard.verifyPermissionsStep.confirmButton',
      handleClick: onContinueButtonClick,
    }]}
    icon={GitlabIcon}
    leftButtonsElement={(
      <JitButton
        data-testid='btn-insufficient-permissions'
        onClick={onClose}
        variant='text'
      >
        <JitText color={colors.iris} text='GitlabIntegrationWizard.verifyPermissionsStep.insufficientPermissions' />
      </JitButton>
      )}
  >
    <div className={styles.content}>
      <JitText bold size='l' text='GitlabIntegrationWizard.verifyPermissionsStep.title' />

      <div className={styles.description}>
        <JitText
          components={{
            paidAccount: <JitText bold display='inline' text='a paid GitLab account' />,
            groupOwner: <JitText bold display='inline' text='Group Owner permissions' />,
          }}
          display='inline'
          muted
          text='GitlabIntegrationWizard.verifyPermissionsStep.description'
        />
      </div>
    </div>
  </DialogContentWrapper>
);
