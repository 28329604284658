import { useCallback, useMemo, useState } from 'react';

import { IGitlabIntegrationWizardStep, IGitlabIntegrationWizardStepStatus } from '../GitlabIntegrationWizard';
import { VerifyPermissionsStep } from '../StepComponents/VerifyPermissionsStep/VerifyPermissionsStep';

interface Props {
  incrementStepIndex: () => void;
  handleClose: () => void;
}

export const useVerifyPermissionsStep = ({
  incrementStepIndex,
  handleClose,
}: Props): IGitlabIntegrationWizardStep => {
  const [isStepCompleted, setIsStepCompleted] = useState<boolean>(false);

  const onContinueButtonClick = useCallback(() => {
    setIsStepCompleted(true);
    incrementStepIndex();
  }, [incrementStepIndex]);

  const status = useMemo(() => (isStepCompleted
    ? IGitlabIntegrationWizardStepStatus.COMPLETE
    : IGitlabIntegrationWizardStepStatus.ACTIVE), [isStepCompleted]);

  return useMemo(() => ({
    title: 'GitlabIntegrationWizard.verifyPermissionsStep.tabText',
    component: () => (
      <VerifyPermissionsStep
        onClose={handleClose}
        onContinueButtonClick={onContinueButtonClick}
      />
    ),
    status,
  }), [onContinueButtonClick, handleClose, status]);
};
