import { CustomerPortal, CustomerPortalSubscription, OnCheckoutCompletedParams } from '@stigg/react-sdk';
import { FC, useState } from 'react';

import styles from './Billing.module.scss';

import { StiggCheckout } from 'components/Stigg/Checkout/StiggCheckout';
import { useSendAnalyticsEvent } from 'context/AnalyticsContext';
import { logError } from 'services/logger/logger';

export const Billing: FC = () => {
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const onCheckoutCompleted = async (params: OnCheckoutCompletedParams) => {
    if (params.success) {
      sendAnalyticsEvent({
        action: 'subscription-updated',
        params: {
          status: 'success',
          plan_id: selectedPlanId || 'selectedPlanId is null',
        },
      });
      setSelectedPlanId(null);
    } else {
      sendAnalyticsEvent({
        action: 'subscription-updated',
        params: {
          status: 'failed',
          plan_id: selectedPlanId || 'selectedPlanId is null',
          error: params.error || 'params.error is undefined',
        },
      });
      logError('Checkout failed from billing page', params);
    }
  };

  const handleManageSubscription = ({ customerSubscriptions }: { customerSubscriptions: CustomerPortalSubscription[] }) => {
    const [firstSubscription] = customerSubscriptions;
    sendAnalyticsEvent({
      action: 'subscription-management-accessed',
      params: {
        current_plan: firstSubscription.planId,
      },
    });
    setSelectedPlanId(firstSubscription.planId);
  };

  return (
    <div className={styles.container}>
      {selectedPlanId ? (
        <StiggCheckout
          onCheckoutCompleted={onCheckoutCompleted}
          planId={selectedPlanId}
        />
      ) : (
        <CustomerPortal
          onManageSubscription={handleManageSubscription}
        />
      )}
    </div>
  );
};

