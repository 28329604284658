import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { Id } from 'react-toastify';

import { useHandleIgnoreRulesWebSocketNotification } from './handleIgnoreRulesWebSocketNotification';

import { useWebsocketSubscribe } from 'context/WebSocketContext/hooks';
import { constants } from 'globalConstants';
import { createIgnoreRule, deleteIgnoreRule } from 'services/FindingsService/FindingsService';
import { WebSocketNotificationTopics } from 'types/enums';
import { IgnoreReason } from 'types/enums/IgnoreReason';
import { IgnoreRequestSource, IgnoreRuleType, OperatorTypes } from 'types/enums/IgnoreRules';
import { CreateIgnoreRuleRequest, IBaseIgnoreRule, IFinding } from 'types/interfaces';
import { useSnackBar } from 'utils/hooks/useSnackBar';

export type IgnoreState = {
  isIgnoreLoading: boolean;
  inProgressNotificationId: Id | null;
  findingsWaitingForIgnoreRules: IFinding[];
  findingsIgnoredCount: number;
};

const initialIgnoreState: IgnoreState = {
  isIgnoreLoading: false,
  inProgressNotificationId: null,
  findingsWaitingForIgnoreRules: [],
  findingsIgnoredCount: 0,
};

export const useUpdateFindingsStatus = (reFetchCurrentPage: () => Promise<void>, findings: IFinding[], getTotalFindingsAmount: () => Promise<void>) => {
  const [findingsToIgnore, setFindingsToIgnore] = useState<IFinding[]>([]);
  const [ignoreState, setIgnoreState] = useState(initialIgnoreState);
  const { showSnackBar, updateSnackBar } = useSnackBar();
  const { time: { MINUTE } } = constants;

  const reloadPage = useCallback(async () => {
    await Promise.all([
      reFetchCurrentPage(),
      getTotalFindingsAmount(),
    ]);
  }, [reFetchCurrentPage, getTotalFindingsAmount]);

  const onIgnoreRuleSuccess = useCallback(() => {
    reloadPage();
    if (ignoreState.inProgressNotificationId) {
      updateSnackBar({
        notificationId: ignoreState.inProgressNotificationId,
        title: t('pages.findings.notifications.ignoreFindingSuccess.title'),
        description: t('pages.findings.notifications.ignoreFindingSuccess.description', {
          count: ignoreState.findingsIgnoredCount,
        }),
        type: 'success',
      });
    }
    setIgnoreState(initialIgnoreState);
  }, [reloadPage, ignoreState.inProgressNotificationId, ignoreState.findingsIgnoredCount, updateSnackBar]);

  const onIgnoreRuleFailure = useCallback((reason: string) => {
    if (ignoreState.inProgressNotificationId) {
      updateSnackBar({
        notificationId: ignoreState.inProgressNotificationId,
        title: t('pages.findings.notifications.ignoreFindingFailed.title'),
        description: t('pages.findings.notifications.ignoreFindingFailed.description', {
          reason,
        }),
        type: 'error',
      });
    }
    setIgnoreState(initialIgnoreState);
  }, [ignoreState.inProgressNotificationId, updateSnackBar, setIgnoreState]);

  const { websocketSubscribe } = useWebsocketSubscribe();
  const { handleIgnoreRulesWebSocketNotification } = useHandleIgnoreRulesWebSocketNotification({
    setIgnoreState,
    onIgnoreRuleSuccess,
    findingsIgnoredCount: ignoreState.findingsIgnoredCount,
    onIgnoreRuleFailure,
  });

  useEffect(() => {
    websocketSubscribe(WebSocketNotificationTopics.IgnoreRules, handleIgnoreRulesWebSocketNotification);
  }, [handleIgnoreRulesWebSocketNotification, websocketSubscribe]);

  const ignoreFindings = useCallback(async (ignoreRulesToCreate: CreateIgnoreRuleRequest[]): Promise<IBaseIgnoreRule[]> => {
    const responses: Promise<IBaseIgnoreRule | undefined>[] = ignoreRulesToCreate.map((ignoreRulePayload) => createIgnoreRule(ignoreRulePayload));
    const findingsResponses: (IBaseIgnoreRule | undefined)[] = await Promise.all(responses);
    return findingsResponses.filter((findingsResponse) => findingsResponse !== undefined) as IBaseIgnoreRule[];
  }, []);

  const undoIgnoreFindings = useCallback(async (findingsToUndoIgnore: IFinding[]) => {
    const ignoreRulesToUndo = findingsToUndoIgnore.map((finding) => finding.ignoreRulesIds).filter((item) => item !== undefined).flat() as string[];
    const responses: Promise<undefined>[] = ignoreRulesToUndo.map((ignoreRuleId) => deleteIgnoreRule(ignoreRuleId));
    await Promise.all(responses);
  }, []);

  const createIgnoreRuleByType = (finding: IFinding, reason: string, description: string) => ({
    type: IgnoreRuleType.IGNORE,
    reason,
    comment: description,
    source: IgnoreRequestSource.BACKLOG,
    fields: [
      {
        name: 'test_id',
        value: finding.testId,
        operator: OperatorTypes.EQUAL,
      },
    ],
  });

  const createIgnoreRuleByDetails = (finding: IFinding, reason: string, description: string) => ({
    type: IgnoreRuleType.IGNORE,
    reason,
    comment: description,
    source: IgnoreRequestSource.BACKLOG,
    fields: [
      {
        name: 'fingerprint',
        value: finding.fingerprint,
        operator: OperatorTypes.EQUAL,
      },
      {
        name: 'control_name',
        value: finding.controlName,
        operator: OperatorTypes.EQUAL,
      },
      {
        name: 'asset_id',
        value: finding.assetId,
        operator: OperatorTypes.EQUAL,
      },
    ],
  });

  const displayInProgressNotification = useCallback(() => {
    const notificationId = showSnackBar({
      title: t('pages.findings.notifications.ignoreFindingsInProgress.title'),
      description: t('pages.findings.notifications.ignoreFindingsInProgress.description', {
        count: findingsToIgnore.length,
      }),
      type: 'loading',
      options: { autoClose: MINUTE },
    });
    setIgnoreState((prevState) => ({
      ...prevState,
      inProgressNotificationId: notificationId,
    }));
  }, [MINUTE, findingsToIgnore.length, showSnackBar]);

  const completeIgnoreFindings = useCallback(async (reason: IgnoreReason, description: string, ignoreByType?: boolean) => {
    setIgnoreState((prevState) => ({
      ...prevState,
      isIgnoreLoading: true,
      findingsWaitingForIgnoreRules: findingsToIgnore,
      findingsIgnoredCount: findingsToIgnore.length,
    }));
    const ignoredRulesToCreate: CreateIgnoreRuleRequest[] = findingsToIgnore.map((finding) => (
      ignoreByType
        ? createIgnoreRuleByType(finding, reason, description)
        : createIgnoreRuleByDetails(finding, reason, description)
    ));
    await ignoreFindings(ignoredRulesToCreate);
    setIgnoreState((prevState) => ({
      ...prevState,
      isIgnoreLoading: false,
    }));
    setFindingsToIgnore([]);
    displayInProgressNotification();
  }, [findingsToIgnore, ignoreFindings, displayInProgressNotification]);

  const updateMultipleFindingsStatus = useCallback(async (selectedFindingIds: string[], ignored: boolean) => {
    const findingsToUpdate = findings.filter((finding) => selectedFindingIds.includes(finding.id) && finding.ignored !== ignored);
    if (ignored) {
      setFindingsToIgnore(findingsToUpdate);
    }
    if (!ignored) {
      await undoIgnoreFindings(findingsToUpdate);
      await reloadPage();
    }
  }, [findings, undoIgnoreFindings, reloadPage]);

  const updateFindingIgnoredStatus = useCallback(async (findingToUpdate: IFinding, ignored: boolean) => {
    if (findingToUpdate.ignored !== ignored) {
      if (ignored) {
        setFindingsToIgnore([findingToUpdate]);
      }
      if (!ignored) {
        await undoIgnoreFindings([findingToUpdate]);
        await reloadPage();
      }
    }
    return findings;
  }, [findings, undoIgnoreFindings, reloadPage]);

  return {
    updateMultipleFindingsStatus,
    updateFindingStatus: updateFindingIgnoredStatus,
    findingsToIgnore,
    setFindingsToIgnore,
    completeIgnoreFindings,
    isIgnoreLoading: ignoreState.isIgnoreLoading,
  };
};
