import { useCallback, useMemo } from 'react';

import { calculateGradientStops, sliceGradientArray } from './utils';

import { DayScore, ScoreTrendMetric } from 'types/interfaces/Metrics/TeamsPortalMetrics/ScoreTrending';
import { getColorByScore } from 'utils/functions/grades';

const MAX_TICKS = 6;

export const useOrgScoreWidget = (data?: ScoreTrendMetric) => {
  const getMonthAndDateValue = (dateString: string) => {
    const dateObj = new Date(dateString);
    const monthStart = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1);
    const dateValue = monthStart.getTime();
    const monthKey = `${dateObj.toLocaleString('default', { month: 'short' })} ${String(dateObj.getFullYear())}`;

    return {
      monthKey,
      dateValue,
    };
  };

  const processScoresByMonth = useCallback((scoresByDay: DayScore[]) => {
    const monthlyScores: { [key: string]: { scores: number[], dateValue: number } } = {};

    scoresByDay.forEach(({ date, score }) => {
      const { monthKey, dateValue } = getMonthAndDateValue(date);

      if (!monthlyScores[monthKey]) {
        monthlyScores[monthKey] = { scores: [],
          dateValue };
      }

      if (score !== null) {
        monthlyScores[monthKey].scores.push(score);
      }
    });

    return monthlyScores;
  }, []);

  const calculateMonthlyAverages = (monthlyScores: { [key: string]: { scores: number[], dateValue: number } }) => Object.entries(monthlyScores).map(([monthKey, { scores, dateValue }]) => ({
    label: monthKey,
    value: scores.length ? Math.round(scores.reduce((sum, score) => sum + score, 0) / scores.length) : 0,
    dateValue,
  })).sort((a, b) => a.dateValue - b.dateValue);

  const monthlyScoreData = useMemo(() => {
    if (!data?.entitiesScores?.length) return [];

    const { scoresByDay } = data.entitiesScores[0];
    const monthlyScores = processScoresByMonth(scoresByDay);

    return calculateMonthlyAverages(monthlyScores);
  }, [data?.entitiesScores, processScoresByMonth]);

  const customXTickFormatter = useCallback((value: string, index: number) => {
    const interval = Math.ceil(monthlyScoreData.length / MAX_TICKS);
    const formattedDate = value.split(' ')[0];

    return index % interval === 0 ? formattedDate : '';
  }, [monthlyScoreData.length]);

  const gradientColors = useMemo(() => {
    const scores = monthlyScoreData.map((month) => month.value);
    if (scores.length === 0) return [];

    const gradientValues = calculateGradientStops(scores);
    return sliceGradientArray(scores, gradientValues);
  }, [monthlyScoreData]);

  const sameScoreColor = useMemo(() => {
    if (monthlyScoreData.length === 0) return null;
    const isAllDatesHaveSameScore = monthlyScoreData.every((month, index, array) => month.value === array[0].value);

    return isAllDatesHaveSameScore ? getColorByScore(monthlyScoreData[0].value) : null;
  }, [monthlyScoreData]);

  return {
    monthlyScoreData,
    customXTickFormatter,
    gradientColors,
    sameScoreColor,
  };
};
