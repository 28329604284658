import React from 'react';

import styles from './ErrorIconCell.module.scss';

import { ErrorDot } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { ITableCell } from 'types/interfaces';

const trimText = (text: string, maxLength: number) => (text.length > maxLength ? `${text.slice(0, maxLength)}...` : text);

export const ErrorIconCell: React.FC<ITableCell> = ({ cell: { value } }) => (
  value ? (
    <JitTooltip followCursor={false} offset={[0, 2]} placement='left' title={trimText(value, 100)}>
      <JitIcon icon={ErrorDot} iconClassName={styles.wrapper} size={24} />
    </JitTooltip>
  ) : <div />
);
