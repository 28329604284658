import { t } from 'i18next';
import { FC, useMemo, useCallback, useEffect, useState } from 'react';

import { DEFAULT_CONDITION } from '../../../../../constants';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { IConditionStep } from 'types/interfaces/Workflows/IWorkflow';

type CondtionOption = { type: string; label: string };

interface ConditionTypeSelectorProps {
  conditionOptions: Array<CondtionOption>;
  selectedStep: IConditionStep;
}

/**
 * Renders a menu for selecting the condition type if multiple options exist.
 */
export const ConditionTypeSelector: FC<ConditionTypeSelectorProps> = ({
  conditionOptions,
  selectedStep,
}) => {
  const {
    setSelectedStepId,
    setWorkflow,
    selectedStepId,
  } = useEditWorkflowContext();

  const preSelectedConditionType = useMemo(
    () => conditionOptions.find((option) => option.type === selectedStep.type),
    [conditionOptions, selectedStep.type],
  );

  const [selectConditionType, setSelectConditionType] = useState<CondtionOption | undefined>(preSelectedConditionType);

  useEffect(() => {
    setSelectConditionType(preSelectedConditionType);
  }, [preSelectedConditionType]);

  const onSelectConditionOptions = useCallback(
    (item: CondtionOption) => {
      setSelectConditionType(item);
      setWorkflow((prevWorkflow) => ({
        ...prevWorkflow,
        steps: prevWorkflow.steps.map((step) => (step.id === selectedStepId
          ? {
            ...step,
            type: item.type,
            name: item.label,
            config: {
              conditions: [DEFAULT_CONDITION],
              isInEditMode: true,
            },
          }
          : step)),
      }));
      setSelectedStepId(selectedStepId);
    },
    [selectedStepId, setSelectedStepId, setWorkflow],
  );

  // If there's only one condition option and step has no type, auto-select it
  useEffect(() => {
    if (conditionOptions.length === 1 && !selectedStep.type) {
      onSelectConditionOptions(conditionOptions[0]);
    }
  }, [conditionOptions, onSelectConditionOptions, selectedStep.type]);

  if (conditionOptions.length <= 1) {
    // If only one condition option or none, skip rendering the menu
    return null;
  }

  return (
    <div>
      <JitAutoCompleteForm
        autoFocus={!selectConditionType}
        clearOnBlur={false}
        fitContent
        fontSize={14}
        getOptionKey={(option) => option.type}
        getOptionLabel={(option) => option.label}
        isSingleValue
        options={conditionOptions}
        placeHolder={t`pages.workflows.editWorkflowPage.stepDetails.condition.addCondition`}
        selectedValue={selectConditionType}
        setSelectedValue={onSelectConditionOptions}
        showArrow={false}
        variant='light'
        width={150}
      />
    </div>
  );
};
