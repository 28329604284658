import { t } from 'i18next';
import debounce from 'lodash/debounce';
import { FC, UIEvent, useCallback, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import styles from './ChooseGroup.module.scss';
import { getSubGroupPath } from './utils';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { calcShouldFetchMore } from 'components/JitTable/utils';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useTenantContext } from 'context/TenantContext';
import { IGroup, useGitlabService } from 'services/GitlabService/useGitlabService';
import colors from 'themes/colors.module.scss';
import { Queries } from 'types/enums/Queries';

interface Props {
  minAccessLevel: number;
  groupId: string | undefined;
  setSelectedSubgroup: (group: IGroup) => void;
  selectedSubgroup?: IGroup;
}

const PAGE_LIMIT = 100;
const SUBGROUP_SEARCH_BOX_PLACEHOLDER = t('GitlabIntegrationWizard.chooseGitlabGroup.subGroups.dropdown.placeholder');

export const SubgroupsDropdown: FC<Props> = ({ minAccessLevel, groupId, setSelectedSubgroup, selectedSubgroup }) => {
  const [subgroupsSearchTerm, setSubgroupsSearchTerm] = useState<string>();
  const { fetchSubgroups } = useGitlabService();
  const { gitlabInstallation } = useTenantContext();

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading, remove } = useInfiniteQuery(
    [Queries.GitlabSubgroups, groupId, subgroupsSearchTerm],
    ({ pageParam = 1 }) => fetchSubgroups(groupId!, {
      per_page: PAGE_LIMIT,
      page: pageParam,
      search: subgroupsSearchTerm,
      min_access_level: minAccessLevel,
    }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage || lastPage.length < PAGE_LIMIT) return undefined;
        return pages.length + 1;
      },
      enabled: !!groupId && !gitlabInstallation?.centralized_repo_asset,
    },
  );

  const subgroups = data?.pages.reduce((acc, currPage) => acc?.concat(currPage || []), []) || [];

  const onScroll = useCallback((e: UIEvent<HTMLUListElement>) => {
    if (hasNextPage && !isFetchingNextPage && calcShouldFetchMore(e, 20)) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const debouncedInputChange = debounce((value: string) => {
    if (selectedSubgroup?.name !== value) {
      setSubgroupsSearchTerm(value);
      remove();
    }
  }, 300);

  const tooltipText = t('GitlabIntegrationWizard.chooseGitlabGroup.subGroups.dropdown.tooltip');

  return (
    <div className={styles.subgroupsDropdown}>

      <JitText color={colors.lightGray} size='m' text='GitlabIntegrationWizard.chooseGitlabGroup.subGroups.title' />

      <JitTooltip followCursor={false} placement='top' title={!groupId ? tooltipText : ''}>
        <JitAutoCompleteForm
          clearOnBlur={false}
          disableCloseOnSelect
          disabled={!groupId || !!gitlabInstallation?.centralized_repo_asset}
          fontSize={14}
          getOptionKey={(subgroup: IGroup) => subgroup.id}
          getOptionLabel={(subgroup: IGroup) => getSubGroupPath(subgroup.fullPath)}
          isLoading={isLoading || isFetchingNextPage}
          isSingleValue
          onInputChange={debouncedInputChange}
          onScroll={onScroll}
          options={subgroups}
          placeHolder={SUBGROUP_SEARCH_BOX_PLACEHOLDER}
          selectedValue={selectedSubgroup}
          setSelectedValue={setSelectedSubgroup}
        />
      </JitTooltip>
    </div>
  );
};
