import { useCallback, useMemo } from 'react';

import { DateTimeCell } from 'components/JitTable/templates/DateTimeCell/DateTimeCell';
import { ErrorIconCell } from 'components/JitTable/templates/ErrorIconCell/ErrorIconCell';
import { StatusCellV2 } from 'components/JitTable/templates/StatusCell/StatusCellV2';
import { TextCell } from 'components/JitTable/templates/TextCell/TextCell';
import { i18n } from 'locale/i18n';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';
import {
  IWorkflowRun,
} from 'types/interfaces/Workflows/IWorkflowRun';
import { TableColumn } from 'types/types';
import { formatDuration } from 'utils';

interface IColumnHeaders {
  startedAt: string;
  status: string;
  duration: string;
}

export const useGetColumns = (workflow?: IWorkflow) => {
  const { t } = i18n;
  const columnHeaders: IColumnHeaders = useMemo(() => t('pages.workflows.editWorkflowPage.historyView.table.columns', { returnObjects: true }), [t]);

  const getStepNameById = useCallback((stepId: string) => workflow?.steps.find((step) => step.id === stepId)?.name || stepId, [workflow]);

  const getDurationMS = (workflowRun: IWorkflowRun) => {
    if (!workflowRun.completedAt) {
      return null;
    }
    const startedAt = new Date(workflowRun.startedAt).getTime();
    const completedAt = new Date(workflowRun.completedAt).getTime();
    return completedAt - startedAt;
  };

  const columnsData = useMemo(() => {
    const columns = [
      {
        Header: columnHeaders.startedAt,
        accessor: (workflowRun: IWorkflowRun) => ({
          dateTime: workflowRun.startedAt,
          showTime: true,
        }),
        Cell: DateTimeCell,
        width: '30%',
      },
      {
        Header: columnHeaders.status,
        accessor: 'status',
        Cell: StatusCellV2,
        width: '20%',
      },
      {
        Header: columnHeaders.duration,
        accessor: (workflowRun: IWorkflowRun) => {
          const duration = getDurationMS(workflowRun);
          return (
            duration && formatDuration(duration, 4)
          );
        },
        Cell: TextCell,
        width: '45%',
      },
      {
        id: 'errorMessage',
        accessor: (workflowRun: IWorkflowRun) => (
          Object.entries(workflowRun.workflowSteps)
            .filter(([, step]) => step.status === 'failure' && step.message)
            .map(([stepId, step]) => `${getStepNameById(stepId)}: ${step.message}`)
            .join('\n')
        ),
        Cell: ErrorIconCell,
        width: '5%',
      },
    ];
    return columns;
  }, [columnHeaders, getStepNameById]);

  return {
    columns: columnsData as TableColumn<object>[],
  };
};
