import { useState, useCallback } from 'react';

import { useOptionsQueries, CallbackProps } from './useOptionsQueries';

import { CallbackKey } from 'pages/PoliciesPage/types';

export const useRuleCallbacks = () => {
  const [activeCallbacks, setActiveCallbacks] = useState<Record<CallbackKey, boolean>>({} as Record<CallbackKey, boolean>);

  const { optionsQueries } = useOptionsQueries(activeCallbacks);

  const getCallbackProps = useCallback((callback: CallbackKey): CallbackProps => optionsQueries[callback] || {
    options: [],
    isLoading: false,
  }, [optionsQueries]);

  const activateCallback = useCallback((optionsCallback: CallbackKey) => {
    if (optionsCallback && !activeCallbacks[optionsCallback]) {
      setActiveCallbacks((prev) => ({
        ...prev,
        [optionsCallback]: true,
      }));
    }
  }, [activeCallbacks]);

  return {
    getCallbackProps,
    activateCallback,
  };
};
