import { ErrorBoundary } from '@sentry/react';
import { FC, PropsWithChildren } from 'react';

import { GeneralErrorPage } from 'components/GeneralErrorPage/GeneralErrorPage';

export const Fallback = () => (
  <GeneralErrorPage
    actionButtons={[{ label: 'pages.generalError.refresh',
      handleClick: () => { window.location.reload(); } }]}
    description='pages.generalError.description'
    title='pages.generalError.title'
  />
);

// eslint-disable-next-line react/no-multi-comp
export const AppErrorBoundary: FC<PropsWithChildren> = ({ children }) => (
  <ErrorBoundary fallback={Fallback}>
    {children}
  </ErrorBoundary>

);
