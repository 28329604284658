import { FC } from 'react';

import { SnackBarContainer } from 'components/JitSnackBar/SnackBarContainer';
import { Background } from 'components/Pages/Background/Background';
import { AllToasts } from 'wrappers/AllToasts/AllToasts';

interface SharedLayoutProps {
  displayToastsTopScreen?: boolean;
  includeBackground?: boolean;
}

export const SharedLayout: FC<SharedLayoutProps> = ({ displayToastsTopScreen = false, includeBackground = false }) => (
  <>
    <SnackBarContainer />

    <AllToasts displayTopScreen={displayToastsTopScreen} />

    {includeBackground && <Background />}
  </>
);
