import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ChooseIntegrationMethod.module.scss';

import { GitlabIcon } from 'assets';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';

export type IntegrationRole = 'maintainer' | 'owner';

interface Props {
  selectedRole: IntegrationRole;
  setSelectedRole: (role: IntegrationRole) => void;
  onContinueButtonClick: () => void;
}

const buttonConfigs = [
  {
    id: 'owner',
    title: 'GitlabIntegrationWizard.chooseIntegrationMethod.fastest.title',
    subtitle: 'GitlabIntegrationWizard.chooseIntegrationMethod.fastest.description',
  },
  {
    id: 'maintainer',
    title: 'GitlabIntegrationWizard.chooseIntegrationMethod.fast.title',
    subtitle: 'GitlabIntegrationWizard.chooseIntegrationMethod.fast.description',
  },
];

export const ChooseIntegrationMethod: FC<Props> = ({
  selectedRole,
  setSelectedRole,
  onContinueButtonClick,
}) => {
  const { t } = useTranslation();

  const getButtonStyles = (buttonId: string) => ({
    filter: selectedRole === buttonId ? 'grayscale(0%)' : 'grayscale(100%)',
    transition: 'filter 0.25s',
    '&:hover': {
      filter: 'grayscale(0%)',
    },
  });

  return (
    <DialogContentWrapper
      actionButtons={[{
        label: 'GitlabIntegrationWizard.chooseIntegrationMethod.nextButton',
        disabled: !selectedRole,
        handleClick: onContinueButtonClick,
      }]}
      icon={GitlabIcon}
      leftButtonsElement={(
        <JitExternalLink
          href={i18n.t('GitlabIntegrationWizard.chooseIntegrationMethod.docsReference.link')}
          text='GitlabIntegrationWizard.chooseIntegrationMethod.docsReference.text'
        />
        )}
    >
      <div className={styles.content}>
        <JitText
          bold
          size='l'
          text='GitlabIntegrationWizard.chooseIntegrationMethod.title'
        />

        <div className={styles.buttonsContainer}>
          {buttonConfigs.map((button) => (
            <JitGradientButton
              key={button.id}
              onClick={() => setSelectedRole(button.id as IntegrationRole)}
              sx={getButtonStyles(button.id)}
            >
              <div className={styles.buttonContent}>
                <JitText
                  bold
                  size='l'
                  text={t(button.title)}
                />

                <JitText
                  muted
                  size='m'
                  text={t(button.subtitle)}
                />
              </div>
            </JitGradientButton>
          ))}
        </div>
      </div>
    </DialogContentWrapper>
  );
};
