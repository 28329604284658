import { useCallback, useState } from 'react';

import { useGitlabService } from 'services/GitlabService/useGitlabService';

export const useCompleteOnboardingStep = () => {
  const { completeOnboarding } = useGitlabService();
  const [isCompleteOnboardingLoading, setIsCompleteOnboardingLoading] = useState(false);
  const [hasCompleteOnboardingPressedAndFinished, setHasCompleteOnboardingPressedAndFinished] = useState(false);

  const onCompleteOnboardingButtonClick = useCallback(async () => {
    setIsCompleteOnboardingLoading(true);

    const response = await completeOnboarding();
    if (response?.status !== 200) {
      setIsCompleteOnboardingLoading(false);
      return;
    }

    setIsCompleteOnboardingLoading(false);
    // We don't want to allow user to press it more than once
    setHasCompleteOnboardingPressedAndFinished(true);
  }, [completeOnboarding]);

  return {
    isCompleteOnboardingLoading,
    onCompleteOnboardingButtonClick,
    hasCompleteOnboardingPressedAndFinished,
  };
};
