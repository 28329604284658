import { useCallback } from 'react';

import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { i18n } from 'locale/i18n';
import { WebSocketNotificationType } from 'types/enums';
import { IWebsocketNotification, IWebsocketNotificationSystemMessage } from 'types/interfaces';
import { ITeam } from 'types/interfaces/Teams/ITeam';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';
import { useSnackBar } from 'utils/hooks/useSnackBar';

type TeamWebsocketNotification = IWebsocketNotification<ITeam>
| IWebsocketNotificationSystemMessage<IResourceAttachmentFailedMessage>;

interface IResourceAttachmentFailedMessage {
  type: string;
  teamName: string;
  teamId: string;
  failedResources: {
    vendor: string;
    type: string;
    name: string;
    reason: string;
  }[];
}

const NOTIFICATION_TYPE = 'import_resource_failed';

export const useHandleImportTeamsWebSocketNotifications = () => {
  const { showSnackBar } = useSnackBar();
  const { setIsUploadingTeams } = useTeamsContext();

  const handleResourceAttachmentFailed = useCallback(
    (message: IResourceAttachmentFailedMessage) => {
      const failedVendors = message.failedResources.map((resource) => resource.vendor);
      const uniqueVendors = [...new Set(failedVendors)];

      showSnackBar({
        title: 'pages.teams.teamsTable.importTeams.resource.error.title',
        description: i18n.t('pages.teams.teamsTable.importTeams.resource.error.description', {
          teamName: message.teamName,
          vendors: uniqueVendors.join(', '),
        }),
        type: 'error',
      });
      setIsUploadingTeams(false);
    },
    [showSnackBar, setIsUploadingTeams],
  );

  const handleImportTeamsWebSocketNotification = (notification: TeamWebsocketNotification) => {
    const camelizedNotification = camelizeSnakeCaseKeys(notification) as TeamWebsocketNotification;
    if (camelizedNotification.type === WebSocketNotificationType.systemMessage) {
      const systemNotification = camelizedNotification as IWebsocketNotificationSystemMessage<IResourceAttachmentFailedMessage>;
      if (systemNotification.message.type === NOTIFICATION_TYPE) {
        handleResourceAttachmentFailed(systemNotification.message);
      }
    }
  };

  return { handleImportTeamsWebSocketNotification };
};
