import { Dispatch, FC, SetStateAction } from 'react';

import { StyledToggleButton, StyledToggleButtonGroup } from './StyledToggleGroup';
import styles from './ViewToggle.module.scss';

import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';

const viewOptions = ['diagram', 'history'];

export type WorkflowView = typeof viewOptions[number];

interface ViewToggleProps {
  view: WorkflowView;
  setView: Dispatch<SetStateAction<WorkflowView>>;
  isNewWorkflow: boolean;
}

export const ViewToggle: FC<ViewToggleProps> = ({ view, setView, isNewWorkflow }) => (
  <StyledToggleButtonGroup
    className={styles.wrapper}
    data-testid='view-toggle'
    exclusive
    onChange={(_, newView) => newView && setView(newView)}
    value={view}
  >
    <StyledToggleButton data-testid='view-toggle-diagram' value='diagram'>
      <JitText className={styles.buttonText} text='pages.workflows.editWorkflowPage.views.diagram' />
    </StyledToggleButton>

    <JitTooltip data-testid='disabled-history-tooltip' followCursor={false} placement='bottom' title={isNewWorkflow ? 'pages.workflows.editWorkflowPage.historyView.disabled' : undefined}>
      <StyledToggleButton data-testid='view-toggle-history' disabled={isNewWorkflow} value='history'>
        <JitText className={styles.buttonText} color={isNewWorkflow ? '#FFFFFF61' : undefined} text='pages.workflows.editWorkflowPage.views.history' />
      </StyledToggleButton>
    </JitTooltip>
  </StyledToggleButtonGroup>
);
