import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { DataType, IPolicyRule, IPolicyTemplate } from '../../types';
import { AddNewRule } from '../AddNewRule/AddNewRule';
import { PolicyRule } from '../PolicyRule/PolicyRule';

import styles from './Policy.module.scss';

import { JitChip } from 'components/JitChip/JitChip';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { PRODUCT_CATALOG_FEATURES } from 'context/StiggContext/constants';
import { useGetBooleanEntitlement } from 'context/StiggContext/useGetBooleanEntitlement';
import { i18n } from 'locale/i18n';
import { getBaseConditionRootFields } from 'pages/PoliciesPage/utils';
import colors from 'themes/colors.module.scss';
import { PERMISSIONS } from 'wrappers/RBAC/constants';

interface Props {
  policyTemplate: IPolicyTemplate;
  rulesData: IPolicyRule[];
}

export const Policy: FC<Props> = ({ policyTemplate, rulesData }) => {
  const [pendingRule, setPendingRule] = useState<IPolicyRule | null>(null);
  const getBooleanEntitlement = useGetBooleanEntitlement();
  const { hasPermission } = useAuthContext();

  const isEntitled = useMemo(
    () => getBooleanEntitlement(PRODUCT_CATALOG_FEATURES.POLICY_MANAGEMENT_AND_WORKFLOWS, true),
    [getBooleanEntitlement],
  );

  const isReadOnly = !hasPermission(PERMISSIONS.WRITE_POLICIES) || !isEntitled;

  useEffect(() => {
    setPendingRule(null);
  }, [rulesData]);

  const handleAddRule = useCallback(() => {
    const ruleTemplate = policyTemplate.ruleTemplates[0];
    let initialSettings = {};

    if (ruleTemplate.baseCondition) {
      const rootFields = getBaseConditionRootFields(ruleTemplate.inputs, ruleTemplate.baseCondition);
      initialSettings = {
        ...rootFields.reduce<Record<string, DataType>>((acc, key) => {
          const input = ruleTemplate.inputs[key];
          acc[key] = input?.multi ? [] : '';
          return acc;
        }, {}),
        conditions: [],
      };
    }

    const newRule: IPolicyRule = {
      policySlug: policyTemplate.policySlug,
      enabled: false,
      responseType: policyTemplate.responseType,
      slug: ruleTemplate.slug,
      settings: initialSettings,
    } as IPolicyRule;

    setPendingRule(newRule);
  }, [policyTemplate]);

  const onAddCancel = useCallback(() => {
    setPendingRule(null);
  }, []);

  const allRules = pendingRule ? [...rulesData, pendingRule] : rulesData;

  return (
    <div className={styles.wrapper} data-testid='policyWrapper'>
      {allRules.map((rule, index) => (
        <div key={rule.id} className={styles.ruleWrapper}>
          <PolicyRule
            index={index}
            isReadOnly={isReadOnly}
            onAddCancel={onAddCancel}
            policyDisplayName={policyTemplate.display.displayName}
            policySlug={policyTemplate.policySlug}
            ruleData={rule}
            ruleTemplate={policyTemplate.ruleTemplates[0]}
          />

          {index !== 0 && (
            <div className={styles.chipWrapper}>
              <JitChip label={i18n.t('pages.policies.rule.divider.or')} params={{ sx: { backgroundColor: colors.tooltip } }} />
            </div>
          )}
        </div>
      ))}

      <AddNewRule disabled={!!pendingRule} isReadOnly={isReadOnly} onClick={handleAddRule} policyName={policyTemplate.display.displayName} />
    </div>
  );
};
