import { FC, useMemo } from 'react';
import { useQuery } from 'react-query';

import { LeaderboardTable } from './components/LeaderboardTable/LeaderboardTable';
import styles from './LeaderboardPage.module.scss';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { useTeamsContext } from 'context/TeamsContext/TeamsContext';
import { useTeamsService } from 'services/TeamsService/useTeamsService';
import { Queries } from 'types/enums/Queries';
import { TeamSortBy, TeamSortOrder } from 'types/interfaces/Teams/TeamSorting';

export const LeaderboardPage: FC = () => {
  const { specificTeam, teams: teamsOfTeamLeader } = useTeamsContext();
  const { fetchTeams } = useTeamsService();

  const { data: teams, isLoading } = useQuery(
    [Queries.TeamsLeaderboard, {
      sortBy: TeamSortBy.SCORE,
      sortOrder: TeamSortOrder.DESC,
      limit: 10,
      displayImage: true,
    }],
    fetchTeams,
  );

  const currentTeam = useMemo(() => teamsOfTeamLeader?.data?.find(((currTeam) => currTeam.id === specificTeam.team?.id)), [specificTeam.team?.id,
    teamsOfTeamLeader?.data]);

  return (
    <PageLayout>
      <div className={styles.root}>
        <div className={styles.title}>
          <PageTitles title='pages.teamsPortal.leaderboardPage.title' />
        </div>

        {isLoading || specificTeam.isLoadingSpecificTeam
          ? <LoadingBar /> : (
            <LeaderboardTable currentTeam={currentTeam} teams={teams?.data || []} />
          )}
      </div>
    </PageLayout>
  );
};
