import { FC, useMemo } from 'react';

import { ConditionEditor } from './components/ConditionEditor';
import { ConditionTitle } from './components/ConditionTitle';
import { ConditionTypeSelector } from './components/ConditionTypeSelector';
import styles from './ConditionSection.module.scss';

import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import { IConditionStep } from 'types/interfaces/Workflows/IWorkflow';

interface Props {
  selectedStep: IConditionStep;
}

export const ConditionSection: FC<Props> = ({ selectedStep }) => {
  const {
    workflow,
    stepsOptions,
  } = useEditWorkflowContext();
  const conditionOptions = useMemo(() => stepsOptions?.conditionOptions || [], [stepsOptions]);
  const conditionOption = useMemo(() => conditionOptions.find((option) => option.type === selectedStep.type), [conditionOptions, selectedStep.type]);

  return (
    <div className={styles.conditionSection}>
      <ConditionTitle workflowTriggerName={workflow?.trigger?.name} />

      <ConditionTypeSelector
        conditionOptions={conditionOptions}
        selectedStep={selectedStep}
      />

      {conditionOption && <ConditionEditor conditionOption={conditionOption} selectedStep={selectedStep} />}
    </div>
  );
};
