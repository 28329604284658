import { SvgIconComponent } from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';

import styles from './IconButton.module.scss';

import { WhiteDotCheck } from 'assets';
import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { DisabledElement } from 'components/DisabledElement/DisabledElement';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import colors from 'themes/colors.module.scss';
import { IMouseEvent, ISvg } from 'types/interfaces';
import { stopPropagation } from 'utils';

interface Props {
  icon: FC<ISvg> | SvgIconComponent;
  onClick: (e: IMouseEvent) => void;
  isDisabled?: boolean;
  tooltipText: string;
  completed?: boolean;
  testId?: string;
  color?: string;
  size?: number;
  isLoading?: boolean;
  isToggled?: boolean;
  withBackSquare?: boolean;
  backSquareColor?: string;
}

const DEFAULT_COLOR = 'transparent';

const withBackSquareStyles = {
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const IconButton: FC<Props> = ({
  size = 16,
  icon,
  onClick,
  isDisabled,
  tooltipText,
  completed,
  testId = 'icon-button',
  color = colors.white,
  isLoading,
  isToggled,
  withBackSquare,
  backSquareColor = colors.deepPurple,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (isDisabled) return;
    setIsHovered(true);
  }, [isDisabled]);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handleClick = useCallback((e: IMouseEvent) => {
    if (isDisabled) return;

    stopPropagation(e);
    onClick(e);
  }, [isDisabled, onClick]);

  const useBackSquare = useMemo(() => isHovered || isToggled, [isHovered, isToggled]);

  const backSquareProps = useMemo(() => ({
    backgroundColor: useBackSquare ? backSquareColor : DEFAULT_COLOR,
    borderRadius: 50,
    borderColor: DEFAULT_COLOR,
  }), [backSquareColor, useBackSquare]);
  return (
    <DisabledElement isDisabled={!!isDisabled}>
      <div className={styles.iconButtonWrapper}>
        {isLoading ? (
          <div style={{
            width: size,
            height: size,
            ...(withBackSquare ? withBackSquareStyles : {}),
          }}
          >
            <CircularLoadingSpinner />
          </div>
        ) : (
          <JitTooltip data-testid='icon-button-tooltip' followCursor={false} placement='top' title={tooltipText}>
            <JitIcon
              color={color}
              data-testid={testId}
              icon={icon}
              onClick={isDisabled ? undefined : handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              size={`${size}px`}
              withBackSquare={backSquareProps}
            />
          </JitTooltip>
        )}

        {completed && (
          <JitIcon
            className={styles.completedIcon}
            data-testid='completed-icon'
            icon={WhiteDotCheck}
            size='15px'
          />
        )}
      </div>
    </DisabledElement>
  );
};
