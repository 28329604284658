import { FC } from 'react';

import { FindingsPage } from './FindingsPage';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { FindingsProvider } from 'context/FindingsContext/FindingsProvider';
import { useNewBacklogPageRoute } from 'pages/FindingsPage/hooks/useNewBacklogPageRoute';

export const FindingsPageWrapper: FC = () => {
  useNewBacklogPageRoute();

  return (
    <PageLayout>
      <FindingsProvider>
        <FindingsPage />
      </FindingsProvider>
    </PageLayout>
  );
};
