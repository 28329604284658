import { FC } from 'react';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { TeamPage } from 'pages/TeamsPortal/TeamPage/TeamPage';

export const TeamPageWrapper: FC = () => (
  <PageLayout>
    <TeamPage />
  </PageLayout>

);
