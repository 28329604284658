import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';
import { NEW_WORKFLOW_PATH_PARAM } from 'pages/WorkflowsPage/EditWorkflowPage/constants';

interface ICreateWorkflowButtonProps {
  isFirstWorkflow: boolean;
  isReadOnly: boolean;
}

export const CreateWorkflowButton: FC<ICreateWorkflowButtonProps> = ({ isFirstWorkflow, isReadOnly }) => {
  const navigate = useNavigate();

  const { WORKFLOWS } = constants.routes;

  const handleClick = async () => {
    navigate(`/${WORKFLOWS}/${NEW_WORKFLOW_PATH_PARAM}`);
  };

  const text = isFirstWorkflow ? 'pages.workflows.emptyPage.buttons.create' : 'pages.workflows.workflowsListPage.buttons.create';
  const buttonWidth = isFirstWorkflow ? 250 : 200;

  return (
    <JitGradientButton
      data-testid='workflows-button-create'
      height={45}
      isReadOnly={isReadOnly}
      onClick={handleClick}
      width={buttonWidth}
    >
      <JitText horizontalAlign='center' text={text} />
    </JitGradientButton>
  );
};
