export const getTextWidth = (text: string, fontSize: number, fontFamily: string = 'Arial'): number => {
  // Create an off-screen canvas
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) {
    throw new Error('Unable to get 2D context');
  }

  // Set the font for the context
  context.font = `${fontSize}px ${fontFamily}`;

  // Measure the text width
  const metrics = context.measureText(text);
  return metrics.width;
};
