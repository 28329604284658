import { useCallback } from 'react';

import { IPolicyRule, IPolicyTemplate, RuleSettings } from 'pages/PoliciesPage/types';
import { getApiUrls } from 'services/apiUrls';
import { useClient } from 'services/useClient';
import { camelizeSnakeCaseKeys, turnCamelCaseToSnakeCase } from 'utils/functions/camelCaseConverter';

export type UpdatePolicyPayload = {
  enabled?: boolean;
  settings?: RuleSettings;
};

type CreatePolicyPayload = {
  ruleSlug: string;
  policySlug: string;
} & UpdatePolicyPayload;

type PolicyValidationError = {
  message: string;
  error: string;
  invalidParameters: Partial<UpdatePolicyPayload | CreatePolicyPayload>;
};

export const usePoliciesService = () => {
  const { client } = useClient();

  const getPoliciesTemplate = useCallback(async (): Promise<IPolicyTemplate[]> => {
    const url = getApiUrls.policiesService.getPoliciesTemplate();
    const params = { enabled_rules_count: true };

    const response = await client.get<IPolicyTemplate[]>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params,
      },
    });

    return camelizeSnakeCaseKeys(response?.data || []) as IPolicyTemplate[];
  }, [client]);

  type GetPolicyRulesResponse = {
    data: IPolicyRule[];
    metadata: object;
  };

  const getPolicyRules = useCallback(async (slug: string, enabled?: boolean): Promise<IPolicyRule[]> => {
    const url = getApiUrls.policiesService.getPolicyRules(slug);
    const params = enabled !== undefined ? { enabled } : undefined;
    const requestConfig = params ? { params } : undefined;

    const response = await client.get<GetPolicyRulesResponse>({
      url,
      allowedStatuses: [200],
      ...(requestConfig && { requestConfig }),
    });

    const policyRules = response?.data?.data || [];

    return camelizeSnakeCaseKeys(policyRules) as IPolicyRule[];
  }, [client]);

  const updatePolicyRule = useCallback(async (ruleId: string, payload: UpdatePolicyPayload): Promise<IPolicyRule> => {
    const url = getApiUrls.policiesService.updatePolicyRule(ruleId);

    const response = await client.patch<IPolicyRule | PolicyValidationError>({
      url,
      requestConfig: {
        data: turnCamelCaseToSnakeCase(payload),
      },
      allowedStatuses: [200, 400],
    });

    if (response?.status === 400) {
      throw new Error((response.data as PolicyValidationError).message);
    }

    return camelizeSnakeCaseKeys(response?.data || {}) as IPolicyRule;
  }, [client]);

  const createPolicyRule = useCallback(async (payload: CreatePolicyPayload): Promise<IPolicyRule> => {
    const url = getApiUrls.policiesService.createPolicyRule();

    const response = await client.post<IPolicyRule | PolicyValidationError>({
      url,
      requestConfig: {
        data: turnCamelCaseToSnakeCase(payload),
      },
      allowedStatuses: [201, 400],
    });

    if (response?.status === 400) {
      throw new Error((response.data as PolicyValidationError).message);
    }

    return camelizeSnakeCaseKeys(response?.data || {}) as IPolicyRule;
  }, [client]);

  const deletePolicyRule = useCallback(async (ruleId: string): Promise<void> => {
    const url = getApiUrls.policiesService.deletePolicyRule(ruleId);
    setTimeout(() => {}, 3000);
    await client.delete({
      url,
      allowedStatuses: [204],
    });
  }, [client]);

  return {
    getPoliciesTemplate,
    getPolicyRules,
    updatePolicyRule,
    createPolicyRule,
    deletePolicyRule,
  };
};
