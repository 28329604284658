import { isArray, isEmpty, isEqual } from 'lodash';
import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ConvertSinglePipelineResponseIntoIPipeline } from '../utils';

import { PipelineFindingsTable } from './components/PipelineFindings/PipelineFindingsTable';
import { PipelineHeader } from './components/PipelineHeader/PipelineHeader';
import { PipelineMetadata } from './components/PipelineMetadata/PipelineMetadata';
import { PipelineWorkflows } from './components/PipelineWorkflows/Workflows/PipelineWorkflows';
import styles from './PipelineDetailsPage.module.scss';
import { getRelevantFindingsIdsForPipelineWorkflows, setPipelineFindings } from './utils/pipelineFindings';

import { JittyTheExplorer } from 'assets';
import { GeneralErrorPage } from 'components/GeneralErrorPage';
import { JitBreadcrumbs } from 'components/JitBreadcrumbs/JitBreadcrumbs';
import { renderLinkBreadCrumbItem, renderTextBreadCrumbItem } from 'components/JitBreadcrumbs/utils/textToBreadcrumbContent';
import { JitDivider } from 'components/JitDivider/JitDivider';
import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { usePipelineContextNew } from 'context/PipelinesContext/PipelinesContextNew';
import { constants } from 'globalConstants';
import { getTextFromPipelineParams } from 'pages/PipelinesPage/utils/utilsNew';
import { usePipelineService } from 'services/PipelinesService/usePipelineService';
import { IFindingServer, ServerFindingKeys } from 'types/interfaces';

// eslint-disable-next-line max-statements
export const PipelineDetailsPage: FC = () => {
  const { pipelineId: selectedPipelineId } = useParams();
  const { selectedPipeline, setSelectedPipeline, pipelinesState } = usePipelineContextNew();
  const [findings, setFindings] = useState<IFindingServer[]>([]);
  const [isFetchingFindings, setIsFetchingFindings] = useState<boolean>(true);
  const [isFetchingSelectedPipeline, setIsFetchingSelectedPipeline] = useState<boolean>(false);
  const { getPipelineById } = usePipelineService();
  const selectedPipelineFindingsIds = useMemo(() => (selectedPipeline && getRelevantFindingsIdsForPipelineWorkflows(selectedPipeline)?.sort())
    || [], [selectedPipeline]);
  const currentFindingsIds = useMemo(() => findings.map((finding: IFindingServer) => finding[ServerFindingKeys.id])?.sort(), [findings]);

  useEffect(() => {
    const shouldFetchFindings = !isEqual(currentFindingsIds, selectedPipelineFindingsIds) && !isEmpty(selectedPipelineFindingsIds);
    if (shouldFetchFindings) {
      setPipelineFindings(selectedPipelineFindingsIds, selectedPipeline, setFindings, setIsFetchingFindings);
    } else if (isFetchingFindings && isArray(selectedPipelineFindingsIds) && isEmpty(selectedPipelineFindingsIds)) {
      setIsFetchingFindings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPipelineFindingsIds, setFindings]);

  if (!selectedPipeline) {
    const { pipelines } = pipelinesState;

    const foundPipeline = pipelines?.find((pipeline) => pipeline.pipelineId === selectedPipelineId);

    if (foundPipeline) {
      setSelectedPipeline(foundPipeline);
    }
  }

  const handleGetPipelineById = useCallback(async () => {
    if (selectedPipelineId && !selectedPipeline) {
      setIsFetchingSelectedPipeline(true);
      const response = await getPipelineById(selectedPipelineId);
      setIsFetchingSelectedPipeline(false);
      if (response?.status === 200) {
        if (response?.data?.data) {
          setSelectedPipeline(ConvertSinglePipelineResponseIntoIPipeline(response?.data?.data));
        }
      } else {
        setSelectedPipeline(null);
      }
    }
  }, [getPipelineById, selectedPipeline, selectedPipelineId, setSelectedPipeline]);

  useEffect(() => {
    handleGetPipelineById();
  }, [handleGetPipelineById]);

  const pageContent = useMemo(() => (
    !selectedPipeline
      ? (
        <GeneralErrorPage
          description='pages.pipelineDetails.errorPage.description'
          icon={JittyTheExplorer}
          title='pages.pipelineDetails.errorPage.title'
        />
      )
      : (
        <>
          <PipelineHeader pipeline={selectedPipeline} />

          <div className={styles.divider}>
            <JitDivider />
          </div>

          <div className={styles.divider}>
            <JitDivider />
          </div>

          <PipelineMetadata pipeline={selectedPipeline} />

          <div className={styles.divider}>
            <JitDivider />
          </div>

          <PipelineWorkflows pipeline={selectedPipeline} />

          <div className={styles.divider}>
            <JitDivider />
          </div>

          <div className={styles.pipelineDetailsTable} data-testid='pipelinesTableWrapper'>
            <PipelineFindingsTable findings={findings} isFetchingFindings={isFetchingFindings} selectedPipeline={selectedPipeline} />
          </div>
        </>
      )
  ), [findings, isFetchingFindings, selectedPipeline]);

  return (
    <PageLayout>
      { isFetchingSelectedPipeline ? (
        <LoadingBar data-testid='PipelineDetailsPageLoadingBar' />
      )
        : (

          <div className={styles.pipelineDetailsPage} data-testid='PipelineDetailsPage'>
            <JitBreadcrumbs
              items={[
                {
                  content: renderLinkBreadCrumbItem(
                    'pages.pipelineDetails.breadcrumbs.allPipelines',
                    `/${constants.routes.PIPELINES}`,
                    'allPipelineBreadcrumb',
                  ),
                  itemKey: 'allPipelineBreadcrumb',
                },
                {
                  content: renderTextBreadCrumbItem(
                    getTextFromPipelineParams(
                      selectedPipeline?.sourceAssetVendor,
                      selectedPipeline?.sourceAssetOwner,
                      selectedPipeline?.sourceAssetName,
                      selectedPipeline?.sourceAssetType,
                      selectedPipeline?.jitEventName,
                    ) || 'pages.pipelineDetails.errorPage.assetText',
                    'selectedPipelineBreadcrumb',
                  ),
                  itemKey: 'selectedPipelineBreadcrumb',
                },
              ]}
            />

            {pageContent}
          </div>
        )}
    </PageLayout>
  );
};
