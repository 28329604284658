import { FC } from 'react';

import { CreateWorkflowButton } from '../components/CreateWorkflowButton/CreateWorkflowButton';
import { WorkflowsTable } from '../components/WorkflowsTable/WorkflowsTable';

import styles from './WorkflowsSection.module.scss';

import { PageLayout } from 'components/Pages/PageLayout/PageLayout';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { PremiumFeatureBanner } from 'components/PremiumFeatureBanner/PremiumFeatureBanner';
import { i18n } from 'locale/i18n';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

interface WorkflowSectionProps {
  workflows: IWorkflow[];
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  isEntitled: boolean;
  isReadOnly: boolean;
}

export const WorkflowSection: FC<WorkflowSectionProps> = ({
  workflows,
  fetchNextPage,
  isFetchingNextPage,
  isEntitled,
  isReadOnly,
}) => {
  const premiumBannerMessageText = i18n.t('pages.workflows.premiumBannerMessage');

  return (
    <PageLayout>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <PageTitles
            subtitle='pages.workflows.workflowsListPage.subtitle'
            title='pages.workflows.workflowsListPage.title'
          />

          <PremiumFeatureBanner isEntitled={isEntitled} message={premiumBannerMessageText} />

          <CreateWorkflowButton isFirstWorkflow={false} isReadOnly={isReadOnly} />
        </div>

        <div className={styles.content}>
          <WorkflowsTable
            fetchNextPage={fetchNextPage}
            isEntitled={isEntitled}
            isFetchingNextPage={isFetchingNextPage}
            isReadOnly={isReadOnly}
            workflows={workflows}
          />
        </div>
      </div>
    </PageLayout>
  );
};
