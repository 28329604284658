import { FC, useState } from 'react';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';

interface Props {
  onClick: () => void;
  type: 'add' | 'remove';
}

export const AddRemoveButton: FC<Props> = ({ onClick, type }) => {
  const [isHovered, setIsHovered] = useState(false);

  const textKey = type === 'add' ? 'addNew' : 'remove';
  const textColor = isHovered ? colors.white : colors.upgradeColor;

  return (
    <JitButton
      data-testid={`addRemoveButton-${type}`}
      height={38}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        borderRadius: '4px',
        border: `1px solid ${colors.upgradeColor}`,
        '&:hover': {
          background: '#3B3953',
          borderColor: colors.upgradeColor,
        },
        minWidth: 'fit-content',
        width: 'auto',
      }}
      variant='outlined'
    >
      <JitText color={textColor} text={i18n.t(`pages.policies.rule.buttons.condition.${textKey}.label`) as string} />
    </JitButton>
  );
};
