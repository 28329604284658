import { LocalizationsOverrides } from '@frontegg/react';

import { config } from 'config';
import { constants } from 'globalConstants';
import { i18n } from 'locale/i18n';

// These routes are temporary solution for disabling them until frontegg will enable platform support for them
export const productionRoutes = {
  forgetPasswordUrl: constants.LOGIN_PATH,
  resetPasswordUrl: constants.LOGIN_PATH,
  resetPhoneNumberUrl: constants.LOGIN_PATH,
};

export const authOptions = {
  disableSilentRefresh: false,
  includeQueryParam: true, // This is needed for authentication with a third party app such as (CLI, IDE, etc.)
  routes: {
    loginUrl: constants.LOGIN_PATH,
    logoutUrl: constants.LOGOUT_PATH,
    ...(config.isProduction && productionRoutes),
  },
};

export const localizations: LocalizationsOverrides = {
  en: {
    loginBox: {
      login: {
        emailInputLabel: i18n.t('pages.login.emailTitle'),
        emailInputPlaceholder: i18n.t('pages.login.emailInputPlaceholder'),
        continue: i18n.t('pages.login.continue'),
        signInWithSocialLogin: i18n.t('pages.login.or'),
        signUpMessage: '',
        signUpLink: '',
      },
      socialLogins: {
        invalidTitle: i18n.t('pages.login.socialLogins.invalidTitle'),
        failedBackToLogin: i18n.t('pages.login.socialLogins.failedBackToLoginBtn'),
      },
    },
  },
};

