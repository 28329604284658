import { FC } from 'react';

import { IntegrationRole } from '../ChooseIntegrationMethod/ChooseIntegrationMethod';

import styles from './ChooseGroup.module.scss';
import { GroupsDropdown } from './GroupsDropdown';
import { SubgroupsDropdown } from './SubgroupsDropdown';

import { GitlabIcon, Info } from 'assets';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import { IGroup } from 'services/GitlabService/useGitlabService';
import colors from 'themes/colors.module.scss';
import { GitlabAccessLevel } from 'types/interfaces';

interface Props {
  onContinueButtonClick: () => void;
  selectedRole: IntegrationRole;
  setSelectedGroup: (group: IGroup) => void;
  selectedGroup?: IGroup;
  setSelectedSubgroup: (group: IGroup) => void;
  selectedSubgroup?: IGroup;
  isLoading: boolean;
}

export const ChooseGroup: FC<Props> = ({
  onContinueButtonClick, selectedRole, setSelectedGroup, selectedGroup, isLoading,
  setSelectedSubgroup, selectedSubgroup,
}) => {
  const accessLevel = selectedRole === 'owner' ? GitlabAccessLevel.OWNER : GitlabAccessLevel.MAINTAINER;

  return (
    <DialogContentWrapper
      actionButtons={[{
        label: 'GitlabIntegrationWizard.chooseGitlabGroup.buttonText',
        disabled: !selectedGroup,
        handleClick: onContinueButtonClick,
        isLoading,
      }]}
      icon={GitlabIcon}
      leftButtonsElement={(
        <JitExternalLink
          href={i18n.t(`GitlabIntegrationWizard.chooseGitlabGroup.docsReference.link.${selectedRole}`)}
          text='GitlabIntegrationWizard.chooseGitlabGroup.docsReference.text'
        />
          )}
    >
      <div className={styles.content}>
        <div className={styles.dropdowns}>
          <GroupsDropdown
            minAccessLevel={accessLevel}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          />

          <SubgroupsDropdown
            groupId={selectedGroup?.id}
            minAccessLevel={accessLevel}
            selectedSubgroup={selectedSubgroup}
            setSelectedSubgroup={setSelectedSubgroup}
          />
        </div>

        <JitInfoBanner bgColor={colors.cardContent} data-testid='alert-message' icon={Info} iconColor={colors.blue}>
          <JitText text='GitlabIntegrationWizard.chooseGitlabGroup.info' />
        </JitInfoBanner>

      </div>
    </DialogContentWrapper>
  );
};
