import { useCallback } from 'react';

import { useWorkflowsService } from 'services/WorkflowsService/useWorkflowsService';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

export const useHandleWorkflowToggle = () => {
  const { updateWorkflow } = useWorkflowsService();

  const handleToggle = useCallback((newChecked: boolean, workflow: IWorkflow) => {
    const updatedWorkflow = { ...workflow,
      isEnabled: newChecked };
    updateWorkflow(updatedWorkflow);
  }, [updateWorkflow]);

  return handleToggle;
};
