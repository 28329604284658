import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { JitButton } from 'components/JitButton/JitButton';
import { JitText, JitTextProps } from 'components/JitText/JitText';
import { config } from 'config';
import colors from 'themes/colors.module.scss';

interface IJitUpgradeButtonProps {
  text: string;
  size?: 'small' | 'medium';
  textSize?: JitTextProps['size'];
}

const SIZE_PRESETS = {
  small: {
    height: 30,
  },
  medium: {
    height: 46,
  },
};

export const JitUpgradeButton: FC<IJitUpgradeButtonProps> = ({ text, size = 'medium', textSize = 'l' }) => {
  const { uiShowPaymentPage } = useFlags();
  const sizeConfig = SIZE_PRESETS[size];

  const buttonProps = uiShowPaymentPage
    ? {
      component: Link,
      to: '/pricing-plans',
    }
    : {
      href: config.contactUsUrl,
      target: '_blank',
    };

  return (
    <JitButton
      data-testid='upgradeToPremium'
      height={sizeConfig.height}
      noHover
      sx={{
        borderRadius: '4px',
        border: `1px solid ${colors.upgradeColor}`,
        background: `${colors.upgradeColor}1A`,
      }}
      variant='outlined'
      width='fit-content'
      {...buttonProps}
    >
      <DiamondOutlinedIcon
        fontSize='small'
        sx={{
          color: colors.upgradeColor,
          marginRight: '6px',
        }}
      />

      <JitText color={colors.upgradeColor} size={textSize} text={text} />
    </JitButton>
  );
};
