import { FronteggThemeOptions } from '@frontegg/react';

import styles from './LoginPage.module.scss';

import { JittyOkSVG } from 'assets';
import { JitText } from 'components/JitText/JitText';
import { config } from 'config';
import { i18n } from 'locale/i18n';
import { LoginPage } from 'pages/LoginPage/LoginPage';
import colors from 'themes/colors.module.scss';

const fontFamily = 'Inter';

const BORDER_RADIUS = '8px';
const BUTTON_WIDTH = '230px';
const BUTTON_HEIGHT = '45px';

const fixedBackgroundStyle = {
  boxShadow: 'none',
  background: `linear-gradient(108deg, ${colors.white} 0%, ${colors.brightPink2} 100%)`,
};
const fixedPageBackgroundStyle = {
  ...fixedBackgroundStyle,
  '& div.MuiCollapse-wrapperInner > form': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > div': {
      marginBottom: '0',
    },
  },

  '& .fe-social-login-github-icon path': {
    fill: colors.white,
  },
};

const socialLoginButtonStyle = {
  base: {
    width: BUTTON_WIDTH,
    fontFamily,
    height: BUTTON_HEIGHT,
    color: colors.cards,
    backgroundColor: 'transparent',
    borderRadius: BORDER_RADIUS,
    border: `1px solid ${colors.black}`,
    padding: '0 0',
  },
  hover: {
    backgroundColor: colors.brightPink2,
    opacity: 0.8,
  },
};

const githubLoginButtonStyle = {
  ...socialLoginButtonStyle,
  base: {
    ...socialLoginButtonStyle.base,
    backgroundColor: colors.black,
    color: colors.white,
  },
  hover: {
    ...socialLoginButtonStyle.hover,
    backgroundColor: colors.black,
  },
};

export const fronteggLoginThemeOptions: FronteggThemeOptions = {
  loginBox: {
    boxStyle: {
      boxShadow: 'none',
      background: 'transparent',
    },
    submitButtonTheme: {
      base: {
        width: BUTTON_WIDTH,
        backgroundColor: colors.jitPink,
        borderRadius: BORDER_RADIUS,
        border: 'none',
      },
      hover: {
        backgroundColor: colors.jitPink,
        opacity: 0.8,
      },
    },
    inputTheme: {
      base: {
        width: BUTTON_WIDTH,
        borderRadius: BORDER_RADIUS,
        height: BUTTON_HEIGHT,
        backgroundColor: 'transparent',
        border: `1px solid ${colors.gray}`,
        placeholderColor: colors.gray,
      },
      hover: {
        outline: 'none',
        borderColor: `${colors.jitPink} !important`,
      },
    },
    logo: {
      image: JittyOkSVG,
      placement: 'page',
      alignment: 'center',
      style: {
        width: '100px',
        height: '100px',
        marginTop: '40px',
        marginBottom: '80px',
      },
    },
    socialLogins: {
      githubButtonStyle: githubLoginButtonStyle,
      googleButtonStyle: socialLoginButtonStyle,
      microsoftButtonStyle: socialLoginButtonStyle,
      socialLoginsLayout: {
        mode: 'eventually',
        placement: 'top',
      },
      containerStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      dividerStyle: {
        width: BUTTON_WIDTH,
        margin: '18px auto',
      },
    },
    login: {
      title: i18n.t('pages.login.getStartedForFree'),
      typographyStyleOptions: { fontFamily },
      titleStyle: {
        color: colors.cards,
        fontSize: '20px !important',
        fontFamily: 'Sora',
        fontWeight: '700',
        marginBottom: '0 !important',
        lineHeight: '0.5rem',
      },
      rootStyle: fixedPageBackgroundStyle,
      layout: {
        type: 'float-left',
        sideElement: LoginPage,
      },
    },
    signup: {
      hideSignUpForm: true,
      layout: {
        type: 'float-left',
        sideElement: LoginPage,
      },
    },

    boxFooter: () => (
      <div className={styles.loginBoxWrapper}>
        <JitText
          align='center'
          color={colors.darkGray}
          components={{
            termsOfService: (
              <JitText
                color={colors.darkGray}
                display='inline'
                onClick={() => window.open(config.docsTermsOfServiceLink)}
                size='s'
                text='pages.login.termsOfService'
                withUnderline
              />
            ),
            privacyPolicy: (
              <JitText
                color={colors.darkGray}
                display='inline'
                onClick={() => window.open(config.docsPrivacyPolicyLink)}
                size='s'
                text='pages.login.privacyPolicy'
                withUnderline
              />
            ),
          }}
          muted
          size='s'
          text='pages.login.disclosure'
        />
      </div>
    ),
  },
  adminPortal: {
    themeName: 'dark',
    palette: {
      primary: {
        light: colors.iris,
        main: colors.iris,
        dark: colors.iris,
        hover: colors.iris,
        active: colors.iris,
        contrastText: colors.iris,
      },
    },
    layout: {
      fullScreenMode: false,
    },
    pages: {
      users: {
        hideInviteWithEmail: true,
      },
      header: {
        backgroundColor: colors.generalSystemsBG,
      },
      content: {
        backgroundColor: colors.generalSystemsBG,
      },
    },
  },
};
