import { FC, useCallback, useEffect, useMemo } from 'react';

import { ActionConfigInputProps } from '../../ActionConfigInput';

import styles from './VendorPicker.module.scss';

import { useIntegrationsContext } from 'context/IntegrationsContext';
import { useTenantContext } from 'context/TenantContext';
import { VendorPickerCard } from 'pages/WorkflowsPage/EditWorkflowPage/components/StepDetails/components/ActionSection/ConfigInputs/VendorPicker/VendorPickerCard';
import { IntegrationStatus as InstallationStatus } from 'types/interfaces';
import { IntegrationStatus } from 'types/interfaces/Integrations/IIntegration';

export const VendorPicker: FC<ActionConfigInputProps> = ({
  configSchema,
  actionConfig,
  updateActionConfig,
}) => {
  const { installations } = useTenantContext();
  const { integrations } = useIntegrationsContext();

  const isActiveIntegration = useCallback((vendor: string) => {
    const isInstalled = installations.some(
      (installation) => installation.vendor === vendor && installation.status === InstallationStatus.CONNECTED,
    );

    const isIntegration = integrations?.some(
      (integration) => (integration.vendor === vendor || integration.vendor === `custom.${vendor}`) && integration.status === IntegrationStatus.SUCCESS,
    );
    return isInstalled || isIntegration;
  }, [installations, integrations]);

  const vendors = useMemo(() => configSchema.enum || [], [configSchema.enum]);

  const isSelected = useCallback(
    (vendor: string) => actionConfig[configSchema.key] === vendor,
    [actionConfig, configSchema.key],
  );

  const onCardClick = useCallback((vendor: string) => {
    updateActionConfig(configSchema.key, vendor);
  }, [updateActionConfig, configSchema.key]);

  useEffect(() => {
    const activeVendors = vendors.filter((vendor) => isActiveIntegration(vendor));
    if (activeVendors.length === 1 && !actionConfig[configSchema.key]) {
      onCardClick(activeVendors[0]);
    }
  }, [vendors, actionConfig, configSchema.key, isActiveIntegration, onCardClick]);

  return (
    <div className={styles.wrapper}>
      {vendors.map((vendor) => (
        <VendorPickerCard
          key={vendor}
          isDisabled={!isActiveIntegration(vendor)}
          isSelected={isSelected(vendor)}
          onCardClick={onCardClick}
          vendor={vendor}
        />
      ))}
    </div>
  );
};
