import { useMemo } from 'react';

import { EmptyWorkflowPage } from './EmptyWorkflowPage/EmptyWorkflowPage';
import { useWorkflows } from './hooks/useWorkflows';
import { WorkflowSection } from './WorkflowsSection/WorkflowsSection';

import { LoadingBar } from 'components/LoadingBar/LoadingBar';
import { useAuthContext } from 'context/AuthContext/AuthContext';
import { PRODUCT_CATALOG_FEATURES } from 'context/StiggContext/constants';
import { useGetBooleanEntitlement } from 'context/StiggContext/useGetBooleanEntitlement';
import { PERMISSIONS } from 'wrappers/RBAC/constants';

export const WorkflowsPage = () => {
  const getBooleanEntitlement = useGetBooleanEntitlement();
  const { hasPermission } = useAuthContext();

  const isEntitled = useMemo(
    () => getBooleanEntitlement(PRODUCT_CATALOG_FEATURES.POLICY_MANAGEMENT_AND_WORKFLOWS, true),
    [getBooleanEntitlement],
  );

  const isReadOnly = !hasPermission(PERMISSIONS.WRITE_WORKFLOWS) || !isEntitled;

  const {
    workflows,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useWorkflows(true);

  if (isLoading) {
    return <LoadingBar />;
  }

  if (workflows.length === 0) {
    return <EmptyWorkflowPage isEntitled={isEntitled} isReadOnly={isReadOnly} />;
  }

  return (
    <WorkflowSection
      fetchNextPage={fetchNextPage}
      isEntitled={isEntitled}
      isFetchingNextPage={isFetchingNextPage}
      isReadOnly={isReadOnly}
      workflows={workflows}
    />
  );
};
