import { FC, useCallback } from 'react';

import { TrashIris } from 'assets';
import { IconButton } from 'components/IconButton/IconButton';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';

interface Props {
  nonDeletableMessage?: string;
}

export const RemoveStep: FC<Props> = ({ nonDeletableMessage }) => {
  const {
    workflow,
    selectedStepId,
    setWorkflow,
    setSelectedStepId,
  } = useEditWorkflowContext();

  const deleteStep = useCallback(() => {
    if (!selectedStepId || !workflow) return;

    const allSteps = [workflow.trigger, ...workflow.steps];
    const currentStepIndex = allSteps.findIndex((step) => step.id === selectedStepId);
    const previousStep = currentStepIndex > 0 ? allSteps[currentStepIndex - 1] : workflow.trigger;

    setWorkflow((prevWorkflow) => (
      {
        ...prevWorkflow,
        steps: prevWorkflow.steps.filter(
          (step) => step.id !== selectedStepId,
        )
          .map((step) => {
            if (step.next.includes(selectedStepId)) {
              return {
                ...step,
                next: step.next.filter((nextStepId) => nextStepId !== selectedStepId),
              };
            }
            return step;
          }),
        trigger: {
          ...prevWorkflow.trigger,
          next: prevWorkflow.trigger.next.filter((nextStepId) => nextStepId !== selectedStepId),
        },
      }));

    setSelectedStepId(previousStep.id);
  }, [selectedStepId, setWorkflow, workflow, setSelectedStepId]);

  return (
    <div>
      <JitTooltip followCursor={false} placement='left' title={nonDeletableMessage}>
        <IconButton
          color='none'
          icon={TrashIris}
          isDisabled={!!nonDeletableMessage}
          onClick={deleteStep}
          size={18}
          testId='remove-step'
          tooltipText={nonDeletableMessage || 'pages.workflows.editWorkflowPage.stepDetails.removeStepTooltip.remove'}
        />
      </JitTooltip>
    </div>
  );
};
