import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useEditWorkflowContext } from '../../EditWorkflowContext/EditWorkflowContext';

import { WorkflowInfoButton } from './components/WorkflowInfo/WorkflowInfoButton';
import styles from './Header.module.scss';

import { JitSwitchToggle, JitSwitchToggleProps } from 'components/JitSwitchToggle/JitSwitchToggle';
import { constants } from 'globalConstants';
import { i18n } from 'locale/i18n';
import { DeleteWorkflow } from 'pages/WorkflowsPage/components/DeleteWorkflow/DeleteWorkflow';
import { BackButton } from 'pages/WorkflowsPage/EditWorkflowPage/components/Header/components/BackButton/BackButton';
import { NameInput } from 'pages/WorkflowsPage/EditWorkflowPage/components/Header/components/NameInput/NameInput';
import { SaveWorkflow } from 'pages/WorkflowsPage/EditWorkflowPage/components/Header/components/SaveWorkflow/SaveWorkflow';

export interface IWorkflowHeaderTexts {
  backButton: string;
  tooltips: {
    details: string;
    deleteWorkflow: string;
    activateWorkflow: string;
  },
  saveDialog: {
    title: string;
    message: string;
    confirm: string;
    cancel: string;
  },
  save: string;
}

interface HeaderProps {
  isReadOnly: boolean;
}

export const Header: FC<HeaderProps> = ({ isReadOnly }) => {
  const {
    workflow,
    setWorkflow,
    isNewWorkflow,
  } = useEditWorkflowContext();
  const [nameAlreadyExists, setNameAlreadyExists] = useState(false);

  const navigate = useNavigate();
  const { WORKFLOWS } = constants.routes;

  const { t } = i18n;
  const workflowHeaderTexts: IWorkflowHeaderTexts = useMemo(
    () => t('pages.workflows.editWorkflowPage.header', { returnObjects: true }),
    [t],
  );

  const onToggleChange: JitSwitchToggleProps['onChange'] = useCallback((event, checked) => {
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      isEnabled: checked,
    }));
  }, [setWorkflow]);

  const onClickBack = useCallback(() => {
    navigate(`/${WORKFLOWS}`);
  }, [navigate, WORKFLOWS]);

  const onNameAlreadyExists = useCallback(() => {
    setNameAlreadyExists(true);
  }, []);
  const resetNameAlreadyExists = useCallback(() => {
    setNameAlreadyExists(false);
  }, []);

  if (!workflow) return null;

  return (
    <div className={styles.wrapper}>
      <BackButton onClickBack={onClickBack} />

      <NameInput
        nameAlreadyExists={nameAlreadyExists}
        resetNameAlreadyExists={resetNameAlreadyExists}
      />

      <div className={styles.actionButtons}>

        <DeleteWorkflow
          isNewWorkflow={isNewWorkflow}
          isReadOnly={isReadOnly}
          onDeleteSuccess={onClickBack}
          workflow={workflow}
        />

        <WorkflowInfoButton disabled={isNewWorkflow} workflow={workflow} />

        <JitSwitchToggle
          checked={workflow.isEnabled}
          isReadOnly={isReadOnly}
          onChange={onToggleChange}
          tooltip={workflowHeaderTexts.tooltips.activateWorkflow}
        />

        <SaveWorkflow setNameAlreadyExists={onNameAlreadyExists} workflowHeaderTexts={workflowHeaderTexts} />
      </div>
    </div>
  );
};
