import { isEmpty } from 'lodash';
import { FC, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetTableColumnsV3 } from './hooks/useGetTableColumnsV3';
import styles from './PipelinesPage.module.scss';
import { downcastMockPipelineNew } from './utils/MockPipelineNew/mockPipelineNew';
import { shouldShowPipeline } from './utils/utilsNew';

import { JittyEmpty } from 'assets';
import { JitEmpty } from 'components/JitEmpty/JitEmpty';
import { JitFilters } from 'components/JitFilters/JitFilters';
import { AppliedFiltersHasNoData } from 'components/JitTable/components/AppliedFiltersHasNoData/AppliedFiltersHasNoData';
import { MissingScmIntegration } from 'components/JitTable/components/MissingScmIntegration/MissingScmIntegration';
import { useGetTableInstance } from 'components/JitTable/hooks/useGetTableInstance';
import { JitTable } from 'components/JitTable/JitTable';
import { JitText } from 'components/JitText/JitText';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { useResetFiltersNew } from 'context/PipelinesContext/hooks/useResetFilters/useResetFiltersNew';
import { useUpdateFilterNew } from 'context/PipelinesContext/hooks/useUpdateFilter/useUpdateFilterNew';
import { usePipelineContextNew } from 'context/PipelinesContext/PipelinesContextNew';
import { isFiltersApplied } from 'context/PipelinesContext/utils/isFiltersApplied';
import { useTenantContext } from 'context/TenantContext/TenantContext';
import { constants } from 'globalConstants';
import { i18n } from 'locale/i18n';
import { PIPELINE_ROW_APPROXIMATE_HEIGHT } from 'pages/PipelinesPage/constants';
import colors from 'themes/colors.module.scss';
import { IMockPipelineNew, IPipelineNew } from 'types/interfaces/Pipelines/IPipelineNew';

export const NewPipelinesPage: FC = () => {
  const navigate = useNavigate();
  const { columnsData } = useGetTableColumnsV3();
  const {
    mockPipeline, secondaryMockPipeline, setSecondaryMockPipeline, pipelinesState, fetchMore, filters, setSelectedPipeline,
  } = usePipelineContextNew();
  const { pipelines, hasLoaded, isLoading } = pipelinesState;
  const { resetFilters } = useResetFiltersNew();
  const { updateFilter } = useUpdateFilterNew();
  const { isAnyScmVendorIntegrated } = useTenantContext();

  const findPipelineIndexInArray = (pipelineToFind: IPipelineNew, pipelinesArray?: IPipelineNew[]) => {
    const originalRepository = pipelineToFind.additionalAttributes?.originalRepository;
    const pullRequestNumber = pipelineToFind.additionalAttributes?.pullRequestNumber;
    const pipelineIndex = pipelinesArray ? pipelinesArray.findIndex((pipeline: IPipelineNew) => (
      pipeline.additionalAttributes?.originalRepository === originalRepository
      && parseInt(pipeline.additionalAttributes?.pullRequestNumber as string, 10)
      === parseInt(pullRequestNumber as string, 10)
    )) : -1;
    return pipelineIndex;
  };

  const prevDisplayPipelines = useMemo(() => {
    if (!secondaryMockPipeline) {
      return pipelines || [];
    }
    const pipelineIndex = findPipelineIndexInArray(secondaryMockPipeline, pipelines);
    const isFound = pipelineIndex > -1;
    if (isFound) {
      setSecondaryMockPipeline(null);
      return pipelines || [];
    }
    if (!shouldShowPipeline(filters, secondaryMockPipeline)) {
      return pipelines || [];
    }
    return [downcastMockPipelineNew(secondaryMockPipeline), ...(pipelines || [])];
  }, [filters, pipelines, secondaryMockPipeline, setSecondaryMockPipeline]);

  const displayPipelines = useMemo(() => {
    if (!isAnyScmVendorIntegrated) return [];

    if (mockPipeline) {
      if (!shouldShowPipeline(filters, mockPipeline)) {
        return prevDisplayPipelines;
      }
      const pinnedPipelineIndex = findPipelineIndexInArray(mockPipeline, prevDisplayPipelines);
      const isFound = pinnedPipelineIndex > -1;
      if (isFound) {
        const pinnedPipeline: IMockPipelineNew = {
          ...prevDisplayPipelines[pinnedPipelineIndex],
          planItemSlug: mockPipeline.planItemSlug,
          commitType: mockPipeline.commitType,
        };
        setSelectedPipeline(pinnedPipeline);
        return [pinnedPipeline, ...prevDisplayPipelines.slice(0, pinnedPipelineIndex), ...prevDisplayPipelines.slice(pinnedPipelineIndex + 1)];
      }
      if (!isEmpty(prevDisplayPipelines)) {
        setSelectedPipeline(mockPipeline);
      }
      return [mockPipeline, ...prevDisplayPipelines];
    }
    return [...prevDisplayPipelines];
  }, [filters, mockPipeline, prevDisplayPipelines, setSelectedPipeline, isAnyScmVendorIntegrated]);

  const tableInstance = useGetTableInstance(columnsData.columns, displayPipelines);

  const onSelectRow = (row: IPipelineNew) => {
    setSelectedPipeline(row);
    navigate(`/${constants.routes.PIPELINES}/${row.pipelineId}`);
  };

  const elementToShowOnPipelineHover: FC = () => (
    <div className={styles.elementToShowOnPipelineHover}>
      <JitText color={colors.iris} text='pages.pipelines.pipelinesTable.openDetails' />
    </div>
  );

  const EmptyTableView: FC = useCallback(() => {
    if (!isAnyScmVendorIntegrated) {
      return (
        <MissingScmIntegration
          subtitleAction='pages.pipelines.pipelinesTable.missingScmIntegrationSubtitleAction'
        />
      );
    }
    if (isFiltersApplied(filters) || isLoading) {
      return (
        <AppliedFiltersHasNoData
          clearFilters={resetFilters}
          entityName='pipelines'
        />
      );
    }
    return (
      <JitEmpty
        descriptionColor={colors.lightGray}
        icon={JittyEmpty}
        showJitty
        title={i18n.t('pages.pipelines.pipelinesTable.noPipelinesData')}
        titleColor={colors.white}
      />
    );
  }, [isAnyScmVendorIntegrated, filters, isLoading, resetFilters]);

  return (
    <div className={styles.pipelinesPage} data-testid='NewPipelinesPage'>
      <div className={styles.titleWrapper}>
        <PageTitles subtitle='pages.pipelines.subTitle' title='pages.pipelines.title' />
      </div>

      <div className={styles.filtersWrapper}>
        <JitFilters
          filters={filters}
          resetFilters={resetFilters}
          updateFilter={updateFilter}
        />
      </div>

      <div className={styles.pipelinesTable} data-testid='pipelinesTableWrapper'>
        <JitTable
          cellPadding='5px 0 25px 20px'
          cellVerticalAlign='top'
          ElementToShowOnRowHover={elementToShowOnPipelineHover}
          EmptyTableView={EmptyTableView}
          entityName='pipelines'
          handleReachScrollEnd={{
            callback: fetchMore,
            threshold: PIPELINE_ROW_APPROXIMATE_HEIGHT,
          }}
          isFetching={isAnyScmVendorIntegrated && isLoading}
          isLoading={isAnyScmVendorIntegrated && !hasLoaded}
          onSelectRow={onSelectRow}
          showPagination={false}
          tableInstance={tableInstance}
        />
      </div>
    </div>
  );
};
