import { FC } from 'react';

import styles from './AddNewRule.module.scss';

import { JitText } from 'components/JitText/JitText';
import { JitReadOnlyTooltip } from 'components/JitTooltip/JitReadOnlyTooltip/JitReadOnlyTooltip';
import colors from 'themes/colors.module.scss';

interface Props {
  onClick(): void;
  policyName?: string;
  disabled?: boolean;
  isReadOnly: boolean;
}

export const AddNewRule: FC<Props> = ({ disabled, onClick, policyName = '', isReadOnly }) => {
  const isDisabled = disabled || isReadOnly;
  const addButtonColor = isDisabled ? colors.lightGray : colors.iris;

  const content = (
    <div
      className={`${styles.wrapper} ${isDisabled ? styles.disabled : ''}`}
      onClick={isDisabled ? undefined : onClick}
      role='button'
      tabIndex={0}
    >
      <JitText color={addButtonColor} text='+' />

      <JitText color={addButtonColor} params={{ policyName }} text='pages.policies.rule.buttons.add.label' />
    </div>
  );

  return isReadOnly ? (
    <JitReadOnlyTooltip followCursor>{content}</JitReadOnlyTooltip>
  ) : (
    content
  );
};
