export const getMaxTokenExpirationDate = () => {
  const today = new Date();
  const nextYearMinusOneDay = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate() - 1);
  return nextYearMinusOneDay.toISOString().split('T')[0];
};

export const createMaintainerTokenCurlCommand = () => (
  `# Replace <glpat-xxx> with your GitLab personal access token
# Replace <group_id> with your GitLab Group ID (note: ensure the PAT has Owner permissions in this group)
curl --request POST \\
--header "PRIVATE-TOKEN: <glpat-xxx>" \\
--header "Content-Type: application/json" \\
--url "https://gitlab.com/api/v4/groups/<group_id>/access_tokens" \\
--data '{
    "name": "jit-integration-token",
    "scopes": ["api"],
    "access_level": 40,
    "expires_at": "${getMaxTokenExpirationDate()}"
}'`
);
