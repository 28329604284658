import { FC, useState } from 'react';

import styles from './BackButton.module.scss';

import { ChevronClosed } from 'assets';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { JitButton } from 'components/JitButton/JitButton';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';
import colors from 'themes/colors.module.scss';
import { ActionButtonVariant } from 'types/interfaces';

interface Props {
  onClickBack: () => void;
}

export const BackButton: FC<Props> = ({ onClickBack }) => {
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);

  const { hasWorkflowChanged } = useEditWorkflowContext();

  return (
    <div>
      <JitButton disableRipple onClick={() => (hasWorkflowChanged ? setIsSaveDialogOpen(true) : onClickBack())}>
        <JitIcon color={colors.iris} icon={ChevronClosed} rotateDegrees={90} size={22} />

        <JitText color={colors.iris} noWrap text='pages.workflows.editWorkflowPage.header.backButton' />
      </JitButton>

      <CustomDialog
        content={(
          <div className={styles.dialogWrapper}>
            <JitText
              bold
              text='pages.workflows.editWorkflowPage.header.unsavedChangesDialog.message'
            />

            <div className={styles.buttons}>
              <JitActionButton
                actionButton={{
                  variant: ActionButtonVariant.SECONDARY,
                  label: 'pages.workflows.editWorkflowPage.header.unsavedChangesDialog.cancel',
                  handleClick: () => {
                    setIsSaveDialogOpen(false);
                    onClickBack();
                  },
                }}
              />

              <JitActionButton
                actionButton={{
                  variant: ActionButtonVariant.PRIMARY,
                  label: 'pages.workflows.editWorkflowPage.header.unsavedChangesDialog.backToEditing',
                  handleClick: () => setIsSaveDialogOpen(false),
                }}
              />
            </div>
          </div>
                )}
        dataTestId='unsaved-changes-dialog'
        isOpen={isSaveDialogOpen}
        onClose={() => setIsSaveDialogOpen(false)}
        title='pages.workflows.editWorkflowPage.header.unsavedChangesDialog.title'
        width='s'
        withDivider
      />
    </div>
  );
};
