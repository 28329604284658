import { FC, PropsWithChildren } from 'react';

import { SecretsManagementDialog } from '../../components/SecretsManagementDialog/SecretsManagementDialog';

import { useDialogsMapping } from './useDialogsMapping';

import { NewOrganizationDialog } from 'components/Navbar/components/SelectTenantPopper/components/NewOrganizationDialog/NewOrganizationDialog';
import { useDialogsContext } from 'context/DialogsContext/DialogsContext';

export const AllDialogs: FC<PropsWithChildren> = ({ children }) => {
  const { activeDialogs } = useDialogsContext();
  const dialogsMapping = useDialogsMapping();
  const secretsCustomDialogs = [<SecretsManagementDialog key='secretsDialog' />];
  const newOrganizationDialogs = [<NewOrganizationDialog key='newOrganization' />];

  return (
    <>
      {secretsCustomDialogs}

      {newOrganizationDialogs}

      {activeDialogs.map((activeDialog) => {
        const { props: defaultProps, template: Template } = dialogsMapping[activeDialog.type];
        return (
          <Template
            key={activeDialog}
            {...defaultProps}
            {...activeDialog.props}
            type={activeDialog.type}
          />
        );
      })}

      {children}
    </>
  );
};
