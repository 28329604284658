import { useMemo } from 'react';

import { DeleteWorkflowCell } from './customCells/DeleteWorkflowCell/DeleteWorkflowCell';
import { NameWithIconsCell } from './customCells/NameWithIconsCell/NameWithIconsCell';

import { DateTimeCell } from 'components/JitTable/templates/DateTimeCell/DateTimeCell';
import { StatusCellV2 } from 'components/JitTable/templates/StatusCell/StatusCellV2';
import { SwitchCell } from 'components/JitTable/templates/SwitchCell/SwitchCell';
import { UpgradeCell } from 'components/JitTable/templates/UpgradeCell/UpgradeCell';
import { UserCell } from 'components/JitTable/templates/UserCell/UserCell';
import { i18n } from 'locale/i18n';
import { useHandleWorkflowToggle } from 'pages/WorkflowsPage/hooks/useHandleWorkflowToggle';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';
import { TableColumn } from 'types/types';

interface IColumnHeaders {
  name: string;
  description: string;
  runs: string;
  lastRun: string;
  createdBy: string;
  createdAt: string;
  lastModified: string;
  status: string;
  enabled: string;
}

export const useGetColumns = (isEntitled: boolean, isReadOnly: boolean) => {
  const { t } = i18n;
  const columnHeaders: IColumnHeaders = useMemo(() => t('pages.workflows.workflowsListPage.table.columns', { returnObjects: true }), [t]);

  const handleToggle = useHandleWorkflowToggle();

  const columnsData = useMemo(() => {
    const columns = [
      {
        Header: columnHeaders.name,
        accessor: (workflow: IWorkflow) => ({
          name: workflow.name,
          description: workflow.description,
          vendors: workflow.steps.map((step) => step.config?.vendor).filter((vendor) => vendor),
        }),
        Cell: NameWithIconsCell,
        width: '20%',
      },
      {
        Header: columnHeaders.createdAt,
        accessor: (workflow: IWorkflow) => ({
          dateTime: workflow.createdAt,
          showTime: false,
        }),
        Cell: DateTimeCell,
        width: '10%',
      },
      {
        Header: columnHeaders.createdBy,
        accessor: 'creatorUserName',
        Cell: UserCell,
        width: '10%',
      },
      {
        Header: columnHeaders.lastRun,
        accessor: (workflow: IWorkflow) => ({
          dateTime: workflow.lastRunAt,
          showTime: true,
        }),
        Cell: DateTimeCell,
        width: '15%',
      },
      {
        Header: columnHeaders.status,
        accessor: 'lastRunStatus',
        Cell: StatusCellV2,
        width: '8%',
      },
      ...!isEntitled ? [{
        Header: '',
        accessor: 'dummyAccessor',
        Cell: UpgradeCell,
        width: '15%',
      }] : [],
      ...isEntitled && !isReadOnly ? [{
        id: 'delete',
        Header: '',
        accessor: (workflow: IWorkflow) => ({
          workflow,
          isReadOnly,
        }),
        showOnHover: true,
        Cell: DeleteWorkflowCell,
        width: '4%',
      }] : [],
      {
        id: 'enabled',
        Header: columnHeaders.enabled,
        accessor: (workflow: IWorkflow) => ({
          isChecked: workflow.isEnabled,
          entity: workflow,
          isDisabled: !isEntitled,
          isReadOnly,
          onClick: handleToggle,
        }),
        Cell: SwitchCell,
        width: '7%',
      },
    ];

    return columns;
  }, [columnHeaders, isEntitled, handleToggle, isReadOnly]);

  return {
    columns: columnsData as TableColumn<object>[],
  };
};
