import { Switch } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';

import styles from './JitSwitchToggle.module.scss';

import { JitReadOnlyTooltip } from 'components/JitTooltip/JitReadOnlyTooltip/JitReadOnlyTooltip';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';

export interface JitSwitchToggleProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  dataTestId?: string;
  disabled?: boolean;
  isReadOnly?: boolean;
  tooltip?: string;
}

export const JitSwitchToggle: FC<JitSwitchToggleProps> = ({
  checked,
  onChange,
  dataTestId,
  disabled,
  tooltip,
  isReadOnly = false,
}) => {
  const switchToggle = (
    <Switch
      checked={checked}
      classes={{
        switchBase: styles.switchBase,
        track: checked ? styles.checkedTrack : styles.uncheckedTrack,
        thumb: styles.thumb,
      }}
      data-testid={dataTestId || 'JitSwitchToggle'}
      disabled={disabled || isReadOnly}
      onChange={onChange}
    />
  );

  if (isReadOnly) {
    return (
      <JitReadOnlyTooltip>
        {switchToggle}
      </JitReadOnlyTooltip>
    );
  }

  if (tooltip) {
    return (
      <JitTooltip followCursor={false} placement='top' title={tooltip}>
        {switchToggle}
      </JitTooltip>
    );
  }

  return switchToggle;
};
