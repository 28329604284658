import { Slide, ToastContainer } from 'react-toastify';

import styles from './SnackBarContainer.module.scss';

export const SnackBarContainer = () => (
  <ToastContainer
    closeButton={false}
    data-testid='snackbar-container'
    hideProgressBar
    limit={3}
    newestOnTop
    pauseOnFocusLoss
    pauseOnHover
    position='bottom-right'
    toastClassName={styles.toast}
    transition={Slide}
  />
);
