import { FC } from 'react';
import { createPortal } from 'react-dom';

import styles from './BillingText.module.scss';
import { useCheckoutTextObserver } from './hooks/useCheckoutTextObserver';

import { JitText } from 'components/JitText/JitText';
import { config } from 'config';

interface CheckoutStepProps {
  checkoutStep: number;
}

export const BillingText: FC<CheckoutStepProps> = ({ checkoutStep }) => {
  const { container, buttonText, shouldShow } = useCheckoutTextObserver(
    '.billing-text-container',
    'billing-text-container',
    '.stigg-checkout-progress-container',
    [checkoutStep],
  );

  if (!container || !buttonText || !shouldShow) return null;

  return createPortal(
    <div className={styles.billingContainer}>
      <JitText
        color='white'
        lineHeight={1.8}
        mb={2}
        size={12}
        text='common.checkout.subscriptionInfo'
      />

      <JitText
        color='white'
        display='inline'
        size={12}
        text='common.checkout.otherPaymentOptions'
      />

      {' '}

      <a href={config.contactUsUrl} rel='noreferrer' target='_blank'>
        <JitText
          color='inherit'
          display='inline'
          size={12}
          text='common.checkout.contactUs'
        />
      </a>
    </div>,
    container,
  );
};
