import { FC } from 'react';

import { AppErrorBoundary } from 'components/GeneralErrorPage/AppErrorBoundary/AppErrorBoundary';
import { ContextProviders } from 'context/ContextProviders';
import { Router } from 'Router';
import { AllDialogs } from 'wrappers';
import { BlockMobileWrapper } from 'wrappers/BlockMobileWrapper';

export const App: FC = () => (
  <AppErrorBoundary>
    <BlockMobileWrapper>
      <ContextProviders>

        <AllDialogs />

        <Router />
      </ContextProviders>

    </BlockMobileWrapper>
  </AppErrorBoundary>
);
