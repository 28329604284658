import { t } from 'i18next';
import { useCallback, useState, FC } from 'react';
import { useMutation } from 'react-query';

import styles from './DeleteWorkflow.module.scss';

import { TrashIris } from 'assets';
import { IconButton } from 'components/IconButton/IconButton';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { CustomDialog } from 'components/JitDialogs/CustomDialog';
import { JitText } from 'components/JitText/JitText';
import { queryClient } from 'context/QueryClient/QueryClient';
import { useWorkflowsService } from 'services/WorkflowsService/useWorkflowsService';
import { Queries } from 'types/enums/Queries';
import { ActionButtonVariant } from 'types/interfaces';
import { IWorkflow } from 'types/interfaces/Workflows/IWorkflow';

interface Props {
  onDeleteSuccess: () => void;
  workflow: IWorkflow;
  isNewWorkflow?: boolean;
  isReadOnly: boolean;
}

export interface IDeleteWorkflowTexts {
  title: string;
  message: string;
  subMessage: string;
  confirm: string;
  cancel: string;
  tooltips: {
    deleteWorkflow: string;
  }
}

export const DeleteWorkflow: FC<Props> = ({
  onDeleteSuccess,
  workflow,
  isNewWorkflow = false,
  isReadOnly,
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { deleteWorkflow: deleteWorkflowClient } = useWorkflowsService();

  const deleteWorkflowTexts: IDeleteWorkflowTexts = t('pages.workflows.deleteWorkflowDialog', { returnObjects: true });

  const messageWithWorkflowName = t('pages.workflows.deleteWorkflowDialog.message', {
    workflowName: workflow.name,
  });

  const {
    mutateAsync: deleteWorkflow,
    isLoading: isDeletingWorkflow,
  } = useMutation(
    async () => {
      if (!workflow) return; // should never happen
      await deleteWorkflowClient(workflow.id);
    },
  );

  const onClickDelete = useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, []);

  const onConfirmDelete = useCallback(async () => {
    setIsDeleteDialogOpen(false);
    await deleteWorkflow();
    await queryClient.invalidateQueries([Queries.Workflows]);
    onDeleteSuccess();
  }, [deleteWorkflow, onDeleteSuccess]);

  const dialogContent = (
    <div className={styles.content}>
      <JitText
        bold
        text={messageWithWorkflowName}
      />

      <JitText text={deleteWorkflowTexts.subMessage} />

      <div className={styles.actions}>
        <JitActionButton
          actionButton={{
            variant: ActionButtonVariant.SECONDARY,
            label: deleteWorkflowTexts.cancel,
            handleClick: () => setIsDeleteDialogOpen(false),
          }}
        />

        <JitActionButton
          actionButton={{
            variant: ActionButtonVariant.DANGER,
            label: deleteWorkflowTexts.confirm,
            handleClick: onConfirmDelete,
          }}
        />
      </div>
    </div>
  );

  return (
    <div>
      <IconButton
        color='none'
        icon={TrashIris}
        isDisabled={isNewWorkflow || isReadOnly}
        isLoading={isDeletingWorkflow}
        onClick={onClickDelete}
        size={18}
        tooltipText={deleteWorkflowTexts.tooltips.deleteWorkflow}
        withBackSquare
      />

      <CustomDialog
        content={dialogContent}
        dataTestId='delete-workflow-dialog'
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        title={deleteWorkflowTexts.title}
        width='s'
        withDivider
      />
    </div>
  );
};
