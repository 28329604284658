import { FC, useCallback } from 'react';

import styles from '../../Header.module.scss';

import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { IWorkflowHeaderTexts } from 'pages/WorkflowsPage/EditWorkflowPage/components/Header/Header';
import { useCanSave } from 'pages/WorkflowsPage/EditWorkflowPage/components/Header/useCanSave';
import { useSaveWorkflow } from 'pages/WorkflowsPage/EditWorkflowPage/components/Header/useSaveWorkflow';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext/EditWorkflowContext';

interface ISaveWorkflowProps {
  workflowHeaderTexts: IWorkflowHeaderTexts;
  onSaveSuccess?: () => void; // If you want to handle a callback after saving
  setNameAlreadyExists: () => void;
}

export const SaveWorkflow: FC<ISaveWorkflowProps> = ({
  workflowHeaderTexts,
  onSaveSuccess,
  setNameAlreadyExists,
}) => {
  const { workflow, isNewWorkflow } = useEditWorkflowContext();

  const { canSave, cantSaveReason } = useCanSave(workflow);

  const { mutateAsync: saveWorkflowMutation, isLoading: isSaving } = useSaveWorkflow({ isNewWorkflow });

  const onClickSave = useCallback(async () => {
    const { status } = await saveWorkflowMutation();
    if (status === 409) {
      setNameAlreadyExists();
    } else if (onSaveSuccess) onSaveSuccess();
  }, [onSaveSuccess, saveWorkflowMutation, setNameAlreadyExists]);

  return (
    <div>
      <JitTooltip followCursor={false} placement='bottom' title={cantSaveReason}>
        <JitGradientButton
          data-testid='saveWorkflowButton'
          disabled={!canSave || isSaving}
          isLoading={isSaving}
          onClick={onClickSave}
          width={isSaving ? 50 : 'fit-content'}
        >
          <div className={styles.saveButtonTextWrapper}>
            <JitText size='s' text={workflowHeaderTexts.save} />
          </div>
        </JitGradientButton>
      </JitTooltip>
    </div>
  );
};
