import { useNavigate } from 'react-router-dom';

import { JitLink } from 'components/JitLink/JitLink';
import { JitText } from 'components/JitText/JitText';
import { constants } from 'globalConstants';

export const useNavigatePipelinesDemo = () => {
  const navigate = useNavigate();

  const navigatePipelinesDemo = () => {
    navigate(`/${constants.routes.PIPELINES}`);
  };

  const pipelinePageLink = (
    <JitLink
      onClick={navigatePipelinesDemo}
      sx={{ cursor: 'pointer' }}
    >
      <JitText className='inlineText' color='iris' text='pages.pipelines.navigate.toPipelines' />
    </JitLink>
  );

  return {
    navigatePipelinesDemo,
    pipelinePageLink };
};
