import { FC } from 'react';

import styles from './UpgradeCell.module.scss';

import { JitUpgradeButton } from 'components/JitUpgradeButton/JitUpgradeButton';
import { ITableCell } from 'types/interfaces';

export const UpgradeCell: FC<ITableCell> = () => (
  <div className={styles.wrapper}>
    <JitUpgradeButton size='small' text='common.pricingPlan.upgradeToActivate' />
  </div>
);
